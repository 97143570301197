import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { useNavigate } from "react-router";
import stateCity from "./stateCity.json";

const tagsDataset = {
    Doctor: [
        "ANM",
        "B.Pharm",
        "B.sc Nursing",
        "B.V.Sc",
        "Biotechnology",
        "BPT",
        "D.Pharm",
        "Diploma",
        "DMLT",
        "DNB",
        "M.Pharm",
        "M.V.Sc",
        "M.sc Nursing",
        "MBBS",
        "MDS",
        "Medical",
        "MS",
        "MD",
        "M.Phil",
        "Phd",
        "Pharmacy",
        "D.Pharma",
        "M.Pharma",
        "M.Sc",
        "GNM",
    ],
    Nursing: [
        // "Staff Nurse",
        "Senior Staff Nurse",
        "Mental Health Nurse",
        "Nursing Assistant",
        "BSN",
        "MSN",
        "DNP",
        "PhD in Nursing",
        "ANM",
        "GNM",
        "B.sc Nursing",
        "Telemetry Nurse",
        "Informatics Nurse",
        "Diabetes Nurse",
        "Rehabilitation Nurse",
        "Infection Control Nurse",
        "Nurse Manager",
        "Nurse Director",
        "B.Pharm",
        // "B.sc",
        "B.V.Sc",
        "Biotechnology",
        "BPT",
        "D.Pharm",
        "Diploma",
        "DMLT",
        "DNB",
        "M.Pharm",
        "M.V.Sc",
        "M.sc Nursing",
        "MDS",
        "Medical",
        // "Techncian",
        "Nutrionsist",
        "Audiologist",
        "Optometrist",
        "12th Pass",
        "MPH",
        "MHA",
    ],
    Paramedic: ["EMT", "Paramedic", "Dialysis Technician", "Orthopedic Technologist"],
};

const indianStates = Object.keys(stateCity);

const AutoSuggestJobs = ({ inputValue, setInputValue, setFinalSuggestion, location, setIsPrivateJobSearch, isPrivateJobSearch, isSmallScreen }) => {
    const [suggestions, setSuggestions] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setShowDropdown(true);
        setSuggestions(getSuggestions(value));
    };

    const getSuggestions = (value) => {
        const input = value.toLowerCase();
        let suggestionList = [];

        // Split the input value to check for "in" and location
        const parts = input.split(" in ");
        const jobPart = parts[0];
        const locationPart = parts[1] || "";

        // Add location suggestions immediately after "in"
        if (input.endsWith(" in ") || input.endsWith(" in")) {
            suggestionList = [...indianStates.map((state) => `${jobPart} in ${state}`), ...Object.entries(stateCity).flatMap(([state, cities]) => cities.map((city) => `${jobPart} in ${city}`))];
        } else {
            // Add job suggestions
            Object.keys(tagsDataset).forEach((category) => {
                tagsDataset[category].forEach((tag) => {
                    if (tag.toLowerCase().includes(jobPart)) {
                        suggestionList.push(tag);
                    }
                });
            });

            // Add location suggestions if user starts typing location
            if (locationPart) {
                const locationSuggestions = [];

                Object.entries(stateCity).forEach(([state, cities]) => {
                    if (state.toLowerCase().startsWith(locationPart)) {
                        locationSuggestions.push(`${jobPart} in ${state}`);
                    }
                    cities.forEach((city) => {
                        if (city.toLowerCase().startsWith(locationPart)) {
                            locationSuggestions.push(`${jobPart} in ${city}`);
                        }
                    });
                });

                // Prepend location suggestions to job suggestions
                suggestionList = [...locationSuggestions, ...suggestionList];
            }
        }

        return suggestionList.slice(0, 10); // Limit suggestions to 10
    };

    const handleSuggestionClick = (suggestion) => {
        const parts = suggestion.split(" in ");
        const jobPart = parts[0];
        const locationPart = parts[1];

        let finalSuggestion = suggestion;

        // Check if the location part is a city, and update the input value to the corresponding state
        if (locationPart) {
            Object.entries(stateCity).forEach(([state, cities]) => {
                if (cities.includes(locationPart)) {
                    finalSuggestion = location?.length > 0 ? jobPart : `${jobPart} in ${state}`;
                }
            });
        }
        if (window.location.href.toString().includes("private-jobs") || isPrivateJobSearch == 2) {
            let url = `/private-jobs?searchText=${finalSuggestion}&page=1`;
            if (location) {
                url += `&jobState=${location.toLowerCase()}`;
            }
            navigate(url);
        } else {
            const url = location?.length > 0 ? `/job/list?searchText=${finalSuggestion}&location=${location}` : `/job/list?searchText=${finalSuggestion}`;
            navigate(url);
        }

        setInputValue(finalSuggestion);
        setShowDropdown(false);
    };

    return (
        <div ref={dropdownRef} className={styles.container}>
            <input
                readOnly
                type="text"
                value={inputValue || ""}
                //  onChange={handleChange}
                //  onClick={handleChange}
                placeholder="Search jobs Here..."
            />
            {/* {suggestions.length > 0 && isPrivateJobSearch == null && showDropdown && !isSmallScreen && (
                <div className={styles.search_section_choose}>
                    <h3>Which jobs do you want to see ?</h3>
                    <div>
                        <button onClick={() => setIsPrivateJobSearch(2)}>Private Jobs</button>
                        <button onClick={() => setIsPrivateJobSearch(1)}>Gov. Jobs</button>
                    </div>
                </div>
            )} */}

            {suggestions.length > 0 && isPrivateJobSearch != null && showDropdown && (
                <div className={styles.suggestions}>
                    {suggestions.map((suggestion, index) => (
                        <p key={index} onClick={() => handleSuggestionClick(suggestion)}>
                            {suggestion}
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AutoSuggestJobs;
