import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./Store/index.js";
import { GovernmentJobProvider } from "./Api/governmentJob.js";
import { LoadingProvider } from "./Api/loading.js";
import { BrowserRouter } from "react-router-dom";
import { PrivateJobProvider } from "./Api/privatejobPosting.js";
import { ReviewsJobProvider } from "./Api/reviews.js";

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <GovernmentJobProvider>
                    <LoadingProvider>
                        <PrivateJobProvider>
                            <ReviewsJobProvider>
                                <App />
                            </ReviewsJobProvider>
                        </PrivateJobProvider>
                    </LoadingProvider>
                </GovernmentJobProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);
