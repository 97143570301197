import React from "react";

const TextWithLinks = ({ text }) => {
    const urlPattern = /(?:https?:\/\/[^\s]+|www\.[^\s]+)/g;
    const emailPattern = /(?:\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,})/g;

    const combinedPattern = new RegExp(`(${urlPattern.source}|${emailPattern.source})`, "g");

    const parts = text ? text.split(combinedPattern).filter(Boolean) : [];

    return (
        <>
            {parts.map((part, index) => {
                if (urlPattern.test(part)) {
                    return (
                        <a href={part.startsWith("http") ? part : `http://${part}`} key={index} target="_blank" rel="noopener noreferrer">
                            {part}
                        </a>
                    );
                } else if (emailPattern.test(part)) {
                    return (
                        <a href={`mailto:${part}`} key={index}>
                            {part}
                        </a>
                    );
                }
                return <span key={index}>{part}</span>;
            })}
        </>
    );
};

export default TextWithLinks;
