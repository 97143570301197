import React, { useState } from "react";
import PropTypes from "prop-types";
import "./FileInput.css";
import instance from "../../../Services/interceptors";

const FileInput = ({ label, accept, maxFileSize, onFileChange, allowPreview, placeholder, actionButtonLabel, uploadPath, preUrl, replaceActionButtonLabel }) => {
    const [fileName, setFileName] = useState("");
    const [error, setError] = useState("");
    const [preview, setPreview] = useState(null);
    const [fileUrl, setFileUrl] = useState("");

    const uploadFile = async (file, uploadPath) => {
        try {
            const formData = new FormData();
            formData.append("file", file);

            const response = await instance.post(uploadPath, formData);

            if (response.status === 201) {
                return response.data.filePath; // Assuming the response contains the URL in the `url` field
            } else {
                throw new Error("Failed to upload file");
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const validTypes = accept.split(",").map((type) => type.trim());

            if (!validTypes.includes(file.type)) {
                setError(`Invalid file type. Please select a ${accept} file.`);
                setFileName("");
                setPreview(null);
                return;
            }

            if (maxFileSize && file.size > maxFileSize) {
                setError(`File size exceeds the limit of ${(maxFileSize / 1024 / 1024).toFixed(2)}MB.`);
                setFileName("");
                setPreview(null);
                return;
            }

            setError("");
            setFileName(file.name);

            if (file.type === "application/pdf") {
                // Generate a blob URL for the PDF
                const url = URL.createObjectURL(file);
                setFileUrl(url);
                setPreview(null);
            } else if (allowPreview && file.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPreview(reader.result);
                };
                reader.readAsDataURL(file);
                setFileUrl("");
            } else {
                setPreview(null);
                setFileUrl("");
            }

            try {
                const url = await uploadFile(file, uploadPath);
                //  setFileUrl(url);
                if (onFileChange) {
                    onFileChange(url);
                }
            } catch (uploadError) {
                //console.log(uploadError)
                setError("Failed to upload file. Please try again.");
                setFileName("");
                setPreview(null);
                setFileUrl("");
            }
        }
    };

    return (
        <div className="file-input-container">
            {label && (
                <label htmlFor="file-input" className="file-input-label">
                    {label}
                </label>
            )}

            {preview && (
                <div className="file-input-preview">
                    <img className="file-input-preview-img" src={preview} alt="File preview" />
                </div>
            )}

            {preUrl && !preview && placeholder && (
                <div className="file-input-preview">
                    <img className="file-input-preview-img" src={`${process.env.REACT_APP_API_BASE_URL}/v1/fileupload/get${preUrl?.split("uploads")[1]}`} alt="File preview" />
                </div>
            )}

            {placeholder && !preview && !preUrl && (
                <div className="file-input-preview">
                    {" "}
                    <img src={placeholder} className="placeholder_image" alt="placeholder" />{" "}
                </div>
            )}

            <input id="file-input" type="file" accept={accept} className="file-input" onChange={handleFileChange} />
            {placeholder ? (
                <label htmlFor="file-input" className="file-input-button file-input-image">
                    {replaceActionButtonLabel ? replaceActionButtonLabel : actionButtonLabel}
                </label>
            ) : (
                <div className="file_input_button">
                    <label htmlFor="file-input" className="file-input-button for_pdf">
                        {replaceActionButtonLabel ? replaceActionButtonLabel : actionButtonLabel}
                    </label>
                    {fileName ? (
                        <p className="file-input-filename">
                            {fileName.endsWith(".pdf") && fileUrl ? (
                                <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                                    {fileName}
                                </a>
                            ) : (
                                fileName
                            )}
                        </p>
                    ) : preUrl ? (
                        <a href={`${process.env.REACT_APP_API_BASE_URL}/v1/fileupload/get${preUrl?.split("uploads")[1]}`} target="_blank" rel="noopener noreferrer">
                            {"View Uploaded Resume"}
                        </a>
                    ) : (
                        <p className="file-input-filename">
                            Upload <span>.pdf</span> files only
                        </p>
                    )}
                </div>
            )}
            {error && <div className="file-input-error">{error}</div>}
        </div>
    );
};

FileInput.propTypes = {
    label: PropTypes.string,
    accept: PropTypes.string.isRequired,
    maxFileSize: PropTypes.number,
    onFileChange: PropTypes.func.isRequired,
    allowPreview: PropTypes.bool,
};

FileInput.defaultProps = {
    allowPreview: false,
};

export default FileInput;
