import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import menuList from "./data/menulist.json";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RegistrationForm from "../../Components/RegistrationPopUp";
import MobileViewNav from "./mobileNav";
import AutoSuggestJobs from "../../Components/AutoSuggestJobs";
import stateCity from "../../Components/AutoSuggestJobs/stateCity.json";
import downArrow from "./assets/downarrow.svg";
import companyLogo from "./assets/companyLogo.svg";
import SearchPopUp from "../../Components/SearchPopUp";
import { useVerifyRole } from "../../Utils/Helpers";
import { ROLES_LIST } from "../../Utils/Constants";
import NavbarSubMenu from "../../Components/NavbarSubMenus";

const Navbar = () => {
    const navigate = useNavigate();
    const isVerifiedRole = useVerifyRole([ROLES_LIST.ADMIN]);
    const location = useLocation();
    const [isPrivateJobSearch, setIsPrivateJobSearch] = useState(null);
    const queryParams = new URLSearchParams(location.search);
    const [showSearchPopUp, setShowSearchPopUp] = useState(false);
    // Example: Get specific query parameters
    const searchVal = queryParams.get("searchText");

    const [showDropdown, setShowDropdown] = useState(null);

    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const { user, isLoggedIn } = useSelector((state) => state.auth);

    const [searchText, setSearchText] = useState("");
    const [finalSuggestion, setFinalSuggestion] = useState("");
    const [showRegistrations, setShowRegistrations] = useState(false);
    // //console.log(user,isLoggedIn)
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const profileBoxRef = useRef(null);

    const handleProfileClick = () => {
        setIsProfileOpen(!isProfileOpen);
    };

    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(null);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setSearchText(searchVal != "null" ? searchVal : "");
    }, [searchVal]);

    const getRedirectLink = (data) => {
        if (window.location.href.toString().includes("private-jobs")) {
            let privateJobUrl = `/private-jobs/listing?`;
            if (data.name && data.name.toLowerCase() != "all") {
                privateJobUrl += `field=${data.name.toLowerCase()}`;
            }
            return privateJobUrl;
        }
        return data.url;
    };

    return (
        <div className={styles.navbar_main_container_wrapper}>
            <div className={styles.navbar_main_container}>
                <div style={{ maxWidth: isLoggedIn ? "1000px" : "" }} ref={dropdownRef}>
                    <div
                        onClick={() => {
                            navigate("/");
                        }}
                        className={styles.breadcom_container}
                    >
                        <img src={companyLogo} alt="companyLogo" />
                    </div>

                    <div className={styles.menu_list}>
                        <ul>
                            {menuList.map((item) => {
                                return (
                                    <li key={item.name}>
                                        {item.child ? (
                                            <div style={{ position: "relative" }}>
                                                <p onClick={() => setShowDropdown(item.name === showDropdown ? null : item.name)}>
                                                    <span>{item.name}</span>{" "}
                                                    <img style={{ transform: showDropdown === item.name ? "rotate(180deg)" : "rotate(0deg)" }} src={downArrow} alt="downarrow" />{" "}
                                                </p>
                                                <ul style={{ display: showDropdown === item.name ? "flex" : "none" }} className={styles.child_container}>
                                                    {/* {item.child.map((item) => {
                                                        return (
                                                            <li
                                                                key={item.name}dropdownRef
                                                                onClick={() => {
                                                                    getRedirectLink(item);
                                                                    setShowDropdown(null);
                                                                }}
                                                            >
                                                                <Link to={getRedirectLink(item)}>{item.name}</Link>
                                                            </li>
                                                        );
                                                    })} */}
                                                    <NavbarSubMenu data={item.child} />
                                                </ul>
                                            </div>
                                        ) : (
                                            <Link to={item.url}>{item.name}</Link>
                                        )}
                                    </li>
                                );
                            })}
                            {isLoggedIn && (
                                <>
                                    {isVerifiedRole && (
                                        <li>
                                            <Link to={"/register"}>{"Registration"}</Link>
                                        </li>
                                    )}

                                    <li
                                        onClick={() => {
                                            localStorage.removeItem("userToken");
                                            window.location.href = "/";
                                        }}
                                    >
                                        Logout
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
                <div>
                    <form
                        onClick={() => setShowSearchPopUp(true)}
                        onSubmit={(event) => {
                            event.preventDefault();
                            setShowSearchPopUp(true);
                            // const parts = searchText.split(" in ");
                            // const jobPart = parts[0];
                            // const locationPart = parts[1];

                            // let finalSuggestion = searchText;

                            // if (locationPart) {
                            //     Object.entries(stateCity).forEach(([state, cities]) => {
                            //         if (cities.map((city) => city.toLowerCase()).includes(locationPart.toLowerCase())) {
                            //             finalSuggestion = `${jobPart} in ${state}`;
                            //         }
                            //     });
                            // }

                            // if (window.location.href.toString().includes("private-jobs") || isPrivateJobSearch == 2) {
                            //     navigate(`/private-jobs/listing?searchText=${finalSuggestion}&page=1`);
                            // } else {
                            //     navigate(`/job/list?searchText=${finalSuggestion}&page=1`);
                            // }
                        }}
                        className={styles.search_box}
                    >
                        <AutoSuggestJobs
                            setIsPrivateJobSearch={setIsPrivateJobSearch}
                            isPrivateJobSearch={isPrivateJobSearch}
                            setFinalSuggestion={setFinalSuggestion}
                            inputValue={searchText}
                            setInputValue={setSearchText}
                            isSmallScreen={true}
                        />
                        <button>
                            Search
                            <svg width="13" height="13" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.64792 16.2958C12.8717 16.2958 16.2958 12.8717 16.2958 8.64792C16.2958 4.42409 12.8717 1 8.64792 1C4.42409 1 1 4.42409 1 8.64792C1 12.8717 4.42409 16.2958 8.64792 16.2958Z"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path d="M18.2093 18.2074L14.0508 14.0488" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </form>
                </div>

                {!isLoggedIn && (
                    <div className={styles.registration}>
                        <p>Get Personalized Healthcare Job Alerts </p>
                        <button onClick={() => setShowRegistrations(true)}>Register Now</button>
                    </div>
                )}

                {/* <div className={styles.post_job}>
                    <Link to={"/private-jobs/postjob"}>
                        {" "}
                        <button>Post a Job</button>
                    </Link>
                </div> */}
            </div>

            <MobileViewNav />
            {showSearchPopUp && (
                <div className={styles.search_pop_up}>
                    <SearchPopUp onClose={() => setShowSearchPopUp(false)} />
                </div>
            )}
            {showRegistrations && <RegistrationForm getClose={() => setShowRegistrations(false)} />}
        </div>
    );
};

export default Navbar;
