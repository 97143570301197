import React from "react";
import styles from "./index.module.css";

const Loader = ({ progress }) => {
    return (
        <div className={styles.container}>
            <div style={{ width: `${progress}%` }}></div>
        </div>
    );
};

export default Loader;
