import React from "react";
import styles from "./index.module.css";

const Disclaimer = () => {
    return (
        <div className={styles.container}>
            <h2>Disclaimer:</h2>
            <p>
                Job postings are sourced externally, and we are not responsible for their accuracy. Never pay fees for applications or offers. Verify job legitimacy independently. By using this
                platform, you agree we are not liable for any related actions or outcomes. Report suspicious activity to admins.
            </p>
        </div>
    );
};

export default Disclaimer;
