import React from "react";
import styles from "./index.module.css";

const Textarea = ({ name, label, placeholder, required, type, defaultValue, onChange = () => {} }) => {
    return (
        <div className={styles.container}>
            <p>
                {label} {required && <span>*</span>}
            </p>
            <textarea placeholder={placeholder} type={type} name={name} value={defaultValue} required={required} onChange={onChange} />
        </div>
    );
};

export default Textarea;
