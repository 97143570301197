const DATA = {
    paragraph: `Exciting Opportunity for OT Nurse at Nirali Hospital in Navsari, Gujarat 🚀

    1️⃣ Positions Available:
    OT Nurse – Salary: ₹20,000 - ₹35,000 per month
    
    2️⃣ Responsibilities:
    Preventing and managing healthcare-associated infections within healthcare settings.
    Supervising and monitoring cleanliness and hygienic practices.
    Overseeing infection prevention and control including hand hygiene, surgical site infections, injection safety, and antimicrobial resistance.
    
    3️⃣ Qualifications:
    Diploma or Bachelor's degree in Nursing.
    Minimum 1 year of experience in a similar role.
    Nursing License required.
    
    4️⃣ Location:
     Navsari, Gujarat
    
    5️⃣ Company:
    Nirali Hospital: A leading healthcare provider known for its comprehensive services and commitment to high standards of patient care.
    
    6️⃣Apply Here:
    https://niralihealthcare.org/career/staff-nurse-ot/`,

    sortByData: [
        { value: "dsc", label: "Latest" },
        { value: "asc", label: "Expiring Soon" },
    ],
    tags: [
        { label: "ANM", value: "ANM", category: "nursing" },
        { label: "B.sc Nursing", value: "B.sc Nursing", category: "nursing" },
        { label: "GNM", value: "GNM", category: "nursing" },
        { label: "B.Pharm", value: "B.Pharm", category: "pharmacy" },
        { label: "D. Pharm", value: "D. Pharm", category: "pharmacy" },
        { label: "M. Pharm", value: "M.Pharm", category: "pharmacy" },
        { label: "B.V.Sc", value: "B.V.Sc", category: "doctor" },
        { label: "BAMS", value: "BAMS", category: "doctor" },
        { label: "BDS", value: "BDS", category: "doctor" },
        { label: "BHMS", value: "BHMS", category: "doctor" },
        { label: "MD", value: "MD", category: "doctor" },
        { label: "MBBS", value: "MBBS", category: "doctor" },
        { label: "MDS", value: "MDS", category: "doctor" },
        { label: "MS", value: "MS", category: "doctor" },
        { label: "Biotechnology", value: "Biotechnology", category: "paramedic" },
        { label: "BPT", value: "BPT", category: "doctor" },
        { label: "BUMS", value: "BUMS", category: "doctor" },
        { label: "DNB", value: "DNB", category: "doctor" },
        { label: "M.V.Sc", value: "M.V.Sc", category: "doctor" },
        { label: "M. Sc Nursing", value: "M.sc Nursing", category: "nursing" },
        { label: "M. Phil", value: "M.Phil", category: "doctor" },
        { label: "Phd", value: "Phd", category: "doctor" },
        { label: "Pharmacy", value: "Pharmacy", category: "pharmacy" },
        { label: "Technician", value: "Technician", category: "technician" },
        { label: "Nutritionist", value: "Nutritionist", category: "technician" },
        { label: "Audiologist", value: "Audiologist", category: "technician" },
        { label: "Optometrist", value: "Optometrist", category: "technician" },
        { label: "12th Pass", value: "12th Pass", category: "technician" },
        { label: "MPH", value: "MPH", category: "technician" },
        { label: "MHA", value: "MHA", category: "technician" },
        { label: "Diploma", value: "Diploma", category: "technician" },
        { label: "DMLT", value: "DMLT", category: "technician" },
    ],
    States: [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
        "Delhi",
        "Ladakh",
        "Lakshadweep",
        "Puducherry",
        "Chandigarh",
        "Dadra and Nagar Haveli and Daman and Diu",
    ],
    Fields: [
        // { value: "", label: "Select  Field" },
        { value: "all", label: "All" },
        { value: "doctor", label: "Doctor" },
        { value: "nursing", label: "Nursing" },
        { value: "pharmacy", label: "Pharmacy" },
        { value: "technician", label: "Technician" },
    ],
    sectionType: [
        {
            value: "list",
            label: "List",
        },
        {
            value: "paragraph",
            label: "Paragraph",
        },
        {
            value: "link",
            label: "Link",
        },
        {
            value: "iframe",
            label: "PDF Document",
        },
    ],
};

export default DATA;
