import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Select from "react-select";
import { useNavigate } from "react-router";
import Utils from "../../Utils";
import DATA from "../../Data";

const SearchForm = ({ formData, setFormData }) => {
    const [data, setData] = useState({
        field: formData.get("field"),
        jobState: formData.get("jobState"),
    });
    const navigate = useNavigate();
    useEffect(() => {
        setData({
            field: formData.get("field"),
            jobState: formData.get("jobState"),
        });
    }, [formData]);

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                const formData = new FormData(event.target);
                const formDataJSON = Object.fromEntries(formData.entries());
                let redirectUrl = `/private`;
                if (formDataJSON.jobState) {
                    redirectUrl += `/jobs-in-${formDataJSON.jobState.toLowerCase()}`;
                }
                if (formDataJSON.field) {
                    redirectUrl += formDataJSON.field.toLowerCase() === "all" ? `-jobs` : `/${formDataJSON.field.toLowerCase()}-jobs`;
                }
                navigate(`${redirectUrl}?tab=1`);
                // if (!window.location.href.toString().includes("private-jobs/listing")) {
                //     let redirectUrl = `/private-jobs/listing?tab=1&page=1`;
                //     if (formDataJSON.jobState) {
                //         redirectUrl += `&jobState=${formDataJSON.jobState}`;
                //     }
                //     if (formDataJSON.field) {
                //         redirectUrl += `&field=${formDataJSON.field}`;
                //     }
                //     navigate(redirectUrl);
                // } else {
                //     setFormData(
                //         (prev) => {
                //             if (formDataJSON.field && formDataJSON.jobState) {
                //                 prev.set("field", formDataJSON.field);
                //                 prev.set("jobState", formDataJSON.jobState);
                //             } else if (formDataJSON.field) {
                //                 prev.set("field", formDataJSON.field);
                //                 prev.delete("jobState");
                //             } else if (formDataJSON.jobState) {
                //                 prev.set("jobState", formDataJSON.jobState);
                //                 prev.delete("field");
                //             } else {
                //                 prev.delete("field");
                //                 prev.delete("jobState");
                //             }
                //             prev.delete("tag");
                //             prev.delete("searchText");
                //             return prev;
                //         },
                //         { replace: true }
                //     );
                // }
            }}
            className={styles.container}
        >
            <Select
                className={styles.select}
                classNamePrefix="select"
                value={DATA.Fields.find((item) => item.value === data.field) || { value: "", label: "Select  Field" }}
                isSearchable={true}
                name="field"
                options={DATA.Fields}
                onChange={(selectedOption) => {
                    setData({ ...data, field: selectedOption.value });
                }}
            />

            <Select
                className={styles.select}
                classNamePrefix="select"
                value={Utils.getStatesOptions().find((item) => item.value === data.jobState) || { value: "", label: "Select State" }}
                isSearchable={true}
                name="jobState"
                options={Utils.getStatesOptions()}
                onChange={(selectedOption) => {
                    setData({ ...data, jobState: selectedOption.value });
                }}
            />
            <button>
                Apply <span>& Search</span>
            </button>
        </form>
    );
};

export default SearchForm;
