import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

const NavbarSubMenu = ({
    refs,
    data = {
        name: "Government Jobs",
        items: [
            {
                name: "Popular Categories",
                list: [
                    {
                        name: "Nursing Jobs",
                        url: "/nursing-jobs",
                    },
                    {
                        name: "Doctor Jobs",
                        url: "/doctor-jobs",
                    },
                    {
                        name: "Pharmacy Jobs",
                        url: "/pharmacy-jobs",
                    },
                    {
                        name: "Technician Jobs",
                        url: "/technician-jobs",
                    },
                ],
            },
            {
                name: "Jobs By Eduction",
                list: [
                    {
                        name: "Anm Jobs",
                        url: "/anm-jobs",
                    },
                    {
                        name: "GNM Jobs",
                        url: "/gnm-jobs",
                    },
                    {
                        name: "B.sc Nursing Jobs",
                        url: "/b.sc nursing-jobs",
                    },
                    {
                        name: "MBBS Jobs",
                        url: "/mbbs-jobs",
                    },
                    {
                        name: "B Pharm Jobs",
                        url: "/b.pharm-jobs",
                    },
                    {
                        name: "Diploma Jobs",
                        url: "/diploma-jobs",
                    },
                ],
            },
            {
                name: "Jobs By Location",
                list: [
                    {
                        name: "Jobs in Delhi",
                        url: "/jobs-in-delhi",
                    },
                    {
                        name: "Jobs in Haryana",
                        url: "/jobs-in-haryana",
                    },
                    {
                        name: "Jobs in Kerala",
                        url: "/jobs-in-kerala",
                    },
                    {
                        name: "Jobs in Punjab",
                        url: "/jobs-in-punjab",
                    },
                    {
                        name: "Jobs in Bihar",
                        url: "/jobs-in-bihar",
                    },
                    {
                        name: "Jobs in Goa",
                        url: "/jobs-in-goa",
                    },
                    {
                        name: "Jobs in Karnataka",
                        url: "/jobs-in-karnataka",
                    },
                ],
            },
        ],
    },
}) => {
    return (
        <div ref={refs} className={styles.container}>
            {data.items.map((item) => (
                <div key={item.name}>
                    <h2>{item.name}</h2>
                    <ul>
                        {item.list.map((listItem) => (
                            <li key={listItem.url}>
                                <Link to={listItem.url}>{listItem.name}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default NavbarSubMenu;
