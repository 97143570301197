import React from "react";
import styles from "./index.module.css";

const JobTags = ({ data, onClick, defaultValue }) => {
    const isActive = (item) => {
        return item == defaultValue ? styles.active : "";
    };
    return (
        <div className={styles.container}>
            {data.map((item) => (
                <div onClick={() => onClick(item.value)} key={item.label} className={`${styles.tagging_item} ${isActive(item.value)}`}>
                    <p>{item.label}</p>
                </div>
            ))}
        </div>
    );
};

export default JobTags;
