export const ICONS = {
    CANCEL: (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6.758 17.243L12.001 12m5.243-5.243L12 12m0 0L6.758 6.757M12.001 12l5.243 5.243"
            />
        </svg>
    ),
    DELETE: (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="m6.774 6.4l.812 13.648a.8.8 0 0 0 .798.752h7.232a.8.8 0 0 0 .798-.752L17.226 6.4zm11.655 0l-.817 13.719A2 2 0 0 1 15.616 22H8.384a2 2 0 0 1-1.996-1.881L5.571 6.4H3.5v-.7a.5.5 0 0 1 .5-.5h16a.5.5 0 0 1 .5.5v.7zM14 3a.5.5 0 0 1 .5.5v.7h-5v-.7A.5.5 0 0 1 10 3zM9.5 9h1.2l.5 9H10zm3.8 0h1.2l-.5 9h-1.2z"
            />
        </svg>
    ),
    TICK: (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M21 7L9 19l-5.5-5.5l1.41-1.41L9 16.17L19.59 5.59z" />
        </svg>
    ),
    EDIT: (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
            <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                <path d="M19.09 14.441v4.44a2.37 2.37 0 0 1-2.369 2.369H5.12a2.37 2.37 0 0 1-2.369-2.383V7.279a2.356 2.356 0 0 1 2.37-2.37H9.56" />
                <path d="M6.835 15.803v-2.165c.002-.357.144-.7.395-.953l9.532-9.532a1.36 1.36 0 0 1 1.934 0l2.151 2.151a1.36 1.36 0 0 1 0 1.934l-9.532 9.532a1.36 1.36 0 0 1-.953.395H8.197a1.36 1.36 0 0 1-1.362-1.362M19.09 8.995l-4.085-4.086" />
            </g>
        </svg>
    ),
    PLUS: (
        <svg xmlns="http://www.w3.org/2000/svg" width="1.1em" height="1.1em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1" />
        </svg>
    ),
    SECONDARY_DELETE: (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="m19.5 5.5l-.62 10.025c-.158 2.561-.237 3.842-.88 4.763a4 4 0 0 1-1.2 1.128c-.957.584-2.24.584-4.806.584c-2.57 0-3.855 0-4.814-.585a4 4 0 0 1-1.2-1.13c-.642-.922-.72-2.205-.874-4.77L4.5 5.5M3 5.5h18m-4.944 0l-.683-1.408c-.453-.936-.68-1.403-1.071-1.695a2 2 0 0 0-.275-.172C13.594 2 13.074 2 12.035 2c-1.066 0-1.599 0-2.04.234a2 2 0 0 0-.278.18c-.395.303-.616.788-1.058 1.757L8.053 5.5m1.447 11v-6m5 6v-6"
                color="currentColor"
            />
        </svg>
    ),
    UN_PUBLISH: (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12q0-1.55.425-2.937T3.65 6.475L1.375 4.2q-.3-.3-.3-.712t.3-.713t.713-.3t.712.3l18.4 18.4q.3.3.3.7t-.3.7t-.712.3t-.713-.3l-2.25-2.225q-1.2.8-2.587 1.225T12 22m4.95-13.15q-.3-.3-.712-.3t-.688.3l-1.9 1.925l-5.95-5.95q-.45-.45-.363-1.088T8 2.826q.925-.4 1.925-.612T12 2q2.075 0 3.9.788t3.175 2.137T21.213 8.1T22 12q0 1.075-.213 2.075T21.176 16q-.25.575-.888.675t-1.112-.375l-4.125-4.15l1.9-1.9q.275-.275.275-.7t-.275-.7m-9.9 4.2L9.9 15.9q.3.3.7.3t.7-.3l.9-.9l-1.4-1.4l-.2.2l-2.15-2.15q-.275-.275-.7-.275t-.7.275t-.275.7t.275.7"
            />
        </svg>
    ),
    PUBLISH: (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M7 5.775q-1.375 1.1-2.187 2.713T4 12q0 2.875 1.75 5.013t4.425 2.762q.375.1.6.438t.225.737t-.275.675t-.625.2q-3.5-.65-5.8-3.387T2 12q0-2.275.913-4.2T5.4 4.5H4q-.425 0-.712-.288T3 3.5t.288-.712T4 2.5h4q.425 0 .713.288T9 3.5v4q0 .425-.288.713T8 8.5t-.712-.288T7 7.5zm3.575 8L15.55 8.8q.3-.3.7-.288t.7.313q.275.3.288.7t-.288.7L11.275 15.9q-.3.3-.7.3t-.7-.3L7.05 13.05q-.275-.275-.287-.687t.287-.713q.275-.275.7-.275t.7.275zM18.6 19.5H20q.425 0 .713.288T21 20.5t-.288.713T20 21.5h-4q-.425 0-.712-.288T15 20.5v-4q0-.425.288-.712T16 15.5t.713.288t.287.712v1.725q1.375-1.125 2.188-2.725T20 12q0-2.875-1.75-5.012t-4.425-2.763q-.375-.1-.6-.437T13 3.05t.275-.675t.625-.2q3.5.65 5.8 3.388T22 12q0 2.275-.913 4.2T18.6 19.5"
            />
        </svg>
    ),
};
