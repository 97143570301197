import React, { useState } from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import menusData from "../../../Layout/Navbar/data/menulist.json";

const MobileViewNavbarSubMenu = () => {
    const [showItems, setShowItems] = useState({
        parent: "Government Jobs",
        child: "Popular Categories",
    });
    return (
        <div className={styles.container}>
            {menusData.map((menu) => (
                <div>
                    <h2 className={styles.parent_header} onClick={() => setShowItems({ parent: showItems.parent === menu.name ? null : menu.name, child: menu.child.items[0].name })}>
                        {menu.name}{" "}
                        <svg style={{ transform: showItems.parent === menu.name ? "rotate(180deg)" : "rotate(0deg)" }} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 10l5 5l5-5" />
                        </svg>
                    </h2>
                    {showItems.parent == menu.name &&
                        menu.child.items.map((item) => (
                            <div key={item.name}>
                                <h3 onClick={() => setShowItems({ parent: menu.name, child: showItems.child === item.name ? null : item.name })}>
                                    {item.name}{" "}
                                    <svg
                                        style={{ transform: showItems.child === item.name ? "rotate(180deg)" : "rotate(0deg)" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 24 24"
                                    >
                                        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 10l5 5l5-5" />
                                    </svg>
                                </h3>
                                {showItems.child === item.name && (
                                    <ul>
                                        {item.list.map((listItem) => (
                                            <li key={listItem.url}>
                                                <Link to={listItem.url}>{listItem.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                </div>
            ))}
        </div>
    );
};

export default MobileViewNavbarSubMenu;
