import React from "react";
import styles from "./index.module.css";
import { Footer, Navbar } from "../../Layout/index.js";
import { useParams } from "react-router";
import JobListing from "./Pages/JobListing/index.jsx";
import JobForm from "./Pages/JobForm/index.jsx";
import NewsLetter from "../../Components/NewsLetter/index.jsx";
import DATA from "./Data/index.js";

const PrivateJobPosting = () => {
    const { tab, subTab } = useParams();

    const fieldMap = {
        "doctor-jobs": "doctor",
        "nursing-jobs": "nursing",
        "pharmacy-jobs": "pharmacy",
        "technician-jobs": "technician",
    };
    const tagMap = {
        ...Object.fromEntries(DATA.tags.map((tag) => [`${tag.value.toLowerCase()}-jobs`, tag.value.toLowerCase()])),
    };

    const stateMap = {
        ...Object.fromEntries(DATA.States.map((state) => [`jobs-in-${state.toLowerCase()}`, state.toLowerCase()])),
    };

    return (
        <div className={styles.container_wrapper}>
            <Navbar />

            <div className={styles.heading}></div>

            {/* {(tab === "listing" || tab === "details") && } */}
            {/* {tab === "postjob" || tab === "authentication" ? <JobForm /> : <JobListing />} */}
            <JobListing
                initialQuery={{
                    field: fieldMap[tab] || fieldMap[subTab],
                    state: stateMap[tab] || stateMap[subTab],
                    tag: tagMap[tab] || tagMap[subTab],
                }}
            />
            {/* <NewsLetter /> */}
            <Footer />
        </div>
    );
};

export default PrivateJobPosting;
