import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../Slices/auth";
import messageReducer from "../Slices/message";
import successMessageReducer from "../Slices/successMessage";
const reducer = {
    auth: authReducer,
    message: messageReducer,
    successMessage: successMessageReducer,
};

export const store = configureStore({
    reducer: reducer,
    devTools: true,
});
