import React, { useEffect } from "react";
import styles from "./index.module.css";

const SubmittedAlerts = ({ message }) => {
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         const messageContainer = document.getElementById("message_container");
    //         if (messageContainer) {
    //             messageContainer.style.display = "none";
    //         }
    //     }, 60000);
    //     return () => clearTimeout(timer);
    // }, []);

    return (
        <div id="message_container" className={styles.container_wrapper}>
            <div className={styles.container}>
                <div className={styles.tick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 15 15">
                        <path fill="none" stroke="currentColor" d="M4 7.5L7 10l4-5m-3.5 9.5a7 7 0 1 1 0-14a7 7 0 0 1 0 14Z" />
                    </svg>
                </div>
                <h2>{message || "Thank you! Your review has been successfully submitted. We appreciate your valuable feedback."}</h2>
                {/* <span
                    onClick={() => {
                        document.getElementById("message_container").style.display = "none";
                    }}
                    className={styles.cut}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5" d="m8.464 15.535l7.072-7.07m-7.072 0l7.072 7.07" />
                    </svg>
                </span> */}
            </div>
        </div>
    );
};

export default SubmittedAlerts;
