import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Select from "react-select";
import { capitalizeWords, states } from ".";
import { useNavigate } from "react-router";
import { tags } from "../../ExploreTopGovernmentJob/data";

const SearchForm = ({ onClose }) => {
    const [formData, setFormData] = useState({
        jobState: "",
        searchText: "",
    });

    const navigate = useNavigate();
    useEffect(() => {}, [formData]);
    const handlePrivateJobs = (event) => {
        event.preventDefault();
        let redirectUrl = `/private`;
        if (formData.jobState) {
            // redirectUrl += `&location=${capitalizeWords(formData.jobState)}`;
            redirectUrl += `/jobs-in-${formData.jobState.toLowerCase()}`;
        }
        if (formData.searchText) {
            // redirectUrl += `&searchText=${formData.searchText}`;
            redirectUrl += `/${formData.searchText.toLowerCase()}-jobs`;
        }
        onClose && onClose();
        navigate(redirectUrl);
    };

    const handleGovernmentJobs = (event) => {
        event.preventDefault();
        // let redirectUrl = `/job/list?tab=1&page=1`;
        let redirectUrl = ``;
        if (formData.jobState) {
            // redirectUrl += `&location=${capitalizeWords(formData.jobState)}`;
            redirectUrl += `/jobs-in-${formData.jobState.toLowerCase()}`;
        }
        if (formData.searchText) {
            // redirectUrl += `&searchText=${formData.searchText}`;
            redirectUrl += `/${formData.searchText.toLowerCase()}-jobs`;
        }
        onClose && onClose();
        navigate(redirectUrl);
    };

    return (
        <form className={styles.container}>
            <Select
                className={styles.select}
                classNamePrefix="select"
                value={tags.find((item) => item.value === formData.searchText) || { value: "", label: "Select Education" }}
                isSearchable={true}
                name="searchText"
                options={tags}
                onChange={(selectedOption) => {
                    setFormData({ ...formData, searchText: selectedOption.value });
                }}
            />

            <Select
                className={styles.select}
                classNamePrefix="select"
                value={states.find((item) => item.value === formData.jobState) || { value: "", label: "Select State" }}
                isSearchable={true}
                name="jobState"
                options={states}
                onChange={(selectedOption) => {
                    setFormData({ ...formData, jobState: selectedOption.value });
                }}
            />
            <div className={styles.button_group}>
                <button disabled={formData.jobState != "delhi"} onClick={handlePrivateJobs}>
                    Private Jobs
                </button>
                <button onClick={handleGovernmentJobs}>Government Jobs</button>
            </div>
        </form>
    );
};

export default SearchForm;
