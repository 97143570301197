import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useSearchParams } from "react-router-dom";
import { usePrivateJobContext } from "../../../../Api/privatejobPosting.js";
import SearchForm from "../../Components/SearchForm/index.jsx";
import JoinGroupForNotification from "../../../../Components/JoinGroupsForNotification/index.jsx";
import JoinUsForm from "../../../../Components/JoinUsForm/index.jsx";
import { parseJobDetails } from "../../index.jsx";
import JobContainer from "../../Components/JobContainer/index.jsx";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import JobDetails from "../../Components/JobDetails/index.jsx";
import AddNewJobPopUp from "../../Components/AddNewJobPopUp/index.jsx";
import JobTags from "../../Components/JobTags/index.jsx";
import { useVerifyRole } from "../../../../Utils/Helpers/index.js";
import { ROLES_LIST } from "../../../../Utils/Constants/index.js";
import DATA from "../../Data/index.js";
import Utils from "../../Utils/index.js";

const JobListing = ({ initialQuery }) => {
    const navigate = useNavigate();
    const { tab, subTab } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isVerifiedRole = useVerifyRole([ROLES_LIST.ADMIN, ROLES_LIST.INTERN]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [showFilterOption, setShowFilterOption] = useState(false);
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const dropdownRef = useRef(null);
    const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });
    const [pageRangeDisplayed, setPageRangeDisplayed] = useState(4);
    const [marginPagesDisplayed, setMarginPagesDisplayed] = useState(2);
    const [showJobForm, setShowJobForm] = useState(false);
    console.log(initialQuery);
    const [filters, setFilters] = useSearchParams({
        sortOrder: "desc",
        tab: "1",
        page: "1",
        // field: "",
        // jobState: "",
        // searchText: "",
        // tag: "",
    });

    const { getPrivateJobList, privateJobList, postPatchPrivateJobInfo } = usePrivateJobContext();

    // console.log(privateJobList);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowFilterOption(false);
        }
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected + 1;
        setFilters(
            (prev) => {
                prev.set("page", selectedPage);
                return prev;
            },
            { replace: true }
        );
    };

    const handleTab = (tab) => {
        setFilters(
            (prev) => {
                prev.set("tab", tab);
                return prev;
            },
            { replace: true }
        );
    };

    useEffect(() => {
        if (isSmallScreen) {
            setPageRangeDisplayed(1);
            setMarginPagesDisplayed(0);
        } else {
            setPageRangeDisplayed(3);
            setMarginPagesDisplayed(2);
        }
    }, [isSmallScreen]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const getData = (isNavigate) => {
        const query = window.location.href.split("?")[1];
        let url = `/v1/privatejobpost/getprivatejoblist?page=1`;
        if (query) {
            url += `&${query}`;
        }
        if (initialQuery.field) {
            url += `&field=${initialQuery.field}`;
        }
        if (initialQuery.state) {
            url += `&jobState=${initialQuery.state}`;
        }
        if (initialQuery.tag) {
            url += `&tag=${initialQuery.tag}`;
        }
        let refreshFunction;
        if (isNavigate) {
            refreshFunction = () => navigate(`/private-jobs?${query}`);
        }
        getPrivateJobList(url, null, refreshFunction);
        window.scrollTo(0, 0);
    };
    useEffect(() => {
        getData();
    }, [filters, tab, subTab]);

    const getTextFromQueryResult = () => {
        if (tab) {
            if (subTab && subTab != undefined && subTab != null && subTab != "Undefined") {
                return (subTab + " " + tab.replace(/jobs/g, "")).replace(/-/g, " ");
            } else {
                return tab.replace(/-/g, " ");
            }
        }
        return "Private Jobs";
    };
    console.log(tab);
    return (
        <div className={styles.container_wrapper}>
            {isLoggedIn && (!tab || tab?.length < 30) && (
                <div className={styles.job_list_tab}>
                    <div>
                        {[
                            {
                                label: "Pending Job",
                                value: privateJobList.pendingJobs || 0,
                            },
                            {
                                label: "Posted Job",
                                value: privateJobList.completedJobs || 0,
                            },
                            {
                                label: "All Job ",
                                value: privateJobList.totalJobs || 0,
                            },
                        ].map((item, index) => (
                            <p key={index} className={filters.get("tab") == index + 1 ? styles.active : undefined} onClick={() => handleTab(index + 1)}>
                                {item.label}
                                <span>{item.value}</span>
                            </p>
                        ))}
                    </div>
                    <div className={styles.add_job_post}>
                        <button onClick={() => setShowJobForm(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.1em" height="1.1em" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1" />
                            </svg>
                            Post New Job
                        </button>
                    </div>
                </div>
            )}
            {isSmallScreen && (
                <div className={styles.mobile_view_search_form}>
                    <SearchForm formData={filters} setFormData={setFilters} />
                </div>
            )}

            <div className={styles.container}>
                {!isSmallScreen && (
                    <div style={{ top: tab != "listing" ? "0rem" : "" }} className={styles.left_container}>
                        <div>
                            {" "}
                            <SearchForm formData={filters} setFormData={setFilters} />
                        </div>
                        <div>
                            <JobTags
                                data={DATA.tags}
                                defaultValue={filters.get("tag")}
                                onClick={(data) => {
                                    // if (window.location.href.toString().includes("private-jobs/listing")) {
                                    //     let redirectUrl = `/private-jobs?tab=1&page=1`;
                                    //     if (data) {
                                    //         redirectUrl += `&tag=${data}`;
                                    //     }
                                    //     navigate(redirectUrl);
                                    // } else {
                                    navigate(`/private/${data.toLowerCase()}-jobs?tab=1`);
                                    // setFilters((prev) => {
                                    //     if (data && data != prev.get("tag")) {
                                    //         prev.set("tag", data);
                                    //     } else {
                                    //         prev.delete("tag");
                                    //     }
                                    //     prev.delete("field");
                                    //     prev.delete("searchText");
                                    //     prev.delete("jobState");
                                    //     return prev;
                                    // });
                                    // }
                                }}
                            />
                        </div>
                        <div>
                            <JoinGroupForNotification />
                            <JoinUsForm style={{ marginTop: "0.8rem" }} />
                        </div>
                    </div>
                )}
                <div className={styles.right_container}>
                    {(!tab || tab?.length < 30) && (
                        <div className={styles.filters}>
                            <div>
                                <p>
                                    Showing {parseInt((filters.get("page") - 1) * itemsPerPage)} -{" "}
                                    {parseInt(filters.get("page") * itemsPerPage) > privateJobList.totalDocuments ? privateJobList.totalDocuments : parseInt(filters.get("page") * itemsPerPage)} of{" "}
                                    {privateJobList.totalDocuments} Results
                                </p>
                                <div ref={dropdownRef} className={styles.filter_body}>
                                    <h1
                                    // onClick={() => setShowFilterOption(!showFilterOption)}
                                    >
                                        <span>
                                            {getTextFromQueryResult()}
                                            {/* <svg
                                        style={{
                                            transform: showFilterOption ? "rotate(180deg)" : "rotate(0deg)",
                                        }}
                                        width="12"
                                        height="12"
                                        viewBox="0 0 27 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M16.9641 22C15.4245 24.6667 11.5755 24.6667 10.0359 22L0.942634 6.25001C-0.596966 3.58334 1.32753 0.250001 4.40673 0.250001L22.5933 0.250003C25.6725 0.250003 27.597 3.58334 26.0574 6.25L16.9641 22Z"
                                            fill="black"
                                        />
                                    </svg> */}
                                        </span>
                                    </h1>
                                    {showFilterOption && (
                                        <div className={styles.filter_item}>
                                            {[
                                                {
                                                    label: "All",
                                                    value: "All",
                                                },
                                                {
                                                    label: "Doctor",
                                                    value: "doctor",
                                                },
                                                {
                                                    label: "Nursing",
                                                    value: "nursing",
                                                },
                                                {
                                                    label: "Pharmacy",
                                                    value: "pharmacy",
                                                },
                                                {
                                                    label: "Technician",
                                                    value: "technician",
                                                },
                                            ].map((item, index) => (
                                                <h3
                                                    key={index}
                                                    onClick={() => {
                                                        navigate(`/private/${item.value}-jobs?tab=1`);
                                                        // setFilters(
                                                        //     (prev) => {
                                                        //         prev.set("field", item.value);
                                                        //         prev.delete("tag");
                                                        //         prev.delete("searchText");
                                                        //         return prev;
                                                        //     },
                                                        //     { replace: true }
                                                        // );
                                                    }}
                                                >
                                                    {item.label}
                                                </h3>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Link to={"/government-jobs"}>
                                <button onClick={() => navigate("/government-jobs")} className={styles.switch_button}>
                                    Switch to Government Jobs
                                </button>
                            </Link>

                            <div className={styles.sort_by_container}>
                                <label htmlFor="">Sort By : </label>
                                <Select
                                    classNamePrefix="select"
                                    onChange={(data) => {
                                        setFilters((prev) => {
                                            prev.set("sortOrder", data.value);
                                            return prev;
                                        });
                                    }}
                                    name="sortOrder"
                                    defaultValue={DATA.sortByData.find((item) => item.value === filters.get("sortOrder")) || { value: "dsc", label: "Latest" }}
                                    options={DATA.sortByData}
                                ></Select>
                            </div>
                        </div>
                    )}

                    {(!tab || tab?.length < 30) && (
                        <div className={styles.job_listing_container}>
                            {privateJobList?.data?.map((item, index) => (
                                <JobContainer key={index} keyValue={item._id} item={item} />
                            ))}
                            {privateJobList?.data?.length === 0 && (
                                <div className={styles.empty_container}>
                                    <img style={{ maxWidth: "20rem" }} src="../../publicAssets/Dashboard/empty.png" alt="empty icon" />
                                    <h2>Not Found</h2>
                                </div>
                            )}
                            {privateJobList?.data?.length !== 0 && (
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(privateJobList.totalPages || 0)}
                                    marginPagesDisplayed={marginPagesDisplayed}
                                    pageRangeDisplayed={pageRangeDisplayed}
                                    onPageChange={handlePageClick}
                                    containerClassName={`${styles.pagination} pagination`}
                                    activeClassName={styles.active}
                                    forcePage={Math.min(Math.max(filters.get("page") - 1, 0), Math.ceil(privateJobList.totalPages || 0) - 1)} // Ensure forcePage is within bounds
                                />
                            )}
                        </div>
                    )}
                    {tab?.length > 30 && <JobDetails isVerifiedRole={(isVerifiedRole && filters.get("tab") == 1) || user?.roles?.USER === ROLES_LIST.ADMIN} />}
                    {/* <Desclaimer /> */}
                </div>

                {showJobForm && (
                    <AddNewJobPopUp
                        data={{
                            heading: "Add New Job Post",
                            description: `are you sure you want to Add a new Job post`,
                            textInput: true,
                            action: [
                                {
                                    label: "Submit",
                                    actionFunction: (data) => {
                                        let requestBody = {};
                                        if (data.paragraph) {
                                            requestBody = Utils.parseJobDetails(data);
                                        } else {
                                            requestBody = {
                                                companyName: "Company Name",
                                                jobTitle: "Job Title",
                                                numberOfVacancy: "1",
                                                field: data.field || "doctor",
                                                jobState: "delhi",
                                                jobCity: "delhi",
                                                responsibilities: "Write Responsibilities Here...",
                                                //  qualification: "Write Qualifications Here...",
                                                howToApply: "Write How to Apply Here ...",
                                                sections: [
                                                    {
                                                        type: "list",
                                                        heading: "Responsibilities",
                                                        data: ["Write Responsibilities Here..."],
                                                    },
                                                    {
                                                        type: "list",
                                                        heading: "Qualification",
                                                        data: ["Write Qualifications Here..."],
                                                    },
                                                    {
                                                        type: "list",
                                                        heading: "How To Apply",
                                                        data: ["Write How to Apply Here ..."],
                                                    },
                                                    {
                                                        type: "paragraph",
                                                        heading: "About the Company",
                                                        data: "Write About a Company Here ...",
                                                    },
                                                ],
                                                tags: ["MBBS"],
                                                url: "job-title",
                                            };
                                        }
                                        postPatchPrivateJobInfo(
                                            "/v1/privatejobpost/postprivatejob",
                                            requestBody,

                                            getData
                                        );
                                        setShowJobForm(false);
                                    },
                                    className: "yes_button",
                                    // loading:loading
                                },
                                {
                                    label: "Cancel",
                                    actionFunction: () => {
                                        setShowJobForm(false);
                                    },
                                    className: "no_button",
                                    // loading:loading
                                },
                            ],
                            setClose: () => {
                                setShowJobForm(false);
                            },
                        }}
                    />
                )}
            </div>
            <div className={styles.mobile_view_notification}>
                <JoinGroupForNotification style={{ marginTop: "0" }} />
                <div className={styles.tags_container}>
                    <div className={styles.tags_heading}>
                        <h2>
                            Search Job By <span>Education</span>
                        </h2>
                    </div>

                    <JobTags
                        data={DATA.tags}
                        defaultValue={filters.get("tag")}
                        onClick={(data) => {
                            navigate(`/private/${data.toLowerCase()}-jobs?tab=1`);
                            // if (!window.location.href.toString().includes("private-jobs/listing")) {
                            //     let redirectUrl = `/private-jobs/listing?tab=1&page=1`;
                            //     if (data) {
                            //         redirectUrl += `&tag=${data}`;
                            //     }
                            //     navigate(redirectUrl);
                            // } else {
                            //     setFilters((prev) => {
                            //         if (data && data != prev.get("tag")) {
                            //             prev.set("tag", data);
                            //         } else {
                            //             prev.delete("tag");
                            //         }
                            //         prev.delete("field");
                            //         prev.delete("searchText");
                            //         prev.delete("jobState");
                            //         return prev;
                            //     });
                            // }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default JobListing;
