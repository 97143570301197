import React, { useState } from "react";
import styles from "./index.module.css";
import { Footer, Navbar } from "../../Layout";
import ReviewForm from "./components/reviewForm";
import ICON from "./Utils/icons";
import Loader from "./components/loader";
import companyLogo from "./assets/companyLogo.svg";

const Reviews = () => {
    const [progress, setProgress] = useState(5);
    return (
        <main className={styles.container_wrapper}>
            <div className={styles.navbar}>
                <div
                    onClick={() => {
                        navigate("/");
                    }}
                    className={styles.breadcom_container}
                >
                    <img src={companyLogo} alt="companyLogo" />
                </div>

                <div className={styles.menu_list}>
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="31" height="31" rx="10" fill="#197BFF" />
                        <path d="M9.89062 9.23438H22.4225" stroke="white" strokeWidth="3" strokeLinecap="round" />
                        <path d="M9.89062 15.1719L22.4225 15.1719" stroke="white" strokeWidth="3" strokeLinecap="round" />
                        <path d="M16.1562 21.7656L22.4222 21.7656" stroke="white" strokeWidth="3" strokeLinecap="round" />
                    </svg>
                    {/* {showDropdown && <MobileViewNavbarSubMenu />} */}
                </div>
            </div>
            {/* <Navbar /> */}
            {/* <section>
                <div className={styles.overlay}></div>
                <div className={styles.container}>
                    <div className={styles.title}>
                        <h1>
                            <span>Hospital</span> Review
                        </h1>
                        <p>Rate the medical college/ Hospital under the following criteria</p>
                    </div>
                    <div className={styles.body}>
                        <ReviewForm />
                    </div>
                </div>
            </section> */}

            <section>
                <div className={styles.mobile_container}>
                    <div className={styles.sticky_header}>
                        <div className={styles.mobile_title}>
                            <h1>
                                <span>Hospital</span> Review
                            </h1>
                            <p>Rate your department anonymously!</p>
                        </div>
                        <div className={styles.progress_container}>
                            <div>
                                {ICON.SECURE}{" "}
                                <p>
                                    Your name will remain <span>100% confidential</span>
                                </p>
                            </div>
                            <Loader progress={progress} />
                        </div>
                    </div>
                    <div className={styles.body}>
                        <ReviewForm setProgress={setProgress} />
                    </div>
                </div>
            </section>

            {/* <Footer /> */}
        </main>
    );
};

export default Reviews;
