const constants = {
    collegeList: [
        "Aarupadai Veedu Medical College, Pondicherry",
        "Abhishek I Mishra Memorial Medical College & Research, Bhiali",
        "Acharya Harihar Regional Cancer Centre, Cuttack",
        "Acharya Shri Chander College of Medical Sciences, Jammu",
        "ACPM Medical College, Dhule",
        "ACS Medical College and Hospital, Chennai",
        "ACSR Government Medical College Nellore",
        "Adesh Institute of Medical Sciences & Research, Bhatinda",
        "Adesh Medical College and Hospital, Shahabad, Kurukshetra, Haryana",
        "Adichunchanagiri Institute of Medical Sciences Bellur",
        "Agartala Government Medical College,Agartala",
        "Ajay Sangaal Institute of Medical Sciences and Ayushman Hospital",
        "A J Institute of Medical Sciences & Research Centre, Mangalore",
        "Akash Institute of Medical Sciences & Research Centre, Devanhalli, Bangalore, Karnataka",
        "Al-Ameen Medical College,Bijapur",
        "Al-Azhar Medical College and Super Speciality Hospital, Thodupuzha",
        "Al Falah School of Medical Sciences & Research Centre, Faridabad",
        "All India Institute of Hygiene & Public Health, Kolkata",
        "All India Institute of Medical Sciences, Bhatinda",
        "All India Institute of Medical Sciences, Bhopal",
        "All India Institute of Medical Sciences, Bhubaneswar",
        "All India Institute of Medical Sciences, Bibinagar",
        "All India Institute of Medical Sciences, Bilaspur",
        "All India Institute of Medical Sciences, Deoghar",
        "All India Institute of Medical Sciences, Gorakhpur",
        "All India Institute of Medical Sciences, Guwahati",
        "All India Institute of Medical Sciences, Jodhpur",
        "All India Institute of Medical Sciences, Kalyani, Nadia",
        "All India Institute of Medical Sciences, Madurai",
        "All India Institute of Medical Sciences, Mangalagiri, Vijayawada",
        "All India Institute of Medical Sciences, Nagpur",
        "All India Institute of Medical Sciences, New Delhi",
        "All India Institute of Medical Sciences, Patna",
        "All India Institute of Medical Sciences, Rae Bareli",
        "All India Institute of Medical Sciences, Raipur",
        "All India Institute of Medical Sciences, Rajkot",
        "All India Institute of Medical Sciences, Rishikesh",
        "All India Institute of Medical Sciences, Vijaypur",
        "All India Institute of Physical Med. and Rehabilitation, Mumbai",
        "Alluri Sitaram Raju Academy of Medical Sciences, Eluru",
        "Amala Institute of Medical Sciences, Thrissur",
        "Amaltas Institute of Medical Sciences, Dewas",
        "American International Institute of Medical Sciences, Bedwas",
        "Amrita School of Medicine, Elamkara, Kochi",
        "Amrita School of Medicine, Faridabad",
        "Ananta Institute of Medical Sciences & Research Centre, Rajsamand",
        "Ananya College of Medicine & Research",
        "Andaman & Nicobar Islands Institute of Medical Sciences, Port Blair",
        "Andhra Medical College, Visakhapatnam",
        "Annaii Medical College and Hospital",
        "Annaii Medical College and Hospital, Pennalur, Kanchipuram",
        "Annapoorna Medical College & Hospital, Salem",
        "Anugrah Narayan Magadh Medical College, Gaya",
        "Apollo Institute of Medical Sciences and Research, Chittoor",
        "Apollo Institute of Medical Sciences and Research, Hyderabad",
        "Aravind Eye Hospitals & Post Graduate Institute of Ophthalmology, Madurai",
        "Armed Forces Medical College, Pune",
        "Army College of Medical Sciences, New Delhi",
        "Army Hospital Research & Referral, New Delhi",
        "Arunai Medical College And Hospital",
        "Arundathi Institute of Medical Sciences",
        "Ashwini Rural Medical College, Hospital & Research Centre, Solapur",
        "Assam Medial College, Dibrugarh",
        "Atal Bihari Vajpayee Institute of Medical Sciences and Dr. RML Hospital, New Delhi",
        "Autonomous State Medical College, Akbarpur",
        "Autonomous State Medical College and Hospital Auraiya",
        "Autonomous State Medical College and Hospital Gonda",
        "Autonomous State Medical College and Hospital Kaushambi",
        "Autonomous State Medical College and Hospital Lakhimpuri kheri",
        "Autonomous State Medical College Kushinagar",
        "Autonomous State Medical College, Lalitpur",
        "Autonomous State Medical College Pilibhit",
        "Autonomous State Medical College Pratapgarh",
        "Autonomous State Medical College, Siddharthnagar",
        "Autonomous State Medical College Society, Etah, Uttar Pradesh",
        "Autonomous State Medical College Society, Fatehpur",
        "Autonomous State Medical College Society Ghazipur",
        "Autonomous State Medical College Society, Hardoi",
        "Autonomous State Medical College, Sonebhadra",
        "Autonomous State Medical College Sultanpur",
        "Autonomous State Society Medical College Mirzapur",
        "Ayaan Institute of Medical Sciences, Teaching Hospital & Research Centre, Kanaka Mamidi, R.R. Dist",
        "Azeezia Instt of Medical Science,Meeyannoor,Kollam",
        "Baba Kina Ram Autonomous State Medical College and Hospital",
        "Banas Medical College and Research Institute, Palanpur, Gujarat",
        "Bangalore Medical College and Research Institute, Bangalore",
        "Bankura Sammilani Medical College, Bankura",
        "Barasat Government Medical College & Hospital",
        "Basaveswara Medical College and Hospital, Chitradurga",
        "Belagavi Institute of Medical Sciences, Belagavi",
        "Believers Church Medical College Hospital, Thiruvalla, Kerala",
        "BGS Global Institute of Medical Sciences, Bangalore",
        "BGS Medical College and Hospital",
        "Bhaarat Medical College & Hospital",
        "Bhabha Atomic Research Centre,Mumbai",
        "Bhagwan Mahavir Institute of Medical Sciences, Pawapuri (Formely known as Vardhman Institute of Medical Sciences, Pawapuri)",
        "Bhagyoday Medical College",
        "Bharati Vidyapeeth Deemed University Medical College & Hospital, Sangli",
        "Bharati Vidyapeeth University Medical College, Pune",
        "Bharatratna Atal Bihari Vajpayee Medical College, Pune",
        "Bhaskar Medical College, Yenkapally",
        "Bhopal Memorial Hospital & Research Centre, Bhopal",
        "Bidar Institute of Medical Sciences,Bidar",
        "B. J. Govt. Medical College, Pune",
        "B J Medical College, Ahmedabad",
        "B.K.L. Walawalkar Rural Medical College, Ratnagiri",
        "Bombay Hospital Institute of Medical Sciences, Mumbai",
        "BPS Government Medical College for Women, Sonepat",
        "BRD Medical College, Gorakhpur",
        "Bundelkhand Medical College, Sagar",
        "Burdwan Medical College, Burdwan",
        "Calcutta National Medical College, Kolkata",
        "Career Instt. Of Medical Sciences & Hospital, Lucknow",
        "Central Health Education Bureau, New Delhi",
        "Central Institute of Psychiatry, Ranchi",
        "Chacha Nehru Bal Chikitsalaya",
        "Chalmeda Anand Rao Insttitute Of Medical Sciences, Karimnagar",
        "Chamrajanagar Institute of Medical Sciences, Karnataka",
        "Chandulal Chandrakar Memorial Govt. Medical College, Durg",
        "Chengalpattu Medical College, Chengalpattu",
        "Chettinad Hospital & Research Institute, Kanchipuram",
        "Chhattisgarh Institute of Medical Sciences, Bilaspur",
        "Chikkaballapura Institute of Medical Sciences",
        "Chikkamagaluru Institute of Medical Sciences, Chikkamagaluru",
        "Chintpurni Medical College, Pathankot, Gurdaspur",
        "Chirayu Medical College and Hospital, Bairagarh,Bhopal",
        "Chitradurga Medical College and Research Institute",
        "Chittaranjan National Cancer Institute",
        "Chittaranjan Seva Sadan Hospital, Kolkata",
        "Christian Medical College, Ludhiana",
        "Christian Medical College, Vellore",
        "CMR Institute of Medical Sciences",
        "Coimbatore Medical College, Coimbatore",
        "College of Medicine and JNM Hospital,Kalyani,Nadia",
        "College of Medicine and Sagore Dutta Hospital, Kolkata",
        "Command Hospital Air Force, Bangalore",
        "Command Hospital,Chandimandir",
        "Command Hospital, Kolkata",
        "Command Hospital, Lucknow",
        "Coochbehar Government Medical College & Hospital, Coochbehar, WB",
        "CU Shah Medical College, Surendra Nagar",
        "Darbhanga Medical College, Lehriasarai",
        "Datta Meghe Medical College, Nagpur",
        "Dayanand Medical College & Hospital, Ludhiana",
        "Deccan College of Medical Sciences, Hyderabad",
        "Dhanalakshmi Srinivasan Institute of Medical Sciences and Hospital,",
        "Dhanalakshmi Srinivasan Medical College and Hospital,Perambalur",
        "Dharanidhar Medical College & Hospital (DDMCH), Keonjhar,",
        "Dharwad Institute of Mental Health and Neuro Sciences, Dharwad",
        "Dhubri Medical College, Dhubri",
        "Diamond Harbour Government Medical College and Hospital, West Bengal",
        "Diphu Medical College & Hospital, Diphu, Assam",
        "Doon Medical College, Dehradun, Uttarakhand",
        "Dr. Baba Saheb Ambedkar Medical College, Rohini, Delhi",
        "Dr. B. Borooah Cancer Institute (Regional Cancer Centre), Guwahati",
        "Dr. B.C. Roy Memorial Hospital for Children, Kolkata",
        "Dr. B C Roy Post Graduate Institute of Paediatric Sciences,Kolkata",
        "Dr BR Ambedkar Medical College, Bangalore",
        "Dr B R Ambedkar State Institute of Medical Sciences, SAS Nagar , Mohali",
        "Dr. Chandramma Dayananda Sagar Instt. of Medical Education & Research, Harohalli, Hubli",
        "Dr. D Y Patil Medical College, Hospital and Research Centre, Pimpri, Pune",
        "Dr. D Y Patil Medical College, Kolhapur",
        "DRIEMS Institute of Health Sciences and Hospital, Kairapari",
        "Dr.Kiran C.Patel Medical College and Research Institute",
        "Dr. M.K. Shah Medical College & Research Centre, Ahmedabad",
        "Dr. Moopen s Medical College, Wayanad, Kerala",
        "Dr. N.D. Desai Faculty of Medical Science and Research, Nadiad",
        "Dr. N Y Tasgaonkar Institute of Medical Science",
        "Dr. Panjabrao Alias Bhausaheb Deshmukh Memorial Medical College, Amravati",
        "Dr. Patnam Mahender Reddy Institute of Medical Sciences, Chevella, Rangareddy",
        "Dr. P.S.I. Medical College , Chinoutpalli",
        "Dr. Radhakrishnan Government Medical College, Hamirpur, H.P",
        "Dr. Rajendar Prasad Government Medical College, Tanda, H.P",
        "Dr. Rajendra Gode Medical College, Amravati",
        "Dr. Ram Manohar Lohia Institute of Medical Sciences,Lucknow",
        "Dr. Shankarrao Chavan Govt. Medical College, Nanded",
        "Dr SN Medical College, Jodhpur",
        "Dr. Somervel Memorial CSI Hospital & Medical College, Karakonam, Thiruvananthapuram",
        "Dr S S Tantia Medical College Hospital & Research Centre",
        "Dr. Ulhas Patil Medical College & Hospital, Jalgaon",
        "Dr Vaishampayan Memorial Medical College, Solapur",
        "Dr.Vasantrao Pawar Med. Col. Hosp. & Research Centre,Nasik (Prev. NDMVP Samaj Medical College)",
        "Dr. Vithalrao Vikhe Patil Foundations Medical College & Hospital, Ahmednagar",
        "Dr. VRK Womens Medical College, Aziznagar",
        "Dumka Medical College, Dighi Dumka",
        "East Point College of Medical Sciences & Research Centre, Bangalore",
        "East West Institute of Medical Sciences and Research",
        "Employees State Insurance Coporation Medical College, Sanath Nagar, Hyderabad",
        "Employees State Insurance Corporation Medical College,Alwar",
        "Employees State Insurance Corporation Medical College, Bangalore",
        "Employees State Insurance Corporation Medical College, Faridabad",
        "Employees State Insurance Corporation Medical College, Gulbarga",
        "Employees State Insurance Corporation Medical College, Joka, Kolkata",
        "Employees State Insurance Corporation Medical College,Patna",
        "Era Lucknow Medical College , Lucknow",
        "E.S.I.C. Medical College & Hospital, K.K. Nagar, Chennai earlier known as ESIC Medical College & PGIMSR, K.K Nagar, Chennai",
        "ESI-PGIMSR,ESI-Hospital,Andheri(E),Mumbai",
        "ESI-PGIMSR,ESI-Hospital,Basaidarapur,New Delhi",
        "ESI-PGIMSR,ESI Hospital.Manicktala,Kolkata",
        "ESI-PGIMSR,ESI-Hospital,Parel,Mumbai",
        "Faculty of Medicine and Health Sciences, Gurgaon (Formarly SGGST Medical College & R Centre,Gurgaon)",
        "Faculty of Medicine , Sri Lalithambigai Medical College and Hospital",
        "Fakhruddin Ali Ahmed Medical College, Barpeta, Assam",
        "Father Colombo Institute of Medical Sciences",
        "Father Mullers Medical College, Mangalore",
        "Fathima Instt. of Medical Sciences,Kadapa",
        "F.H. Medical College & Hospital, Etamdapur, Agra",
        "Gadag Institute of Medical Sciences, Mallasamudra, Mulgund Road, Gadag",
        "Gajra Raja Medical College, Gwalior",
        "Gandhi Medical College, Bhopal",
        "Gandhi Medical College, Secunderabad",
        "Gauhati Medical College, Guwahati",
        "Gautam Buddha Chikitsa Mahavidyalaya, Dehradum",
        "Gayathri Vidya Parishad Institute of Health Care & Medical Technology, Visakhapatnam",
        "G.B. Pant Institute of Postgraduate Medical Education and Research, New Delhi",
        "G.C.R.G. Institute of Medical Sciences, Lucknow",
        "GCS Medical College, Ahmedabad",
        "Geetanjali Medical College & Hospital,Udaipur",
        "Gian Sagar Medical College & Hospital, Patiala",
        "GITAM Institute of Medical Sciences and Research, Visakhapatnam",
        "GMERS Medical College, Dharpur Patan",
        "GMERS Medical College, Gandhinagar",
        "GMERS Medical College, Gotri, Vadodara",
        "GMERS Medical College, Hadiyol, Himmatnagar",
        "GMERS Medical College, Junagadh",
        "GMERS Medical College, Navsari",
        "GMERS Medical College, Rajpipla",
        "GMERS Medical College, Sola, Ahmedabad",
        "GMERS Medical College, Vadnagar, Mehsana",
        "GMERS Medical College, Valsad",
        "Goa Medical College, Panaji",
        "Gouri Devi Institute of Medical Sciences and Hospital, Durgapur",
        "Government Allopathic Medical College, Banda, UP",
        "Government Dharmapuri Medical College, Dharmapuri",
        "Government Erode Medical College & Hospital, Perundurai (Formerly IRT Perundurai Medical College)",
        "Government Institute of Medical Sciences, Kasna, Greater Noida",
        "Government Medical College, Akola",
        "Government Medical College, Alibag",
        "Government Medical College, Alwar",
        "Government Medical College, Ambernath",
        "Government Medical College, Amravati",
        "Government Medical College, Amritsar",
        "Government Medical College, Ananthapuram",
        "Government Medical College, Anantnag",
        "Government Medical college and District Hospital, Ratnagiri",
        "Government Medical college and Hospital , Haridwar",
        "Government Medical College and Hospital, Jajpur",
        "Government Medical College,Ariyalur",
        "Government Medical College, Aurangabad",
        "Government Medical College, Badaun, U.P.",
        "Government Medical College, Banswara",
        "Government Medical College, Baramulla",
        "Government Medical College, Baran",
        "Government Medical College, Barmer",
        "Government Medical College, Bettiah",
        "Government Medical College, Bhadradri Kothagudem",
        "Government Medical College, Bhandara",
        "Government Medical College, Bharatpur, Rajasthan",
        "Government Medical College (Bharat Ratna Shri Atal Bihari Vajpyee Memorial Med. Col.), Rajnandgaon",
        "Government Medical College, Bhavnagar",
        "Government Medical College, Bhilwara, Rajasthan",
        "Government Medical College, Buldhana",
        "Government Medical College, Bundi",
        "Government Medical College, Chandigarh",
        "Government Medical College, Chandrapur",
        "Government Medical College, Chhindwara, MP",
        "Government Medical College, Chittorgarh",
        "Government Medical College, Churachandpur",
        "Government Medical College, Churu",
        "Government Medical College, Datia, MP",
        "Government Medical College, Dausa",
        "Government Medical College, Dholpur",
        "Government Medical College,Dindigul",
        "Government Medical College, Dungarpur",
        "Government Medical College, Eluru",
        "Government Medical College, Ernakulam",
        "Government Medical College & ESIC Hospital, Coimbatore, Tamil Nadu.",
        "Government Medical College, Faizabad",
        "Government Medical College, Firozabad",
        "Government Medical College, Gadchiroli",
        "Government Medical College, Gondia",
        "Government Medical College, Handwara",
        "Government Medical College, Hanumangarh",
        "Government Medical College, Hingoli",
        "Government Medical College & Hospital, Baramati",
        "Government Medical College & Hospital (Renamed as Bhima Bhoi Medical College & Hospital), Balangir",
        "Government Medical College & Hospital (Renamed as Fakir Mohan Medical College & Hospital), Balasore",
        "Government Medical College, Idukki.",
        "Government Medical College (Institute of Integrated Medical Sciences), Yakkara, Palakkad",
        "Government Medical College, Jagtial",
        "Government Medical College, Jalgaon",
        "Government Medical College, Jalna",
        "Government Medical College, Jammu",
        "Government Medical College Jangaon",
        "Government Medical College, Jayashankar Bhupalpally",
        "Government Medical College, Jhunjhunu",
        "Government Medical College, Jogulamba",
        "Government Medical College, Kadapa",
        "Government Medical College Kallakurichi",
        "Government Medical College, Kamareddy",
        "Government Medical College, Kanker",
        "Government Medical College, Kannauj",
        "Government Medical College, Karauli",
        "Government Medical College, Karimnagar",
        "Government Medical College, Karur",
        "Government Medical College, Kathua",
        "Government Medical College, Khammam",
        "Government Medical College, Khandwa, MP",
        "Government Medical College, Konni",
        "Government Medical College, Korba",
        "Government Medical College, Kota",
        "Government Medical College, Kottayam",
        "Government Medical College, Kozhikode, Calicut",
        "Government Medical College Krishnagiri",
        "Government Medical College, Kumuram Bheem Asifabad",
        "Government Medical College, Latur",
        "Government Medical College, Machilipatnam",
        "Government Medical College, Mahabubabad",
        "Government Medical College, Mahabubnagar",
        "Government Medical College, Mahasamund",
        "Government Medical College, Maheshwaram",
        "Government Medical College, Mancherial",
        "Government Medical College, Manjeri, Malapuram Dist.",
        "Government Medical College, Medak",
        "Government Medical College, Miraj",
        "Government Medical College, Morbi",
        "Government Medical College, Mulugu",
        "Government Medical College, Mumbai",
        "Government Medical College Nagapattinam",
        "Government Medical College, Nagarkurnool",
        "Government Medical College, Nagaur",
        "Government Medical College, Nagpur",
        "Government Medical College, Nahan, Sirmour H.P.",
        "Government Medical College, Nalgonda",
        "Government Medical College, Namakkal",
        "Government Medical College, Nandurbar",
        "Government Medical College, Nandyal",
        "Government Medical College, Narayanpet",
        "Government Medical College, Narsampet",
        "Government Medical College, Nashik",
        "Government Medical College, Nirmal",
        "Government Medical College, Nizamabad",
        "Government Medical College, Omandurar",
        "Government Medical College, Ongole (Previously known as Rajiv Gandhi Institute of Medical Sciences, Ongole,)",
        "Government Medical College, Osmanabad",
        "Government Medical College, Paderu",
        "Government Medical College, Pali, Rajasthan",
        "Government Medical College, Panchmahal Godhra",
        "Government Medical College, Parbhani",
        "Government Medical College, Parippally, Kollam",
        "Government Medical College, Patiala",
        "Government Medical College, Porbandar",
        "Government Medical College (Prev.Uttarakhand Forest Hospital Trust Med.Col.), Haldwani",
        "Government Medical College, Pudukottai, Tamil Nadu",
        "Government Medical College, Purnea",
        "Government medical College, Quthbullapur",
        "Government Medical College, Rajamahendravaram",
        "Government Medical College, Rajanna Sircilla",
        "Government Medical College, Rajouri, J&K",
        "Government Medical College, Ramagundam",
        "Government Medical College Ramanathapuram",
        "Government Medical College, Rampur, Basti",
        "Government Medical College, Ratlam",
        "Government Medical College, Sangareddy",
        "Government Medical College ,Satara",
        "Government Medical College, Satna",
        "Government Medical College, Sawai Madhopur",
        "Government Medical College, Seoni",
        "Government Medical College, Shahdol, MP",
        "Government Medical College, Shahjahanpur, UP",
        "Government Medical College, Shivpuri, MP",
        "Government Medical College, Siddipet",
        "Government Medical College, Sindhudurg",
        "Government Medical College, Sirohi",
        "Government Medical College, Sri Ganganagar",
        "Government Medical College, Srinagar",
        "Government Medical College, Sundargarh",
        "Government Medical College & Super facility Hospital, Azamgarh",
        "Government Medical College, Surat",
        "Government Medical College, Suryapet",
        "Government Medical College, The Nilgiris",
        "Government Medical College, Thiruvallur",
        "Government Medical College, Thrissur",
        "Government Medical College ,Tiruppur",
        "Government Medical College, Udhampur",
        "Government Medical College, Vidisha, MP",
        "Government Medical College, Vikarabad",
        "Government Medical College, Virudhunagar",
        "Government Medical College, Vizianagaram",
        "Government Medical College, Wanaparthy",
        "Government Medical College, Washim",
        "Government Medical College, Yadadri",
        "Government Siddhartha Medical College, Vijaywada",
        "Government Sivagangai Medical College, Sivaganga",
        "Government Thiruvannamalai Medical College, Thiruvannamalai",
        "Government Vellore Medical College, Vellore",
        "Government Villupuram Medical College, Villupuram",
        "Govt. Medical College, Doda, Kashmir",
        "Govt. Medical College, Kolkata",
        "Govt. Medical College,Pariyaram, Kannur (Prev. Known as Academy of Medical Sciences)",
        "Govt. Mohan Kumaramangalam Medical College, Salem- 30",
        "Grant Medical College, Mumbai",
        "Graphic Era Institute of Medical Sciences",
        "Great Eastern Medical School and Hospital,Srikakulam",
        "G R Medical College Hospital & Research Centre",
        "GSL Medical College, Rajahmundry",
        "G.S. Medical College & Hospital, Hapur, UP",
        "GSVM Medical College, Kanpur",
        "Gujarat Adani Institute of Medical Sciences, Bhuj",
        "Gulbarga Institute of Medical Sciences, Gulbarga",
        "Guntur Medical College, Guntur",
        "Guru Govind Singh Medical College, Faridkot",
        "Guru Nanak Dev University, Amritsar",
        "Gwalior Mansik Arogyashala, Madhya Pradesh",
        "Hamdard Institute of Medical Sciences & Research, New Delhi",
        "Hassan Institute of Medical Sciences, Hassan",
        "Haveri Institute of Medical Sciences, Haveri",
        "Hazaribagh Medical College, Hazaribagh",
        "H.B.T. Medical College & Dr. R.N. Cooper Municipal General Hospital, Juhu, Mumbai",
        "Heritage Institute of Medical Sciences, Varanasi",
        "Himalaya Medical College and Hospital, Patna",
        "Himalayan Institute of Medical Sciences, Dehradun",
        "Hind Institute of Medical Sciences , Barabanki",
        "Hind Institute of Medical Sciences, Sitapur",
        "Hindu Rao Hospital, Delhi",
        "Hi-Tech Medical College & Hospital, Bhubaneswar",
        "Hi-Tech Medical College & Hospital, Rourkela",
        "Homi Bhabha Cancer Hospital and Research Centre, Visakhapatnam",
        "Homi Bhabha Cancer Hospital, Sangrur, Punjab",
        "Homi Bhabha Cancer Hospital, Varanasi, Uttar Pradesh",
        "ICARE Institute of Medical Sciences & Research, Haldia, Purba Midanpore",
        "Index Medical College Hospital & Research Centre,Indore",
        "Indian Institute of Medical Science & Research, Jalna",
        "Indira Gandhi Institute of Child Health, Bangalore",
        "Indira Gandhi Institute of Medical Sciences,Sheikhpura, Patna",
        "Indira Gandhi Medical College & Hospital, Nagpur",
        "Indira Gandhi Medical College & Research Institute, Puducherry",
        "Indira Gandhi Medical College, Shimla",
        "Indira Medical College & Hospitals, Thiruvallur",
        "Institute of Aerospace Medicine Indian Air Force, Bangaluru",
        "Institute of Child Health, Kolkata",
        "Institute of Human Behaviour and Allied Sciences, Delhi",
        "Institute of Liver and Biliary Sciences, New Delhi",
        "Institute of Medical Science and Research, Vidyagiri, Satara",
        "Institute of Medical Sciences, BHU, Varansi",
        "Institute of Medical Sciences & SUM Hospital",
        "Institute of Mental Health and Hospital, Agra",
        "Institute of Navel Medicine, INHS, Aswini",
        "Institute of Nephro-Urology, Bangalore",
        "Institute of Nuclear Medicine & Allied Sciences, New Delhi",
        "Institute of Opthalmology Joseph Eye Hospital, Tiruchirapalli",
        "Institute of Postgraduate Medical Education & Research, Kolkata",
        "Instt. Of Medical Sciences & SUM Hospital, Bhubaneswar",
        "Integral Institute of Medical Sciences & Research, Lucknow",
        "IQ-City Medical College, Burdwan",
        "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College JGMMMC",
        "Jagannath Gupta Institute of Medical Sciences & Hospital, Kolkata",
        "Jaipur National University Institute of Medical Sciences and Resarch Centre, Jagatpura, Jaipur",
        "Jalpaiguri Government Medical College",
        "Jannayak Karpoori Thakur Medical College & Hospital, Madhepura, Bihar",
        "Jawaharlal Institute of Postgraduate Medical Education & Research, Puducherry",
        "Jawaharlal Nehru Institute of Medical Sciences,Porompet,Imphal",
        "Jawaharlal Nehru Medical College, Ajmer",
        "Jawaharlal Nehru Medical College, Aligarh",
        "Jawaharlal Nehru Medical College, Belgaum",
        "Jawaharlal Nehru Medical College, Bhagalpur",
        "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha",
        "Jhalawar Medical College, Jhalawar",
        "Jhargram Government Medical College & Hospital",
        "JIET Medical College And Hospital",
        "JIS School of Medical Science & Research, Howrah",
        "JJM Medical College, Davangere",
        "JMN Medical College, Nadia",
        "Jorhat Medical College & Hospital , Jorhat",
        "J R Medical College and Hospital",
        "JSS Medical College, Mysore",
        "Jubilee Mission Medical College & Research Institute, Thrissur",
        "Kakatiya Medical College, Warangal",
        "Kalinga Institute of Medical Sciences, Bhubaneswar",
        "Kalpana Chawala Govt. Medical College, Karnal, Haryana",
        "Kalyan Singh Government Medical College, Hospital and Kasturba Mahila Chikitsalaya",
        "Kamineni Academy of Medical Sciences & Research Center, Hyderabad",
        "Kamineni Institute of Medical Sciences, Narketpally",
        "Kanachur Institute of Medical Sciences, Mangalore",
        "Kannur Medical College, Kannur",
        "KanyaKumari Government Medical College, Asaripallam",
        "Kanyakumari Medical Mission Research Centre",
        "K A P Viswanathan Government Medical College, Trichy",
        "Karnataka Institute of Medical Sciences, Hubballi",
        "Karpagam Faculty of Medical Sciences & Research, Coimbatore",
        "Karpaga Vinayaga Institute of Medical Sciences,Maduranthagam",
        "Karuna Medical College, Palakkad",
        "Karwar Institute of Medical Sciences, Karwar",
        "Kasturba Hospital, New Delhi",
        "Kasturba Medical College, Mangalore",
        "Kasturba Medical College, Manipal",
        "Katihar Medical College, Katihar",
        "Katuri Medical College, Guntur",
        "K.D. Medical College Hospital & Research Centre, Mathura",
        "Kempegowda Institute of Medical Sciences, Bangalore",
        "Kerala Medical College, Mangode, Palakkad",
        "Khaja Bandanawaz University - Faculty of Medical Sciences, Gulbarga",
        "Kidwai Memorial Institute of Oncology, Bangalore",
        "Kilpauk Medical College, Chennai",
        "King George Medical University, Lucknow",
        "Kiran Medical College, Surat",
        "KJ Somaiyya Medical College & Research Centre, Mumbai",
        "KMCH Institute of Health Sciences and Research, Coimbatore",
        "KMC Medical College & Hospital",
        "KMCT Medical College,Kozhikode, Calicut",
        "Kodagu Institute of Medical Sciences, Kodagu",
        "Kokrajhar Medical College",
        "Konaseema Institute of Medical Sciences & Research Foundation, Amalapuram",
        "Koppal Institute of Medical Sciences, Koppal",
        "KPC Medical College,Jadavpur,Kolkata",
        "Krishna Institute of Medical Sciences, Karad",
        "Krishna Mohan Medical College & Hospital, Mathura",
        "Krishnanagar Institute of Medical Sciences",
        "K S Hegde Medical Academy, Mangalore",
        "Kurnool Medical College, Kurnool",
        "K V G Medical College, Sullia",
        "Lady Hardinge Medical College, New Delhi",
        "Lakhimpur Medical College",
        "Late Shri Baliram Kashyap Memorial NDMC Govt. Medical College, Jagdalpur",
        "Late Shri Lakhi Ram Agrawal Memorial Govt. Medical College, Raigarh",
        "Laxmi Chandravansi Medical College & Hospital",
        "LLRM Medical College, Meerut",
        "LNCT Medical College & Sewakunj Hospital, Indore",
        "L.N. Medical College and Research Centre,Bhopal",
        "Lokmanya Tilak Municipal Medical College, Sion, Mumbai",
        "Lokopriya Gopinath Bordoloi Regional Institute of Mental Health, Tezpur",
        "Lord Buddha Koshi Medical College and Hospital, Saharsa",
        "Madha Medical College and Hospital, Thandalam, Chennai",
        "Madhubani Medical College, Madhubani",
        "Madras Medical College, Chennai",
        "Madurai Medical College, Madurai",
        "Mahadevappa Rampure Medical College, Kalaburagi, Gulbarga",
        "Mahamaya Rajkiya Allopathic Medical College, Ambedkarnagar",
        "Maharaja Agrasen Medical College, Agroha",
        "Maharajah Institute of Medical Sciences, Vizianagaram",
        "Maharani Laxmi Bai Medical College, Jhansi",
        "Maharashtra Institute of Medical Education & Research, Talegaon,Pune",
        "Maharashtra Institute of Medical Sciences & Research, Latur",
        "Maharashtra Post Graudate Institute of Medical Education & Research",
        "Maharishi Markandeshwar College of Medical Sciences & Research, Sadopur",
        "Maharishi Markandeshwar Institute Of Medical Sciences & Research, Mullana, Ambala",
        "Maharishi Markandeshwar Medical College & Hospital, Solan",
        "Maharshi Devraha Baba Autonomous State Medical College, Deoria",
        "Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha",
        "Mahatma Gandhi Medical College and Hospital, Sitapur, Jaipur",
        "Mahatma Gandhi Medical College & Research Institute, Pondicherry",
        "Mahatma Gandhi Mission Medical College, Vashi",
        "Mahatma Gandhi Missions Medical College, Aurangabad",
        "Mahatma Gandhi Missions Medical College, Navi Mumbai",
        "Mahatma Gandhi Missions Medical College, Nerul, Navi Mumbai",
        "Mahatma Vidur Autonomous State Medical College",
        "Mahaveer Institute of Medical Sciences & Research, Bhopal",
        "Mahavir Institute of Medical Sciences, Vikarabad, Telengana",
        "Maheshwara Medical College, Chitkul, Patancheru, Medak",
        "Major S D Singh Medical College and Hospital, Fathehgarh, Farrukhabad",
        "Malabar Cancer Centre Post Graduate Institute of Oncology Sciences and Research, Modakkallur",
        "Malabar Medical College, Kozhikode,Calicut",
        "Malankara Orthodox Syrian Church Medical College, Kolenchery",
        "Malda Medical College & Hospital, Malda",
        "Malla Reddy Institute of Medical Sciences, Hyderabad",
        "Mallareddy Medical College for Women, Hyderabad",
        "Mamata Academy of Medical Sciences, Bachupally",
        "Mamata Medical College, Khammam",
        "Mandya Institute of Medical Sciences, Mandya",
        "Manipal Tata Medical College,Baridih Jameshedpur",
        "Mata Gujri Memorial Medical College, Kishanganj",
        "Maulana Azad Medical College, New Delhi",
        "Mayo Institute of Medical Sciences, Barabanki",
        "Medical College, Baroda",
        "Medical College, Thiruvananthapuram",
        "Mediciti Institute Of Medical Sciences, Ghanpur",
        "Meenakshi Medical College and Research Institute, Enathur",
        "Melmaruvathur Adiparasakthi Instt. Medical Sciences and Research",
        "M E S Medical College , Perintalmanna Malappuram Distt.Kerala",
        "M G M Medical College, Indore",
        "M G M Medical College, Jamshedpur",
        "Midnapore Medical College, Midnapore",
        "MKCG Medical College, Berhampur",
        "MNR Medical College & Hospital, Sangareddy",
        "Moti Lal Nehru Medical College, Allahabad",
        "Mount Zion Medical College, Chayalode, Ezhamkulam Adoor, Pathanamthitta",
        "MP Shah Medical College,Jamnagar",
        "M S Ramaiah Medical College, Bangalore",
        "Murshidabad Medical College & Hospitals, Murshidabad",
        "Muzaffarnagar Medical College, Muzaffarnagar",
        "MVJ Medical College and Research Hospital, Bangalore",
        "Mysore Medical College and Research Instt. (Prev.name Government Medical College), Mysore",
        "Nagaland Institute of Medical Sciences & Research",
        "Nagaon Medical College",
        "Nalanda Medical College, Patna",
        "Nalbari Medical College, Nalbari",
        "NAMO Medical Education and Research Institute, Silvassa",
        "Nandha Medical College & Hospital Erode",
        "Naraina Medical College & Research Centre",
        "Narayana Medical College, Nellore",
        "Narayan Medical College & Hospital, Sasaram",
        "Narendra Modi Medical College, Ahmedabad (Formely known as Ahmedabad Muncipal Coporation Medical Edu. Trust Medical College)",
        "National Capital Region Institute of Medical Sciences, Meerut",
        "National Institute of Health and Family Welfare, New Delhi",
        "National Institute of Medical Science & Research, Jaipur",
        "National Institute of Mental Health & Neuro Sciences, Bangalore",
        "National Institute of Virology, Pune",
        "Navodaya Medical College, Raichur",
        "N.C. Medical College & Hospital, Panipat",
        "Neelima Institute of Medical Sciences, Medchal",
        "Netaji Subhash Chandra Bose Medical College, Jabalpur",
        "Netaji Subhas Medical College & Hospital, Amhara, Bihta, Patna",
        "Netaji Subhas National Instt. Of Sports Medicine, Patiala",
        "Nilratan Sircar Medical College, Kolkata",
        "Nimra Institute of Medical Sciences, Krishna Dist., A.P.",
        "Nizams Institute of Medical Sciences, Hyderabad",
        "N. K. P. Salve Instt. of Medical Sciences and Research Centre and Lata Mangeshkar Hospital, Nagpur",
        "Noida International Institute Of Medical Sciences",
        "Nootan Medical College and Research Centre, Mehsana",
        "North Bengal Medical College, Darjeeling",
        "North Delhi Muncipal Corporation Medical College, Delhi",
        "North Eastern Indira Gandhi Regional Instt. of Health and Medical Sciences, Shillong",
        "Not Available",
        "Nova Institute of Medical Sciences and Research Centre",
        "NRI Institute of Medical Sciences, Visakhapatnam",
        "NRI Medical College, Guntur",
        "Osmania Medical College, Hyderabad",
        "Pacific Institute of Medical Sciences, Umarda, Udaipur",
        "Pacific Medical College & Hospital, Bhilo Ka Bedla, Udaipur",
        "Padmashree Dr. D.Y.Patil Medical College, Navi Mumbai",
        "Palakkad Institute of Medical Sciences, Palakkad Kerala",
        "Palamu Medical College, Palamu",
        "Pandit Deendayal Upadhyay Medical College, Rajkot",
        "Panimalar Medical College Hospital & Research Institute, Chennai,Tamil Nadu",
        "Parbhani Medical College, Parbhani",
        "Parul Institute of Medical Sciences & Research, Vadodara",
        "PA Sangma International Medical College & Hospital",
        "Patna Medical College, Patna",
        "Peoples College of Medical Sciences & Research Centre, Bhanpur, Bhopal",
        "P E S Institute Of Medical Sciences and Research, Kuppam",
        "PES University Institute of Medical Sciences and Reseach Bangalore",
        "Pimpri Chinchwad Municipal Corporation PG Institute, Pimpri",
        "P K Das Institute of Medical Sciences, Palakkad, Kerala",
        "Pondicherry Institute of Medical Sciences & Research, Pondicherry",
        "Post Graduate Institute of Child Health, Noida (Formely known as Super Speciality Paediatric Hospital & Post Graduate Teaching Institute)",
        "Post Graduate Institute of Medical Education and Research & Capital Hospital, Bhubaneswar",
        "Postgraduate Institute of Medical Education & Research, Chandigarh",
        "Post Graduate Institute of Medical Sciences, Navi Mumbai",
        "Postgraduate Institute of Swasthiyog Pratisthan, Miraj",
        "Prafulla Chandra Sen Government Medical College & Hospital",
        "Prakash Institute of Medical Sciences & Research, Sangli",
        "Pramukhswami Medical College, Karmsad",
        "Prasad Institute of Medical Sciences, Lucknow",
        "Prathima Institute Of Medical Sciences, Karimnagar",
        "Prathima Relief Institue of Medical Sciences",
        "PSG Institute of Medical Sciences, Coimbatore",
        "PSP Medical College Hospital and Research Institute",
        "Pt. B D Sharma Postgraduate Institute of Medical Sciences, Rohtak (Haryana)",
        "Pt. Jawahar Lal Nehru Government Medical College, Chamba",
        "Pt. J N M Medical College, Raipur",
        "Pt. Raghunath Murmu Medical College and Hospital, Baripada, Odisha",
        "Punjab Institute of Medical Sciences, Jalandhar",
        "Purulia Government Medical College & Hospital",
        "Pushpagiri Institute Of Medical Sciences and Research Centre, Tiruvalla",
        "Radha Devi Jageshwari Memorial Medical College and Hospital",
        "Raichur Institute of Medical Sciences,Raichur",
        "Raiganj Government Medical College & Hospital, Raiganj",
        "Raipur Institute of Medical Sciences (RIMS), Raipur",
        "Rajah Muthiah Medical College, Annamalainagar",
        "Rajan Babu T B Institute, Delhi",
        "Rajarajeswari Medical College & Hospital, Bangalore",
        "Rajashree Chatrapati Shahu Maharaj Government Medical College, Kolhapur",
        "Rajendra Institute of Medical Sciences, Ranchi",
        "Rajiv Gandhi Institute of Medical Sciences, adilabad",
        "Rajiv Gandhi Institute of Medical Sciences, Srikakulam",
        "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, Thane",
        "Rajkiya Allopathic Medical College, Bahraich, UP",
        "Rajkiya Medical College Jalaun, Orai, Uttar Pradesh",
        "Rajmata Shrimati Devendra kumari Singhdeo Government Medical College, Surguja ( C.G.)",
        "Rajshree Medical Research Institute, Bareilly",
        "Rama Medical College and Hospital , Kanpur",
        "Rama Medical College Hospital and Research Centre, Hapur",
        "Ram Krishna College Hospital & Research Centre, Bhopal",
        "Rampurhat Government Medical College & Hospital, Rampurhat",
        "Ranchi Instt. Of Neuro-Psychiatry and Allied Sciences,Kanke,Ranchi",
        "Rangaraya Medical College, Kakinada",
        "Regional Cancer Centre, Thiruvanthapuram",
        "Regional centre for Cancer Research and treatment,Adyar,Chennai",
        "Regional Institute of Medical Sciences, Imphal",
        "Regional Instt. of Opthalmology Previously Known asNehru Instt.of Opthalmo, Sitapur",
        "RG Kar Medical College, Kolkata",
        "RIMT Medical College and Hospital",
        "RKDF Medical College Hospital & Research Centre, Jatkhedi, Bhopal",
        "R N T Medical College, Udaipur",
        "Rohilkhand Medical College & Hospital, Bareilly",
        "RUHS College of Medical Sciences, Jaipur",
        "Rural Medical College, Loni",
        "Ruxmaniben Deepchand Gardi Medical College, Ujjain",
        "R.V.M. Institute of Medical Sciences and Research Centre, Siddipet",
        "Safdarjung Hospital, New Delhi",
        "Saheed Laxman Nayak Medical College & Hospital, Koraput",
        "Saheed Rendo Majhi Medical College & Hospital, Bhawanipatna, Kalahandi",
        "SAL Institute of Medical Sciences, Ahmedabad",
        "Sambharam Institute of Medical Sciences & Research, Kolar",
        "Sancheti Institute for Orthpaedics & Rehabilitation, Pune",
        "Sanjay Gandhi Institute of Trauma and Orthopaedics, Bangalore",
        "Sanjay Gandhi Postgraduate Institute of Medical Sciences, Lucknow",
        "Sanjeevan Medical Foundation ENT Post Graduate Training Instt., Miraj",
        "Sankara Netralaya Medical Research Foundation, Chennai",
        "Santhiram Medical College, Nandyal",
        "Santiniketan Medical College, Bolpur, West Bengal",
        "Santosh Medical College, Ghaziabad",
        "Sapthagiri Institute of Medical Sciences & Research Centre, Bangalore",
        "Saraswati Institute of Medical Sciences, Hapur",
        "Saraswati Medical College, Unnao, U.P.",
        "Sarat Chandra Chattopadhyay Government Medical College & Hospital",
        "Sardar Patel Medical College, Bikaner",
        "Saveetha Medical College and Hospital, Kanchipuram",
        "SBKS Medical Instt. & Research Centre, Vadodra",
        "SCB Medical College, Cuttack",
        "School of Medical Sciences & Research,Greater Noida",
        "School of Medical Sciences, Sri Satya Sai University of Technology and Medical Sciences",
        "School of Tropical Medicine, Kolkata",
        "SDM College of Medical Sciences & Hospital, Sattur, Dharwad",
        "SDS Tuberculosis Research Centre and Rajiv Gandhi Institute of Chest Diseases, Bangalore",
        "Seth GS Medical College, and KEM Hospital, Mumbai",
        "Shadan Institute of Medical Sciences,Research Centre and Teaching Hospital, Peerancheru",
        "Shahed Nirmal Mahto Medical College & Hospital, Dhanbad",
        "Shaheed Hasan Khan Mewati Government Medical College, Nalhar",
        "Shaikh-UL-Hind Maulana Mahmood Hasan Medical College, Saharanpur",
        "Shantabaa Medical College, Amreli",
        "Sher-I-Kashmir Instt. Of Medical Sciences, Srinagar",
        "Shija Academy of Health Sciences",
        "Shimoga Institute of Medical Sciences,Shimoga",
        "Shree Narayan Medical Institute and Hospital",
        "Shri Atal Bihari Vajpayee Government Medical College, Faridabad",
        "Shri Atal Bihari Vajpayee Medical College & Research Institute",
        "Shri Balaji Institute of Medical Science",
        "Shri B M Patil Medical College, Hospital & Research Centre, Vijayapura(Bijapur",
        "Shridevi Institute of Medical Sciences & Research Hospital, Tumkur",
        "Shri Gorakshnath Medical College Hospital & Research Centre",
        "Shri Guru Ram Rai Institute of Medical & Health Sciences, Dehradun",
        "Shri Kalyan Govt. Medical College, Sikar, Rajasthan",
        "Shri Krishna Medical College, Muzzafarpur",
        "Shri Lal Bahadur Shastri Government Medical College, Mandi, HP",
        "Shri Ramchandra Institute of Medical Sciences, Aurangabad",
        "Shri Ramkrishna Institute of Medical Sciences & Sanaka Hospitals, Durgapur",
        "Shri Ram Murti Smarak Institute of Medical Sciences, Bareilly",
        "Shri Rawatpura Sarkar Institute of Medical Sciences and Research",
        "Shri Sathya Sai Medical College and Research Institute, Kancheepuram",
        "Shri Shankaracharya Institute of Medical Sciences, Bhilai",
        "Shri Siddhi Vinayak Medical College & Hospital",
        "Shri Vasant Rao Naik Govt. Medical College, Yavatmal",
        "Shyam Shah Medical College, Rewa",
        "Siddaganga Medical College and Research Institute, Tumakuru",
        "Sikkim Manipal Institute of Medical Sciences, Gangtok",
        "Silchar Medical College, Silchar",
        "Sindhudurg Shikshan Prasarak Mandal (SSPM) Medical College & Lifetime Hospital, Padave, Sindhudurg",
        "SKS Hospital Medical College & Research Centre",
        "SMBT Institute of Medical Sciences & Research Centre, Nandihills, Nashik",
        "SMS Medical College, Jaipur",
        "Smt. G.R. Doshi and Smt. K.M. Mehta Institute of Kidney Diseases and Research Centre, Dr. H.L. Trivedi Institute of Transplantation Sciences, Ahmedabad, Gujarat",
        "Smt. Kashibai Navale Medical College and General Hospital,Pune",
        "Smt. N.H.L.Municipal Medical College, Ahmedabad",
        "S. Nijalingappa Medical College & HSK Hospital & Research Centre, Bagalkot",
        "S N Medical College, Agra",
        "Soban Singh Jeena Government Institute of Medical Science & Research, Almora",
        "Sree Balaji Medical College and Hospital, Chennai",
        "Sree Chitra Thirunal Institute for Medical Science and Technology, Thiruvananthapura",
        "Sree Gokulam Medical College Trust & Research Foundation, Trivandrum",
        "Sree Mookambika Institute of Medical Sciences, Kanyakumari",
        "Sree Narayana Instt. of Medical Sciences, Chalakka,Ernakulam",
        "Sree Uthradom Thiurnal Academy of Medical Sciences,Trivandrum",
        "Sri Aurobindo Medical College and Post Graduate Institute , Indore",
        "Sri Balaji Medical College, Hospital and Research Institute, Chittoor",
        "Sri Bhausaheb Hire Government Medical College, Dhule",
        "Sri Chamundeshwari Medical College Hospital & Research Institute",
        "Sri Devaraj URS Medical College, Kolar",
        "Sri Guru Ram Das Institute of Medical Sciences and Research, Sri Amritsar",
        "Sri Jagannath Medical College & Hospital, Puri",
        "Sri Jayadeva Institute of Cardiology, Bangalore",
        "Sri Lakshmi Narayana Institute of Medical Sciences, Pondicherry",
        "Sri Madhusudan Sai Institute of Medical Sciences and Research",
        "Sri Manakula Vinayagar Medical College & Hospital, Pondicherry",
        "Sri Muthukumaran Medical College,Chennai",
        "Srinivasan Medical College and Hospital",
        "Srinivas Institute of Medical Research Centre, Srinivasnagar, mangalore",
        "Sri Ramachandra Medical College & Research Institute, Chennai",
        "Sri siddhartha Institute of Medical Sciences & Research Centre, Bangalore",
        "Sri Siddhartha Medical College, Tumkur",
        "Sri Venkateswaraa Medical College Hospital and Research Institute, Chennai",
        "Sri Venkateswaraa Medical College, Hospital & Research Centre, Pondicherry",
        "Sri Venkateswara Institute of Medical Sciences (SVIMS) , Tirupati",
        "S.R. Medical College & Research Centre, Akathumjuri, Vennicode, Varkala, Thiruvananthapuram",
        "SRM Medical College Hospital & Research Centre, Chengalpattu",
        "SR Patil Medical College and Hospital",
        "SRTR Medical College, Ambajogai",
        "S S Institute of Medical Sciences& Research Centre, Davangere",
        "Stanley Medical College, Chennai",
        "St. Johns Medical College, Bangalore",
        "ST Peters Medical College, Hospital & Research Institute",
        "Subbaiah Institute of Medical Sciences, Shimoga, Karnataka",
        "Subharti Medical College, Meerut",
        "Sudha Medical College",
        "Sukh Sagar Medical College & Hospital, Jabalpur",
        "Sundarlal Patwa Govt. Medical College",
        "Surabhi Institute of Medical Sciences, Siddipet, Telangana",
        "Surat Municipal Institute of Medical Education & Research, Surat",
        "SVIMS - Sri Padmavathi Medical College for Women, Alipiri Road, Tirupati",
        "S V Medical College, Tirupati",
        "S V S Medical College, Mehboobnagar",
        "Swaminayaran Institute of Medical Sciences & Research",
        "Swamy Vivekanandha Medical College Hospital And Research Institute",
        "Symbiosis Medical College for Women, Pune",
        "Tagore Medical College and Hospital, Chennai",
        "Tamralipto Government Medical College & Hospital",
        "Tata Memorial centre, Mumbai",
        "T D Medical College, Alleppey (Allappuzha)",
        "Teerthanker Mahaveer Medical College, Moradabad",
        "Terna Medical College, Navi Mumbai",
        "Tezpur Medical College & Hospital, Tezpur",
        "Thanjavur Medical College,Thanjavur",
        "The Gandhigram Institute of Rural Health and Family Welfare Trust, Ambathurai",
        "Theni Government Medical College,Theni",
        "The Oxford Medical College, Hospital & Research Centre, Bangalore",
        "The Tamilnadu Dr. MGR Medical University,Chennai",
        "Thiruvarur Govt. Medical College, Thiruvarur",
        "Thoothukudi Medical College, Thoothukudi",
        "Tinsukia Medical College",
        "Tirunelveli Medical College,Tirunelveli",
        "Tomo Riba Institute of Health & Medical Sciences, Naharlagun",
        "Topiwala National Medical College, Mumbai",
        "Travancore Medical College, Kollam",
        "Trichy SRM Medical College Hospital & Research Centre, Trichy",
        "Tripura Medical College and Dr. B R A M Teaching Hospital, Agartala",
        "Tripura Santiniketan Medical College & Hospital",
        "TRR Institute of Medical Sciences, Patancheru",
        "T S Misra Medical College & Hospital, Amusi, Lucknow",
        "Uma Nath Singh Autonomous State Medical College Society Jaunpur",
        "United Institute of Medical Sciences, Allahabad",
        "University College of Medical Sciences & GTB Hospital, New Delhi",
        "University College of Medicine, Kolkata",
        "Uttar Pradesh University of Medical Sciences, (Prev. UP Rural Inst.of Med.Sc&R) Etawah",
        "Vardhman Mahavir Medical College & Safdarjung Hospital, Delhi",
        "Varun Arjun Medical College, Banthra, Shahjahanpur",
        "Vedantaa Institute of Medical Sciences, Palghar, Maharashtra",
        "Veer Chandra Singh Garhwali Govt. Medical Sc. & Research Instt, Srinagar, Pauri Garhwal",
        "Veer Surendra Sai Institute of Medical Sciences and Research, Burla",
        "Velammal Medical College Hospital and Research Institute, Madurai",
        "VELS Medical College & Hospital",
        "Venkateshwara Institute of Medical Sciences, Gajraula",
        "Vijaynagar Institute of Medical Sciences, Bellary",
        "Vinayaka Missions Kirupananda Variyar Medical College, Salem",
        "Vinayaka Missions Medical College, Karaikal, Pondicherry",
        "Virendra Kumar Sakhlecha Government Medical College",
        "Viswabharathi Medical College, Kurnool",
        "Vivekananda Institute of Medical Sciences, Kolkata",
        "VP Chest Institute of Medical Sciences, Delhi",
        "Vyas Medical College & Hospital",
        "Vydehi Institute Of Medical Sciences & Research Centre, Bangalore",
        "World College of Medical Sciences & Researc, Jhajjar, Haryana",
        "Yadgiri Institute of Medical Sciences, Yadgiri",
        "Yenepoya Medical College, Mangalore",
        "Zoram Medical College, Mizoram",
        "Zydus Medical College & Hospital, Dahod",
        "Anil Neerukonda Institute of Dental Sciences, Visakhapatnam",
        "C.K.S. Teja Institute of Dental Sciences & Research, Tirupati",
        "Care Dental College, Guntur",
        "Drs. Sudha & Nageswara Rao Siddhartha Institute of Dental Sciences, Gannavaram Mandalam",
        "G. Pulla Reddy Dental College & Hospital, Kurnool",
        "Gitam Dental College & Hospital, Vishakhapatnam",
        "Govt. Dental College & Hospital, Vijayawada",
        "Govt. Dental College, RIMS, Kadapa",
        "GSL Dental College & Hospital, Rajahmundry",
        "KIMS Dental College, Amalapuram",
        "Lenora Institute of Dental Sciences, Rajahmundry",
        "Narayana Dental College & Hospital, Nellore",
        "Nimra Institute of Dental Sciences",
        "Sibar Institute of Dental Sciences, Guntur",
        "Sree Sai Dental College & Research Institute, Srikakulam",
        "St. Joseph Dental College, Duggirala",
        "Vishnu Dental College, Bhimavaram",
        "Govt. Dental College, Dibrugarh, Assam",
        "Govt. Dental College, Silchar, Assam",
        "Regional Dental College, Guwahati",
        "Buddha Institute of Dental Sciences & Hospital, Kankarbagh, Patna",
        "Dr. B.R. Ambedkar Institute of Dental Sciences & Hospital, Patna",
        "Government Dental College & Hospital, Nalanda",
        "Mithila Minority Dental College & Hospital, Darbhanga",
        "Patna Dental College & Hospital, Patna",
        "Sarjug Dental College, Darbhanga",
        "Dr. Harvansh Singh Judge Institute of Dental Sciences & Hospital, Chandigarh",
        "Chattisgarh Dental College & Research Institute, Rajnandgaon",
        "Govt. Dental College, Raipur, Chhattisgarh",
        "Maitri College of Dentistry and Research Centre, Durg",
        "New Horizon Dental College & Research Instiute, Bilaspur",
        "Rungta College of Dental Sciences & Research, Bhilai",
        "Triveni Institute of Dental Sciences, Hosptial & Research Centre, Bilaspur",
        "Vaidik Dental College and Research Centre,",
        "Army Hospital (Research and Referral) Delhi Cantt, Delhi",
        "ESIC Dental College, Rohini, New Delhi",
        "Faculty of Dentistry, Jamia Millia Islamia, New Delhi",
        "Lady Hardinge Medical College & Smt. Sucheta Kriplani Hospital New Delhi",
        "Maulana Azad Dental College & Hospital, New Delhi",
        "University College of Medical Sciences, Dilshad Garden",
        "Goa Dental College & Hospital, Goa",
        "Ahmedabad Dental College & Hospital, Gandhinagar",
        "AMC Dental College, Ahmedabad",
        "College of Dental Sciences and Research Centre, Ahmedabad",
        "College of Dental Sciences, Amargadh",
        "Faculty of Dental Science, Dharmsinh Desai University, Nadiad",
        "Goenka Research Institute of Dental Sciences, Gandhinagar",
        "Govt. Dental College & Hospital, Ahmedabad",
        "Govt. Dental College Hospital, Jamnagar",
        "K.M. Shah Dental College & Hospital, Vadodara",
        "Karnavati School of Dentistry, Gandhinagar",
        "Manubhai Patel Dental College & Dental Hospital & SSR General Hospital, Vadodara",
        "Narsinhbhai Patel Dental College & Hospital, Visnagar",
        "Siddhpur Dental College, Patan",
        "D.A.V. Centenary Dental College, Yamuna nagar",
        "Jan Nayak Ch. Devi Lal Dental College, Sirsa",
        "Maharishi Markandeshwar College of Dental Sciences & Research, Mullana",
        "Manav Rachana Dental College, Faridabad",
        "PDM Dental College & Research Institute, Jhajjar",
        "Post Graduate Institute of Dental Sciences, Rohtak",
        "Sri Govind Tricentenary Dental College, Hospital & Research Institute",
        "Sudha Rustagi College of Dental Sciences & Research,Faridabad",
        "Swami Devi Dyal Hospital & Dental College, Panchkula",
        "Yamuna Institute of Dental Sciences & Research, Yamuna Nagar",
        "Bhojia Dental College & Hospital, Nalagarh",
        "H.P. Govt. Dental College & Hospital, Shimla",
        "Himachal Dental College, Sunder Nagar",
        "Himachal Institute of Dental Sciences, Poanta Sahib",
        "M.N.D.A.V. Dental College & Hospital, Solan",
        "Govt. Dental College, Srinagar",
        "Indira Gandhi Government Dental College, Jammu",
        "Institute of Dental Sciences, Sehora",
        "Awadh Dental College & Hospital, Jamshedpur",
        "Dental Institute Rajendra Institute of Medical Sciences, Ranchi",
        "Hazaribag College of Dental Sciences and Hospital, Hazaribag",
        "Vananchal Dental College & Hospital, Garhwa",
        "A.B. Shetty Memorial Institute of Dental Sciences, Mangalore",
        "A.J. Institute of Dental Sciences, Mangalore",
        "Air Force Institute of Dental Sciences, Bangalore",
        "Al Ameen Dental College & Hospital, Bijapur",
        "Al-Badar Rural Dental College & Hospital, Gulbarga",
        "AME’s Dental College & Hospital, Raichur",
        "Bangalore Institute of Dental Sciences & Hospital, Bangalore",
        "Bapuji Dental College & Hospital, Davangere",
        "College of Dental Sciences, Davangere",
        "Coorg Institute of Dental Sciences, Virajpat",
        "D.A. Pandu Memorial R.V. Dental College, Bangalore",
        "Dayanand Sagar College of Dental Sciences, Bangalore",
        "Dr. Syamala Reddy Dental College, Hospital & Research Centre, Bangalore",
        "ESIC Dental College, Gulbarga",
        "Farooqia Dental College & Hospital, Mysore",
        "Govt. Dental College & Research Institute, Bellary",
        "Govt. Dental College, Bangalore",
        "H.K.D.E.Ts Dental College, Hospital & Research Institute, Humnabad",
        "H.K.E. Society's, S. Nijalingappa Institute of Dental Sciences & Research",
        "JSS Dental College & Hospital, Mysore",
        "K.G.F. College of Dental Sciences & Hospital, KGF",
        "K.V.G. Dental College & Hospital, Sullia",
        "KLE Society’s Institute of Dental Sciences,Bangalore",
        "KLE Vishwanath Katti Institute of Dental Sciences, Belgaum",
        "Krishnadevaraya College of Dental Sciences & Hospital, Bangalore",
        "M.R. Ambedkar Dental College & Hospital, Bangalore",
        "M.S. Ramaiah Dental College, Bangalore",
        "Maaruti College of Dental Sciences & Research Centre, Bangalore",
        "Manipal College of Dental Sciences, Mangalore",
        "Manipal College of Dental Sciences, Manipal",
        "Maratha Mandal’s Dental College & Research Centre, Belgaum",
        "Navodaya Dental College, Raichur",
        "P.M.N.M. Dental College & Hospital, Bagalkot",
        "Rajarajeswari Dental College & Hospital, Bangalore",
        "S.B. Patil Dental College & Hospital, Bidar",
        "S.J.M. Dental College & Hospital, Chitradurga",
        "SDM College of Dental Sciences & Hospital, Dharwad",
        "Sharavathi Dental College & Hospital, Shimoga",
        "Sri Hasanamba Dental College & Hospital, Hassan",
        "Sri Rajiv Gandhi College of Dental Sciences & Hospital, Bangalore",
        "Sri Siddhartha Dental College, Tumkur",
        "Sri Venkateshwara Dental College & Hospital, Bangalore",
        "Srinivas Institute of Dental Sciences, Mangalore",
        "Subbaiah Institute of Dental Sciences, Purale, Shimoga",
        "The Oxford Dental College, Bangalore",
        "V.S. Dental College, Bangalore",
        "Vydehi Institute of Dental Sciences & Research, Bangalore",
        "Yenepoya Dental College & Hospital, Mangalore",
        "Al-Azhar Dental College, Kerala",
        "Amrita School of Dentistry, Kochi",
        "Annoor Dental College & Hospital, Ernakulam",
        "Azeezia College of Dental Sciences & Research, Kollam",
        "Century International Institute of Dental Science & Research Center, Kasaragod",
        "Educare Institute of Dental Sciences, Malappuram",
        "Govt. Dental College, Alappuzha",
        "Govt. Dental College, Kottayam",
        "Govt. Dental College, Kozhikode",
        "Govt. Dental College, Thrissur",
        "Govt. Dental College, Trivandrum",
        "Indira Gandhi Institute of Dental Sciences, Kothamangalam",
        "Kannur Dental College, kannur",
        "KMCT Dental College, Calicut",
        "Malabar Dental College & Research Centre, Malappuram",
        "Mar Baselios Dental College, Kothamangalam",
        "MES Dental College, Malappuram",
        "Noorul Islam College of Dental Sciences, Trivandrum",
        "Pariyaram Dental College, Academy of Medical Sciences, Kannur",
        "PMS College of Dental Science & Research, Thiruvananthpuram",
        "PSM College of Dental Sciences & Research, Trichur",
        "Pushpagiri College of Dental Sciences, Tiruvalla",
        "Royal Dental College, kerala",
        "Sree Anjaneya Medical Trust, Calicut",
        "Sri Sankara Dental College, Thiruvananthapuram",
        "St. Gregorios Dental College, Ernakulam",
        "Travancore Dental College",
        "Bhabha College of Dental Sciences, Bhopal",
        "College of Dental Sciences & Hospital, Indore",
        "College of Dentistry- Indore",
        "Guru Gobind Singh College of Dental Science & Research Centre, Burhanpur",
        "Hitkarini Dental College & Hospital, Jabalpur",
        "Index Institute of Dental Sciences, Indore",
        "Institute of Dental Education & Advance Studies (IDEAS), Gwalior",
        "Maharana Pratap College of Dentistry & Research Centre, Gwalior",
        "Mansarover Dental College, Bhopal",
        "Modern Dental College & Research Centre, Indore",
        "People’s Dental Academy, Bhopal",
        "Peoples College of Dental Sciences & Research Centre, Bhopal",
        "Rishiraj College of Dental Sciences & Research Centre, Bhopal",
        "RKDF Dental College & Research Centre, Bhopal",
        "Sri Aurobindo College of Dentistry, Indore",
        "Aditya Dental College, Beed",
        "Annasaheb Chudaman Patil Memorial Dental College, Dhule",
        "Armed Forces Medical College, Pune-1",
        "Bharati Vidyapeeth Dental College & Hospital, Navi Mumbai",
        "Bharati Vidyapeeth Dental College & Hospital, Pune",
        "Bharati Vidyapeeth Dental College & Hospital, Sangli",
        "Chatrapati Shahu Maharaj Shikshan Sanstha’s Dental College & Hospital, Aurangabad",
        "D.Y. Patil Dental School, Pune",
        "Dr. D.Y. Patil Dental College & Hospital, Pune",
        "Dr. Hedgewar Smruti Rugna Seva Mandals Dental College & Hospital, Hingoli",
        "Dr. Rajesh Ramdasji Kambe Dental College & Hospital, Akola",
        "Govt. Dental College & Hospital, Aurangabad",
        "Govt. Dental College & Hospital, Mumbai",
        "Govt. Dental College & Hospital, Nagpur",
        "Late Shri Yashwantrao Chavan Memorial Medical & Rural Development Foundation's Dental College & Hospital, Ahmednagar",
        "M.A. Rangoonwala College of Dental Sciences & Research Centre, Pune",
        "Maharashtra Institute of Dental Sciences & Research (Dental College)",
        "Mahatma Gandhi Missions Dental College & Hospital, Kamothe, Navi Mumbai",
        "Mahatma Gandhi Vidya Mandir’s Dental College & Hospital, Nashik",
        "Nair Hospital Dental College, Mumbai",
        "Nanded Rural Dental College & Research Center, Nanded",
        "Padmashree Dr. D.Y. Patil Dental College & Hospital, Navi Mumbai",
        "Pandit Dindayal Upadhyay Dental College, Solapur",
        "Rural Dental College, Loni",
        "S.M.B.T. Dental College & Hospital, Amrutnagar",
        "Saraswati Danwantri Dental College & Hospital, Parbhani",
        "School of Dental Sciences, Krishna Institute of Medical Sciences, Karad",
        "Sharad Pawar Dental College & Hospital, Wardha",
        "Sinhgad Dental College & Hospital, Pune",
        "SMBT Institute of Dental Sciences and Research, Nandihills, Nashik",
        "Swargiya Dadasaheb Kalmegh Smruti Dental College & Hospital, Nagpur",
        "Tatyasaheb Kore Dental College & Research Centre, New Pargaon",
        "Terna Dental College & Hospital, Navi Mumbai",
        "Vasantdada Patil Dental College and Hospital, Sangli",
        "Vidarbha Youth Welfare Society’s Dental College & Hospital, Amravati",
        "VSPM Dental College & Research Centre",
        "Yerala Medical Trust & Research Centre’s Dental College & Hospital, Navi Mumbai",
        "Yogita Dental College & Hospital, Ratnagiri",
        "Dental College, Jawaharlal Nehru Institute of Medical Sciences",
        "Dental College, RIMS, Imphal",
        "Hi-Tech Dental College & hospital, Bhubaneswar",
        "Institute of Dental Sciences, Bhubaneswar",
        "Kalinga Institute of Dental Sciences, Bhubaneswar",
        "S.C.B. Dental College & Hospital, Cuttack",
        "Indira Gandhi Institute of Dental Sciences, Pondicherry",
        "Mahatma Gandhi Post Graduate Institute of Dental Sciences, Puducherry",
        "Mahe Institute of Dental Sciences & Hospital, Mahe",
        "Sri Venkateshwaraa Dental College, Puducherry",
        "Adesh Institute of Dental Sciences & Research, Bathinda",
        "Baba Jaswant Singh Dental College Hospital & Research Institute, Ludhiana",
        "Christian Dental College, Ludhiana",
        "Dasmesh Institute of Research & Dental Sciences, Faridkot",
        "Desh Bhagat Dental College & Hospital, Mandi Gobindgarh",
        "Genesis Institute of Dental Sciences & Research, Ferozepur",
        "Gian Sagar Dental College & Hospital",
        "Govt. Dental College & Hospital, Patiala",
        "Guru Nanak Dev Dental College & Research Institute, Sunam",
        "Luxmi Bai Institute of Dental Sciences & Hospital, Patiala",
        "National Dental College & Hospital, Gulabgarh",
        "Pb. Govt. Dental College & Hospital, Amritsar",
        "Rayat Bahra Dental College, Mohali",
        "Shaheed Kartar Singh Sarabha Dental College & Hospital, Ludhiana",
        "Sri Guru Ram Das Institute of Dental Sciences & Research, Amritsar",
        "Sukhmani Dental College & Hospital, Derabassi",
        "Darshan Dental College & Hospital, Udaipur",
        "Daswani Dental College & Research Centre, Kota",
        "Eklavya Dental College & Hospital, Kotputli",
        "Geetanjali Dental & Research Institute, Udaipur",
        "Govt. Dental College & Hospital, Jaipur",
        "Jaipur Dental College, Jaipur",
        "Jodhpur Dental College General Hospital, Jodhpur",
        "Maharaja Ganga Singh Dental College & Research Centre, Sri Ganganagar",
        "Mahatma Gandhi Dental College & Hospital, Sitapura, Jaipur",
        "NIMS Dental College, Jaipur",
        "Pacific Dental College & Research Centre",
        "Pacific Dental College, udaipur",
        "R.R. Dental College & Hospital, Udaipur",
        "Rajasthan Dental College & Hospital, Jaipur",
        "Surendera Dental College & Research Institute, Sri Ganganagar",
        "Vyas Dental College & Hospital, Jodhpur",
        "Adhiparasakthi Dental College & Hospital, Melmaruvathur",
        "Asan Memorial Dental College & Hospital, Kancheepuram",
        "Best Dental Science College, Madurai",
        "Chettinad Dental College & Research Institute, Kancheepuram",
        "CSI College of Dental Sciences & Research, Madurai",
        "Dhanalakshmi Srinivasan Dental College",
        "Faculty of Dentistry, Rajah Muthiah Dental College & Hospital, Annamalai Nagar",
        "Government Dental College, Pudukkottai",
        "JKK Natrajah Dental College & Hospital, Komarapalayam",
        "K.S.R. Institute of Dental Science & Research, Tiruchengode",
        "Karpaga Vinayaga Institute of Dental Sciences, Kanchipuram",
        "Madha Dental College & Hospital, Kundrathur",
        "Meenakshi Ammal Dental College & Hospital, Chennai",
        "Nandha Dental College and Hospital",
        "Priyadarshini Dental College & Hospital, Pandur",
        "R.V.S. Dental College & Hospital, Sulur",
        "Ragas Dental College & Hospital, Chennai",
        "Rajas Dental College & Hospital, Kavalkinaru Jn",
        "S.R.M. Dental College, Ramapuram, Chennai",
        "Sathyabama University Dental College and Hospital, Chennai",
        "Saveetha Dental College & Hospital, Chennai",
        "Sree Balaji Dental College & Hospital, Narayanpuram",
        "Sree Mookambika Institute of Dental Sciences, Kulasekharam",
        "Sri Ramachandra Dental College & Hospital, Porur",
        "Sri Ramakrishna Dental College & Hospital, Coimbatore",
        "Sri Venkateswara Dental College & Hospital, Kancheepuram",
        "SRM Kattankulathur Dental College & Hospital, Kanchipuram",
        "Tagore Dental College & Hospital, Chennai",
        "Tamil Nadu Government Dental College & Hospital, Chennai",
        "Thai Moogambigai Dental College & Hospital, Chennai",
        "Vinayaka Mission’s Sankarachariyar Dental College, Salem",
        "Vivekanandha Dental College for Women, Elayampalayam",
        "Army College of Dental Sciences, Secunderabad",
        "Government Dental College and Hospital, Hyderabad",
        "Kamineni Institute of Dental Sciences, Nalgonda",
        "Malla Reddy Dental College for Women, Hyderabad",
        "Malla Reddy Institute of Dental Sciences,Hyderabad",
        "Mamata Dental College, Khamam",
        "Mamata Institute of Dental Sciences",
        "Meghna Institute of Dental Sciences, Nizamabad",
        "MNR DENTAL COLLEGE & HOSPITAL , MEDAK",
        "Panineeya Mahavidyalaya Institute of Dental Sciences & Research Centre, Hyderabad",
        "Sri Balaji Dental College, Hyderabad",
        "Sri Sai College of Dental Surgery, Vikarabad",
        "Sri Venkata Sai Institute of Dental Sciences, Hyderabad",
        "Tirumala Institute of Dental Sciences & Research Centre",
        "Agartala Government Dental College and IGM Hospital",
        "Babu Banarasi Das College of Dental Sciences, Lucknow",
        "Career Institute of Dental Sciences & Hospital, Lucknow",
        "Chandra Dental College & Hospital, safedabad",
        "D.J. College of Dental Sciences & Research, Modi Nagar",
        "Dental College Azamgarh",
        "Dr. Ziauddin Ahmad Dental College, Aligarh",
        "Faculty of Dental Sciences, Banaras Hindu University, Varanasi",
        "Faculty of Dental Sciences, Lucknow",
        "I.T.S. Centre for Dental Studies & Research, Ghaziabad",
        "I.T.S. Dental College, Hospital & Research Centre, Gr. Noida",
        "Inderprastha Dental College & Hospital, Ghaziabad",
        "Institute of Dental Sciences, Bareilly",
        "Institute of Dental Studies & Technology, Modinagar",
        "K.D. Dental College, Mathura",
        "Kalka Dental College & Hospital, Meerut",
        "Kothiwal Dental College & Research Centre, Moradabad",
        "Maharana Pratap Dental College & Hospital, Kanpur",
        "Purvanchal Institute of Dental Sciences, Gorakhpur",
        "Rama Dental College, Hospital & Research Centre, Kanpur",
        "Santosh Dental College & Hospital, Ghaziabad",
        "Saraswati Dental College, Lucknow",
        "Sardar Patel Post Graduate Institute of Dental & Medical Sciences, Lucknow",
        "School of Dental Sciences, Greater Noida",
        "Shree Bankey Bihari Dental College & Research Centre, Masuri",
        "Subharati Dental College, Meerut",
        "Teerthanker Mahaveer Dental College & Research Centre, Moradabad",
        "U.P. Rural Institute of Medical Sciences & Research, Saifai",
        "Narayan Swami Hospital and Dental College",
        "Seema Dental College & Hospital, Rishikesh",
        "Uttaranchal Dental College & Medical Research Institute, Dehradun",
        "Burdwan Dental College, Rajbati",
        "Dr. R. Ahmed Dental College & Hospital, Calcutta",
        "Gurunanak Institute of Dental Science & Research, Kolkatta",
        "Haldia Institute of Dental Sciences and Research, Banbishnupur",
        "Kusum Devi Sunderlal Dugar Jain Dental College & Hospital, Kolkata",
        "The North Bengal Dental College, Sushratangar",
    ],
    courseList: [
        // "ALL DIPLOMA",
        // "ALL MD",
        // "ALL MS",
        // "ALL DM",
        // "ALL MCH",
        // "ALL EXCEPT MBBS",
        // "ALL MD/MS/DIPLOMA",
        // "ALL DM/MCH",
        "M.B.B.S.",
        //"All PG Courses",
        "Dip. in Path.& Bact.",
        "Diploma - Aviation Medicine",
        "Diploma - Diploma in Pathology & Bacteriology",
        "Diploma in Anaesthesia",
        "Diploma in Anaesthesiology",
        "Diploma in Basic Medical Sciences (Anatomy)",
        "Diploma in Basic Medical Sciences (Pharmacology)",
        "Diploma in Basic Medical Sciences (Physiology)",
        "Diploma in Cardiology",
        "Diploma in Child Health",
        "Diploma in Clinical Pathology",
        "Diploma in Community Medicine",
        "Diploma in Dermatology",
        "Diploma in Dermatology Venereology and Leprosy",
        "Diploma in Diabetology",
        "Diploma in Emergency Medicine",
        "Diploma in Family Planning",
        "Diploma in Forensic Medicine",
        "Diploma in General Medicine",
        "Diploma in General Surgery",
        "Diploma in Gynaecological Endoscopy",
        "Diploma in Health Administration",
        "Diploma in Health Education",
        "Diploma in Hemato-Oncology",
        "Diploma in Hospital Administration",
        "Diploma in Immuno-Haematology and Blood Transfusion",
        "Diploma in Industrial Health",
        "Diploma in Industrial Hygiene",
        "Diploma in Infertility & Assisted Reproductive Technique",
        "Diploma in Intensive Care",
        "Diploma in Learning Disablity & Neuro Development Paediatrics",
        "Diploma in Leprosy",
        "Diploma in Maternity & Child Welfare",
        "Diploma in Medical Oncology",
        "Diploma in Medical Radiology and Electrology",
        "Diploma in Medical Radiology and Electrology / Diploma in Medical Radio Diagnosis",
        "Diploma in Medical Virology",
        "Diploma in Microbiology",
        "Diploma in Minimal Access Surgery",
        "Diploma in Neonatology",
        "Diploma in Nephrology",
        "Diploma in Neurology",
        "Diploma in Obstetrics & Gynaecology",
        "Diploma in Ophthalmic Medicine and Surgery",
        "Diploma in Ophthalmology",
        "Diploma in Orthopaedics",
        "Diploma in Otorhinolaryngology",
        "Diploma in Oto-Rhino-Laryngology",
        "Diploma in Paediatric Cardiology",
        "Diploma in Paediatric Gastoenterology Hepatology & Neutrition",
        "Diploma in Paediatric Intensive Care",
        "Diploma in Paediatric Nephrology",
        "Diploma in Paediatric Neurology",
        "Diploma in Paediatric Orthopaedics",
        "Diploma in Paediatric Urology",
        "Diploma in Physical Medicine & Rehabilitation",
        "Diploma in Psychological Medicine",
        "Diploma in Public Health",
        "Diploma in Radiation Medicine",
        "Diploma in Radio-Diagnosis",
        "Diploma in Radio Therapy",
        "Diploma in Sports Medicine",
        "Diploma in Transfusion Medicine",
        "Diploma in Tropical Medicine Health",
        "Diploma in Tropical Medicine & Hygiene",
        "Diploma in Tuberculosis & Chest Diseases",
        "Diploma in Venereology",
        "Diploma (Marine Medicine)",
        "Diploma- Urology",
        "Diplom -Diploma V & D",
        "DM - Addiction Psychiatry",
        "DM - Cardiac-Anaesthesia",
        "DM - Cardiac Anaesthesia and Critical Care",
        "DM - Cardiac Intensive Care",
        "DM - Cardiac Radiology",
        "DM Cardiac surgical Intensive care",
        "DM - Cardiology",
        "DM - Cardiovascular Imaging and Vascular Interventional Radiology",
        "DM - Cardiovascular Radiology and Endovasular Intervention",
        "DM - Child & Adolescent Psychiatry",
        "DM - Clinical Haematology",
        "DM - Clinical Immunology",
        "DM- Clinical Immunology & Rheumatology",
        "DM - Clinical Immunology & Rheumatology (Internal Medicine)",
        "DM - Clinical Immunology & Rheumatology (Paediatrics)",
        "DM( Clinical Infectious Diseases)",
        "DM - Clinical Pharmacology",
        "DM - Critical Care Medicine",
        "DM - Endocrinology",
        "DM- Endocrinology & Metabolism",
        "DM - Geriatric Mental Health",
        "DM - Geriatric Psychiatry",
        "DM - Haematology Pathology/Hematopathology",
        "DM - Hepatology",
        "DM - High Altitude Medicine",
        "DM - Histopathology",
        "DM - Immunology",
        "DM - Infectious Disease",
        "DM - Infectious Diseases",
        "DM - Infectious Diseases (Direct 6 years)",
        "DM - Intensive Care",
        "DM - Interventional Radiology",
        "DM( Medical and Forensic Toxicology)",
        "DM - Medical Gastroenterology",
        "DM- Medical Gastroentrology",
        "DM - Medical Genetics",
        "DM-Medical Oncology",
        "DM - Neonatology",
        "DM - Nephrology",
        "DM - Neuro Anaesthesia",
        "DM- Neuro - Anesthesiology",
        "DM- Neuro - Anesthesiology & Critical Care",
        "DM - Neuroimaging & Interventional Neuroradiology",
        "DM - Neuroimaging & Interventional Radiology",
        "DM Neurology",
        "DM - Neurology",
        "DM - Neuropathology",
        "DM - Neuro Radiology",
        "DM - Onco-Anesthesia",
        "DM- Onco - Anesthesiology & Palliative Medicine",
        "DM - Oncology",
        "DM - Onco Pathology",
        "DM - Organ Transplant Anaesthesia & Critical Care",
        "DM - Paediatic Nephrology",
        "DM - Paediatic Rehabilitation",
        "DM - Paediatric Anaesthesia",
        "DM - Paediatric and Neonatal Anaesthesia",
        "DM - Paediatric Critical Care",
        "DM- Paediatric Emergency Medicine",
        "DM - Paediatric Endocrinology",
        "DM - Paediatric Haematology Oncology",
        "DM - Paediatric Hepatology",
        "DM - Paediatric Nephrology",
        "DM - Paediatric Neurology",
        "DM - Paediatric Oncology",
        "DM - Paediatric Pulmonology",
        "DM - Paediatric Pulmo-oncology & Intensive Care",
        "DM - Pain Medicine",
        "DM - Palliative Oncology",
        "DM - Pediatrics Cardiology",
        "DM - Pediatrics Gastroenterology",
        "DM - Preventive Oncology",
        "DM - Pul. Med. & Critical Care Med.",
        "DM- Pulmonary Critical Care & Sleep Medicine",
        "DM - Pulmonary Medicine",
        "DM- Pulmonary Medicine & Critical Care",
        "DM - Radiation Oncology (Head & Neck and Neuro)",
        "DM - Radiation Oncology (Urogenital & GYN)",
        "DM- Radiotherapy & Oncology ( 6 years )",
        "D.M.-Reproductive Medicine/M.Ch.-Reproductive Medicine and Surgery",
        "DM - Rheumatology/Clinical Immunology & Rheumatology",
        "DM - Therapeutic Nuclear Medicine",
        "DM - Theraputic Medicine",
        "DM - Trauma Anesthesia & Acute Care",
        "DM - Virology",
        "DM - Vitreo-retinal Surgery",
        "F.C.P.S.(Dermatology Venereology & Leprosy)",
        "F.C.P.S.(Medicine)",
        "F.C.P.S.(Mid. & Gynae)",
        "F.C.P.S.(Ophthalmology)",
        "F.C.P.S.(Pathology)",
        "F.C.P.S.(Surgery)",
        "Fellowship - Geriatic Mental Health Care",
        "Fellowship in Cardiac & Neuro Anesthesiolgy",
        "Fellowship in Critical Care",
        "Fellowship in Diabetology",
        "Fellowship in Medical Oncology",
        "Fellowship in Paediatric Clinical & Emergency Care",
        "Fellowship in Tropical Parasitology",
        "Fellowship - Mental Health Education",
        "Fellowship - Mental Health/Psychiatric Rehabilitation",
        "Fellowship - Skull Base Surgery Otorhinolaryngology",
        "Master Degree in Applied Epidemiology",
        "Master of Hospital Administration",
        "Master of Public Health",
        "Master of Public Health (Epidemiology)",
        "M. Ch Breast Endocrine and General Surgery",
        "M.Ch. - Breast Surgery",
        "M.Ch.(Burns & Plastic Surgery)",
        "M.Ch - Cardio Thoracic and Vascular Surgery",
        "M.Ch Cardiothoracic Surgery",
        "M.Ch Cardiothoracic & Thoracic Surgery",
        "M.Ch- Cardio- Vascular Surgery",
        "M.Ch. - Cosmetic Gynecology",
        "M.Ch. - Cranio Maxillofacial Surgery",
        "M.Ch - Endocrine Surgery",
        "M.Ch - Gynaecological Oncology",
        "M.Ch Gynaecologic Oncology",
        "M.Ch. - Hand Surgery",
        "M.Ch. - Head and Neck Surgery",
        "M.Ch Head - Neck Surgery and Oncology",
        "M.Ch - Hepato Pancreato Biliary Surgery",
        "M.Ch. - Joint Replacement & Reconstruction",
        "M.Ch. - Minimal Access Surgery and General Surgery",
        "M.Ch - Neuro Surgery",
        "M.Ch - Neuro Surgery 5 Year Course",
        "M.Ch - Neuro Surgery ( After M.S )",
        "M.Ch - Neuro Surgery (Direct 6 years)",
        "M.Ch - Paediatric Orthopaedics",
        "M.Ch - Paediatric Surgery",
        "M.Ch.- Paediatric Surgery - 3 yrs",
        "M.Ch - Paediatric Surgery (6 years Post MBBS)",
        "M.Ch.- Paediatric Surgery - 6 yrs",
        "M.Ch - Pediatric Cardio-Thoracic Vascular Surgery",
        "M. Ch - Pediatrics Surgery",
        "M.Ch - Pediatric Surgery",
        "M. Ch - Plastic & Reconstructive Surgery",
        "M.Ch - Plastic Surgery/Plastic & Reconstructive Surgery",
        "M. Ch- Reproductive Medicine & surgery",
        "M.Ch. - Spine Surgery",
        "M.Ch. - Sports Injury",
        "M.Ch.- Surgical Gastroenterology",
        "M.Ch - Surgical Gastroenterology/G.I. Surgery",
        "M.Ch - Surgical Oncology",
        "M.Ch - Thoracic Surgery/Cardio Thoracic Surgery/Cardio Vascular and thoracic Surgery",
        "M.Ch. - Trauma Surgery and Critical care",
        "M.Ch - Urology",
        "M.Ch - Urology/Genito-Urinary Surgery",
        "M.Ch - Vascular Surgery",
        "M C P S",
        "MD- Anaesthesia",
        "MD - Anaesthesiology",
        "MD- Anaesthesiology & Critical Care Med.",
        "MD - Anatomy",
        "MD - Aviation Medicine/Aerospace Medicine",
        "MD - Bacteriology",
        "MD- Biochemistry",
        "MD - Bio-Chemistry",
        "MD - Bio-Physics",
        "MD - CCM",
        "MD- Community & Family Medicine",
        "MD - Community Health Administration",
        "MD - Community Medicine",
        "MD - Dermatology",
        "MD - Dermatology Venereology & Leprosy",
        "MD - Emergency Medicine",
        "MD - Family Medicine",
        "MD - Forensic Medicine",
        "MD - Forensic Medicine/Forensic Medicine & Toxicology",
        "MD - General Medicine",
        "MD - Geriatrics",
        "MD - Hospital Administration",
        "MD - Immuno Haematology & Blood Transfusion",
        "MD Immunology Hematology and Blood Transfusion",
        "MD - Lab Medicine",
        "MD - Marine Medicine",
        "MD - Maternity & Child Health",
        "MD - Microbiology",
        "MD/MS - Anatomy",
        "MD/MS - Ophthalmology",
        "MD - Nuclear Medicine",
        "MD - Obstetrics & Gynaecology",
        "MD - Ophthalmology",
        "MD - Paediatrics",
        "MD - Palliative Medicine",
        "MD - Pathology",
        "MD- Pathology & Lab Medicine",
        "MD - Pathology & Microbiology",
        "MD - Pharmacology",
        "MD - Physical Medicine & Rehabilitation",
        "MD Physical Medicine Rehabilitation",
        "MD - Physiology",
        "MD - Psychiatry",
        "MD - Pulmonary Medicine",
        "MD - Radiation Oncology",
        "MD - Radio Diagnosis",
        "MD- Radio diagnosis and Interventional Radiology",
        "MD - Radio Diagnosis/Radiology",
        "MD - Radiology",
        "MD - Radiotherapy/ Radiation Oncology",
        "MD - Respiratory Medicine",
        "MD - Social & Preventive Medicine / Community Medicine",
        "MD - Sports Medicine",
        "MD - Transfusion Medicine",
        "MD- Transfusion Medicine and Blood Bank",
        "MD - Tropical Medicine",
        "MD - Tuberculosis & Respiratory Diseases / Pulmonary Medicine",
        "MD - Venereology",
        "M.Phil - Biophysics",
        "M.Phil - Neurophysiology",
        "M.Phil - Neurosciences",
        "MS - Anatomy",
        "M.Sc - Anatomy",
        "M.Sc - Medical Anatomy",
        "M.Sc - Medical Bacteriology",
        "M.Sc - Medical Bio-chemistry",
        "M.Sc - Medical Pathology",
        "M.Sc - Medical Pharmocology",
        "M.Sc - Medical Physics",
        "M.Sc - MLT (Cyto Pathology)",
        "M.Sc - MLT (Microbiology)",
        "M.Sc - Pathology",
        "M.Sc - Physiology",
        "MS - ENT",
        "MS - General Surgery",
        "MS - Neuro Surgery",
        "MS - Obstetrics and Gynaecology",
        "MS - Obstetrics & Gynaecology",
        "MS - Ophthalmology",
        "MS - Orthopaedics",
        "MS - Otorhinolaryngology",
        "MS - Traumatology and Surgery",
        "PDCC - Renal Pathology",
        "PDF - Acute Care & Emergency Psychiatry",
        "PDF - Addiction Medicine",
        "PDF - Apheresis",
        "PDF - Cardiac Electrophysiology and Pacing",
        "PDF - Certificate Course in Advance Imaging",
        "PDF - Child & Adolescent Psychiatry",
        "PDF - Clinical Neuroscience & Therapeutics in Schizophrenia",
        "PDF - Community Mental Health",
        "PDF - Consultation Liaison Psychiatry",
        "PDF - Critical care (Anesthesiology)",
        "PDF - Epilepsy",
        "PDF - Family Planning",
        "PDF - Forensic Psychiatry",
        "PDF - Geriatric Psychiatry",
        "PDF - Hemato Oncology",
        "PDF - Hemato Pathology",
        "PDF - Hepato Pancreatic Biliary Surgery",
        "PDF - Hospital Infection Control",
        "PDF - Infectious Diseases",
        "PDF - Lasers and Dermatosurgery",
        "PDF - Movement Disorder",
        "PDF - Nephro Pathology",
        "PDF - Neuroanaesthesia",
        "PDF - Neuro Critical Care",
        "PDF - Neuroinfection",
        "PDF - Neurological Rehabilitation",
        "PDF - Neuromuscular Disorder",
        "PDF - Neuropathology",
        "PDF - Non invasive Brain Stimulation of Psychiatric Disorder",
        "PDF - Obsessive Compulsive Disorder & Related disorders",
        "PDF - Obstetric Medicine",
        "PDF - Paediatric Dermatology",
        "PDF - Paediatric Neurology",
        "PDF - Paediatric Oncology",
        "PDF - Stroke",
        "PDF - Transfusion Medicine",
        "PDF - Womens Mental Health",
        "Ph. D - Anaesthesia",
        "Ph. D - Anatomy",
        "Ph. D - Bio- Chemistry",
        "Ph. D - Bio-Statistics",
        "Ph. D - Bio-Technology",
        "Ph. D - Cardiology",
        "Ph. D - Cardio Thoracic & Vascular Surgery",
        "Ph. D - Child & Adolescent Psychiatry",
        "Ph. D - Clinical Immunology",
        "Ph. D - Clinical Neuroscience",
        "Ph. D - Community Medicine",
        "Ph. D - Dermatology & Venereology",
        "Ph. D - Endocrinology & Metabolism",
        "Ph. D - ENT",
        "Ph. D - Forensic Medicine",
        "Ph. D - Gastro & Human Nutrition Unit",
        "Ph. D - Gastrointestinal Surgery",
        "Ph. D - Haematology",
        "Ph. D - Histo Compatibility & Immunogenetics",
        "Ph. D - History of Psychiatry",
        "Ph. D - Hospital Administration",
        "Ph. D - Lab Medicine",
        "Ph. D - Medical Oncology",
        "Ph. D - Medical Physics",
        "Ph. D - Medicine",
        "Ph. D - Mental Health Rehabilitation",
        "Ph. D - Microbiology",
        "Ph. D - Nephrology",
        "Ph. D - Neuroimaging & Interventional Radiology",
        "Ph. D - Neurology",
        "Ph. D - Neuro Magnetic Resonance",
        "Ph. D - Neuropathology",
        "Ph. D - Neurophysiology",
        "Ph. D - Neuro Surgery",
        "Ph. D - Nuclear Medicine",
        "Ph. D - Obst. & Gynae",
        "Ph. D - Ocular Bio Chemistry",
        "Ph. D - Ocular Microbiology",
        "Ph. D - Ocular Phramacology",
        "Ph. D - Orthopaedics",
        "Ph. D - Paediatric",
        "Ph. D - Paediatric Surgery",
        "Ph. D - Pathology",
        "Ph. D - Pharmacology",
        "Ph. D - Physical Medicine & Rehabilitation",
        "Ph. D - Physiology",
        "Ph. D - Psychiatry",
        "Ph. D - Radio Diagnosis",
        "Ph. D - Radiotherapy",
        "Ph. D - Surgery",
        "Ph. D - Urology",
        "MDS in Conservative Dentistry and Endodontics",
        "MDS in Oral and Maxillofacial Surgery",
        "MDS Oral Medicine and Radiology",
        "MDS in Oral Pathology and Microbiology",
        "MDS Orthodontics and Dentofacial Orthopaedics",
        "MDS in Pediatric and Preventive Dentistry",
        "MDS Periodontology",
        "MDS in Prosthodontics and Crown & Bridge",
        "MDS Public Health Dentistry",
        "PG Diploma	Orthodonitics & Dentofacial Orthopedics",
        "PG Diploma	Conservative Dentistry & Endodontics",
        "PG Diploma	Prosthodontics and Crown & Bridge",
        "PG Diploma	Pediatric and Preventive Dentistry",
        "Diploma	Dental Mechanics",
        "Postdoctoral Fellowship in CNS Demyelinating Disorders",
        "Postdoctoral Fellowship in Cognitive Neurosciences",
        "Postdoctoral Fellowship in Diagnostic Neuroimaging",
        "Postdoctoral Fellowship in Functional Neuroimaging",
        "Postdoctoral Fellowship in Integrative Mental Health and Neurosciences",
        "Postdoctoral Fellowship in Integrative Psychiatry",
        "Postdoctoral Fellowship in Neuropathy",
        "Postdoctoral Fellowship in Neuropsychiatry",
        "Postdoctoral Fellowship in Psychiatric Rehabilitation",
        "Postdoctoral Fellowship in Tele- Psychiatry",
        "Postgraduate Diploma in Genetic Counselling",
        "Radio-therapy",
        "UnSpecified",
    ],
    easeLevels: ["Almost impossible", "Very difficult", "Moderately difficult", "Not at all difficult", "Super chill"],
    ratingFields: ["Work-Life Balance", "Hands-on Experience", "Work Culture in Your Department", "Infrastructure", "Research Opportunities", "Faculty", "Security", "Overall Rating"],
    toxicityLevels: ["Highly toxic", "Moderately toxic", "Slightly toxic", "Not at all toxic", "Super chill"],
    patientLoadOption: [
        {
            label: "Weekly New Admissions",
            name: "weeklyNewAdmissions",
            options: ["0-10", "10-20", "20-30", "30-40", "40+"],
        },
        {
            label: "Weekly OPD Load",
            name: "weeklyOpdLoad",
            options: ["0-200", "200-400", "400-600", "600-800", "1000+"],
        },
    ],
    sampleFormData: {
        user: "64f45dc19a134f001f4d1e72",
        organizationName: "ABC",
        ratings: [
            { criteria: "Work-Life Balance", stars: 4 },
            { criteria: "Infrastructure", stars: 5 },
            { criteria: "Research Opportunities", stars: 3 },
        ],
        toxicity: {
            level: "Slightly toxic",
            specificIncident: "Occasional arguments in the department.",
        },
        leaves: {
            ease: "Moderately difficult",
            additionalComments: "Leave approval process could be streamlined.",
        },
        hostel: {
            available: true,
            sufficient: false,
        },
        bond: {
            isApplicable: true,
            durationYears: "2 years",
            amount: "500,000",
        },
        workingHours: {
            weeklyHours: 60,
            maxDutyStretchHours: 36,
        },
        passingRate: 85,
        improvementSuggestions: "Better work-life balance and flexible scheduling.",
        positiveAspects: "Supportive faculty and good hands-on experience.",
    },
    validateSchema: {
        organizationName: { required: true, message: "Organization Name is required." },
        //  designation: { required: true, message: "Designation is required." },
        course: { required: true, message: "Course is required." },
        ratings: [
            { criteria: "Work-Life Balance", required: true, message: "Rating for Work-Life Balance is required." },
            { criteria: "Hands-on Experience", required: true, message: "Rating for Hands-on Experience is required." },
            { criteria: "Work Culture in Your Department", required: true, message: "Rating for Work Culture in Your Department is required." },
            { criteria: "Infrastructure", required: true, message: "Rating for Infrastructure is required." },
            { criteria: "Research Opportunities", required: true, message: "Rating for Research Opportunities is required." },
            { criteria: "Faculty", required: true, message: "Rating for Faculty is required." },
            { criteria: "Security", required: true, message: "Rating for Security is required." },
            { criteria: "Overall Rating", required: true, message: "Rating for Overall Rating is required." },
        ],
        patientLoad: {
            weeklyNewAdmissions: { required: true, message: "Weekly New Admissions is required." },
            weeklyOpdLoad: { required: true, message: "Weekly OPD Load is required." },
        },
        toxicity: {
            level: { required: true, message: "Toxicity Level is required." },
            // specificIncident: { required: true, message: "Specific Incident is required." },
        },
        leaves: {
            ease: { required: true, message: "Ease of taking leaves is required." },
        },
        hostel: {
            available: { required: true, message: "Hostel availability is required." },
            //  sufficient: { required: (formValues) => formValues.hostel?.available, message: "Hostel sufficiency is required." },
        },
        bond: {
            isApplicable: { required: true, message: "Bond applicability is required." },
            durationYears: { required: (formValues) => formValues.bond?.isApplicable, message: "Bond duration is required." },
            amount: { required: (formValues) => formValues.bond?.isApplicable, message: "Bond amount is required." },
        },
    },
};

export default constants;
