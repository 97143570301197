import { createContext, useContext, useState } from "react";
import { axiosInstance } from "./Interceptors";
import { toast } from "react-toastify";

const ReviewsJobContext = createContext();

export function useReviewsJobContext() {
    return useContext(ReviewsJobContext);
}

export function ReviewsJobProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const notifyError = (message) => toast.error(message);
    const postReviewsInfo = async (url, data, refreshFunction) => {
        setLoading(true);
        await axiosInstance
            .post(url, data)
            .then(() => {
                setSuccess("Review submitted successfully");
                refreshFunction && refreshFunction();
            })
            .catch((error) => {
                console.log(error);
                notifyError(error.response.data.data.error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return <ReviewsJobContext.Provider value={{ postReviewsInfo, loading, success }}>{children}</ReviewsJobContext.Provider>;
}
