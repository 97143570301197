import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { useLocation, useParams } from "react-router";
import { usePrivateJobContext } from "../../../../Api/privatejobPosting";
import JobContainer from "../JobContainer";
import { useSelector } from "react-redux";
import { WhatsappShareButton, WhatsappIcon, LinkedinShareButton, LinkedinIcon, TelegramShareButton, TelegramIcon } from "react-share";
import InputField from "../../../../Components/Inputs/CustomSelect";
import FileInput from "../../../../Components/Inputs/FileInput";
import PDFViewer from "../../../../Components/PdfViewer";
import AlertBox from "../../../../Components/AlertBox";
import TextWithLinks from "../../../../Components/TextWithLinks";
import Disclaimer from "../Disclaimer";
import { Helmet } from "react-helmet";
import { ROLES_LIST } from "../../../../Utils/Constants";
import DATA from "../../Data";
import Utils from "../../Utils";
import { ICONS } from "../../Utils/icons";

const JobDetails = ({ isVerifiedRole }) => {
    const { tab } = useParams();
    const { privateJobList, getPrivateJobInfo, privateJobInfo, getPrivateJobList, patchPrivateJobInfo, deletePrivateJobInfo } = usePrivateJobContext();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tabs = queryParams.get("tab") || 1;
    const [isEditing, setIsEditing] = useState(false);
    const [editedJobInfo, setEditedJobInfo] = useState(privateJobInfo);
    const [addSection, setAddSection] = useState({
        index: "",
        type: "",
    });
    const { user } = useSelector((state) => state.auth);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const jobURL = window.location.href;

    const shareMessage = `
*Position Available:* ${privateJobInfo.jobTitle}
*Location:* ${privateJobInfo.jobCity + " " + privateJobInfo.jobState || "Not Available"}
*Vacancies:* ${privateJobInfo.numberOfVacancies || "Not Disclosed"}
*Salary:* ${privateJobInfo.salary || "Not Disclosed"}
*Posted Date:* ${Utils.formatDate(privateJobInfo.postedDate || "2024-07-13T13:47:14.405Z")}
    
🔗 Official Notification: ${jobURL?.split("?")[0]}
    
Stay Updated on the Latest Opportunities:
👉🏻 *For the latest government jobs (Visit Now):* https://medmanch.com
👉🏻 *Join our Nurse Job Telegram Group:* https://t.me/nursejobsmedmanch
👉🏻 *Follow our Nurse Job WhatsApp Group:* https://whatsapp.com/channel/0029VajmlTcAO7RGydRL5C1N


    `;

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        const { jobTitle, companyName, field, responsibilities, numberOfVacancy, jobState, jobCity, salary, tags } = editedJobInfo;
        let requestBody = {
            jobTitle,
            companyName,
            field,
            responsibilities,
            numberOfVacancy,
            jobState,
            jobCity,
            salary,
            tags,
        };

        patchPrivateJobInfo(
            `/v1/privatejobpost/patchprivatejob/${tab}`,
            requestBody,
            () => {
                setIsEditing(false);
            },
            getData
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedJobInfo((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCancel = () => {
        setEditedJobInfo(privateJobInfo);
        setIsEditing(false);
    };

    const getData = () => {
        getPrivateJobInfo(`/v1/privatejobpost/getprivatejob/${tab}`, null, (field) => {
            getPrivateJobList(`/v1/privatejobpost/getprivatejoblist?tab=2`);
        });
    };

    useEffect(() => {
        getData();
    }, [tab]);

    useEffect(() => {
        setEditedJobInfo(privateJobInfo);
    }, [privateJobInfo]);

    const [editModeRender, setEditModeRender] = useState([]);
    const [editedContentsRender, setEditedContentsRender] = useState([]);
    const [editedHeadings, setEditedHeadings] = useState([]);
    const [alertShow, setAlertShow] = useState(false);
    const [approveAlertShow, setApproveAlertShow] = useState(false);
    const [showDetails, setShowDetails] = useState({});

    const toggleDetails = (key) => {
        setShowDetails((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const handleCancelRender = (index) => {
        const updatedEditMode = [...editModeRender];
        updatedEditMode[index] = false;

        const updatedContents = [...editedContentsRender];
        updatedContents[index] = [...privateJobInfo.sections[index].data];

        const updatedHeadings = [...editedHeadings];
        updatedHeadings[index] = privateJobInfo.sections[index].heading;

        setEditModeRender(updatedEditMode);
        setEditedContentsRender(updatedContents);
        setEditedHeadings(updatedHeadings);
    };

    useEffect(() => {
        if (privateJobInfo && privateJobInfo.sections) {
            setEditModeRender(privateJobInfo.sections.map(() => false));
            setEditedContentsRender(privateJobInfo.sections.map((section) => section.data));
            setEditedHeadings(privateJobInfo.sections.map((section) => section.heading));
        }
    }, [privateJobInfo]);

    const toggleEditModeRender = (index) => {
        const updatedEditMode = [...editModeRender];
        updatedEditMode[index] = !updatedEditMode[index];
        setEditModeRender(updatedEditMode);
    };

    const handleSaveRender = (index, updatedData) => {
        let updatedSections = privateJobInfo.sections || [];
        updatedSections[index] = updatedData;
        let requestBody = {
            sections: updatedSections,
        };

        patchPrivateJobInfo(
            `/v1/privatejobpost/patchprivatejob/${tab}`,
            requestBody,
            () => {
                toggleEditModeRender(index);
            },
            getData
        );
    };

    const handleInputChangeRender = (sectionIndex, value) => {
        const newData = [...editedContentsRender];
        newData[sectionIndex] = value;
        setEditedContentsRender(newData);
    };

    const handleHeadingChange = (index, value) => {
        const newHeadings = [...editedHeadings];
        newHeadings[index] = value;
        setEditedHeadings(newHeadings);
    };

    const handleTableInputChangeRender = (sectionIndex, rowIndex, cellIndex, value) => {
        const newData = [...editedContentsRender];

        if (!newData[sectionIndex][rowIndex]) {
            newData[sectionIndex][rowIndex] = {};
        }

        const headers = Object.keys(newData[sectionIndex][0]);
        const cellKey = headers[cellIndex] || "";

        if (cellKey) {
            newData[sectionIndex][rowIndex][cellKey] = value;
        }

        setEditedContentsRender(newData);
    };

    const handleHeaderChange = (sectionIndex, headerIndex, newHeader) => {
        const updatedData = [...editedContentsRender];

        const currentHeaders = Object.keys(updatedData[sectionIndex][0]);

        const oldHeader = currentHeaders[headerIndex];

        updatedData[sectionIndex] = updatedData[sectionIndex].map((row) => {
            const updatedRow = { ...row };
            updatedRow[newHeader] = updatedRow[oldHeader];
            delete updatedRow[oldHeader];
            return updatedRow;
        });

        updatedData[sectionIndex] = updatedData[sectionIndex].map((row) => {
            const reorderedRow = {};
            currentHeaders.forEach((header, idx) => {
                if (idx === headerIndex) {
                    reorderedRow[newHeader] = row[newHeader];
                } else {
                    reorderedRow[header] = row[header];
                }
            });
            return reorderedRow;
        });

        setEditedContentsRender(updatedData);
    };

    const renderContent = (item, key) => {
        const isEditing = editModeRender[key];
        const editedContent = isEditing ? editedContentsRender[key] : item.data;
        const editedHeading = editedHeadings[key];

        switch (item.type) {
            case "list":
                return (
                    <div key={key} className={styles.job_description_item}>
                        <div className={styles.job_description_item_heading}>
                            {isEditing ? (
                                <input type="text" value={editedHeading} onChange={(e) => handleHeadingChange(key, e.target.value)} />
                            ) : (
                                <h2 onClick={() => toggleDetails(key)}>{item.heading}</h2>
                            )}
                            <svg
                                onClick={() => toggleDetails(key)}
                                style={{ transform: !showDetails[key] && "rotate(180deg)" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.2em"
                                height="1.2em"
                                viewBox="0 0 24 24"
                            >
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 10 5 5m0 0 5-5" />
                            </svg>
                            {isVerifiedRole && (
                                <div className={styles.admin_action_container}>
                                    <button
                                        className={styles.edit}
                                        onClick={() => {
                                            if (isEditing) {
                                                handleSaveRender(key, {
                                                    type: item.type,
                                                    heading: editedHeading,
                                                    data: editedContent,
                                                });
                                            } else {
                                                toggleEditModeRender(key);
                                            }
                                        }}
                                    >
                                        {isEditing ? ICONS.TICK : ICONS.EDIT}
                                    </button>
                                    {isEditing && (
                                        <button className={styles.cancel} onClick={() => handleCancelRender(key)}>
                                            {ICONS.CANCEL}
                                        </button>
                                    )}
                                    <button
                                        className={styles.delete}
                                        onClick={() => {
                                            setAlertShow(true);
                                            setDeleteIndex(key);
                                        }}
                                    >
                                        {ICONS.DELETE}
                                    </button>
                                </div>
                            )}
                        </div>
                        {!showDetails[key] && (
                            <div className={styles.job_description_item_body}>
                                <ul>
                                    {isEditing ? (
                                        <textarea type="text" value={editedContent.join("\n")} onChange={(e) => handleInputChangeRender(key, e.target.value?.split("\n"))} />
                                    ) : (
                                        editedContent?.map((listItem, index) => listItem?.length > 0 && <li key={index}>{<TextWithLinks text={listItem} />}</li>)
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                );
            case "paragraph":
                return (
                    <div key={key} className={styles.job_description_item}>
                        <div className={styles.job_description_item_heading}>
                            {isEditing ? (
                                <input type="text" value={editedHeading} onChange={(e) => handleHeadingChange(key, e.target.value)} />
                            ) : (
                                <h2 onClick={() => toggleDetails(key)}>{item.heading}</h2>
                            )}
                            <svg
                                onClick={() => toggleDetails(key)}
                                style={{ transform: !showDetails[key] && "rotate(180deg)" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.2em"
                                height="1.2em"
                                viewBox="0 0 24 24"
                            >
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 10 5 5m0 0 5-5" />
                            </svg>
                            {isVerifiedRole && (
                                <div className={styles.admin_action_container}>
                                    <button
                                        className={styles.edit}
                                        onClick={() => {
                                            if (isEditing) {
                                                handleSaveRender(key, {
                                                    type: item.type,
                                                    heading: editedHeading,
                                                    data: editedContent,
                                                });
                                            } else {
                                                toggleEditModeRender(key);
                                            }
                                        }}
                                    >
                                        {isEditing ? ICONS.TICK : ICONS.EDIT}
                                    </button>
                                    {isEditing && (
                                        <button className={styles.cancel} onClick={() => handleCancelRender(key)}>
                                            {ICONS.CANCEL}
                                        </button>
                                    )}
                                    <button
                                        className={styles.delete}
                                        onClick={() => {
                                            setAlertShow(true);
                                            setDeleteIndex(key);
                                        }}
                                    >
                                        {ICONS.DELETE}
                                    </button>
                                </div>
                            )}
                        </div>
                        {!showDetails[key] && (
                            <div className={styles.job_description_item_body}>
                                {isEditing ? (
                                    <textarea value={editedContent} onChange={(e) => setEditedContentsRender(editedContentsRender.map((content, idx) => (idx === key ? e.target.value : content)))} />
                                ) : (
                                    <p>{<TextWithLinks text={item.data} />}</p>
                                )}
                            </div>
                        )}
                    </div>
                );
            case "link":
                return (
                    <div key={key} className={styles.job_description_item}>
                        <div className={styles.job_description_item_heading}>
                            {isEditing ? (
                                <input type="text" value={editedHeading} onChange={(e) => handleHeadingChange(key, e.target.value)} />
                            ) : (
                                <h2 onClick={() => toggleDetails(key)}>{item.heading}</h2>
                            )}
                            <svg
                                onClick={() => toggleDetails(key)}
                                style={{ transform: !showDetails[key] && "rotate(180deg)" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.2em"
                                height="1.2em"
                                viewBox="0 0 24 24"
                            >
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 10 5 5m0 0 5-5" />
                            </svg>
                            {isVerifiedRole && (
                                <div className={styles.admin_action_container}>
                                    <button
                                        className={styles.edit}
                                        onClick={() => {
                                            if (isEditing) {
                                                handleSaveRender(key, {
                                                    type: item.type,
                                                    heading: editedHeading,
                                                    data: editedContent,
                                                });
                                            } else {
                                                toggleEditModeRender(key);
                                            }
                                        }}
                                    >
                                        {isEditing ? ICONS.TICK : ICONS.EDIT}
                                    </button>
                                    {isEditing && (
                                        <button className={styles.cancel} onClick={() => handleCancelRender(key)}>
                                            {ICONS.CANCEL}
                                        </button>
                                    )}
                                    <button
                                        className={styles.delete}
                                        onClick={() => {
                                            setAlertShow(true);
                                            setDeleteIndex(key);
                                        }}
                                    >
                                        {ICONS.DELETE}
                                    </button>
                                </div>
                            )}
                        </div>
                        {!showDetails[key] && (
                            <div className={styles.job_description_item_body}>
                                {isEditing ? (
                                    <>
                                        <FileInput
                                            name="notification"
                                            label={"Notification PDF"}
                                            actionButtonLabel={"Upload Notification"}
                                            accept={"application/pdf"}
                                            maxFileSize={1000000}
                                            allowPreview={true}
                                            uploadPath={`/v1/fileupload/upload/notification/${privateJobInfo._id}_${key}`}
                                            replaceActionButtonLabel={"Replace Notification PDF"}
                                            onFileChange={(file) => setEditedContentsRender(editedContentsRender.map((content, idx) => (idx === key ? file : content)))}
                                        />
                                        <input
                                            type="text"
                                            value={editedContent}
                                            onChange={(e) => setEditedContentsRender(editedContentsRender.map((content, idx) => (idx === key ? e.target.value : content)))}
                                        />
                                    </>
                                ) : (
                                    <a
                                        target="_blank"
                                        href={
                                            item.data?.startsWith("http") ? item.data : `${process.env.REACT_APP_API_BASE_URL}/v1/privatejobpost/getmediaassests/${tab}?key=${item.heading}#zoom=109.5`
                                        }
                                    >
                                        {item.heading}
                                    </a>
                                )}
                            </div>
                        )}
                    </div>
                );
            case "iframe":
                return (
                    <div key={key} className={styles.job_description_item}>
                        <div className={styles.job_description_item_heading}>
                            {isEditing ? (
                                <input type="text" value={editedHeading} onChange={(e) => handleHeadingChange(key, e.target.value)} />
                            ) : (
                                <h2 onClick={() => toggleDetails(key)}>{item.heading}</h2>
                            )}
                            <svg
                                onClick={() => toggleDetails(key)}
                                style={{ transform: !showDetails[key] && "rotate(180deg)" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.2em"
                                height="1.2em"
                                viewBox="0 0 24 24"
                            >
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 10 5 5m0 0 5-5" />
                            </svg>
                            {isVerifiedRole && (
                                <div className={styles.admin_action_container}>
                                    <button
                                        className={styles.edit}
                                        onClick={() => {
                                            if (isEditing) {
                                                handleSaveRender(key, {
                                                    type: item.type,
                                                    heading: editedHeading,
                                                    data: editedContent,
                                                });
                                            } else {
                                                toggleEditModeRender(key);
                                            }
                                        }}
                                    >
                                        {isEditing ? ICONS.TICK : ICONS.EDIT}
                                    </button>
                                    {isEditing && (
                                        <button className={styles.cancel} onClick={() => handleCancelRender(key)}>
                                            {ICONS.CANCEL}
                                        </button>
                                    )}
                                    <button
                                        className={styles.delete}
                                        onClick={() => {
                                            setAlertShow(true);
                                            setDeleteIndex(key);
                                        }}
                                    >
                                        {ICONS.DELETE}
                                    </button>
                                </div>
                            )}
                        </div>
                        {!showDetails[key] && (
                            <div className={styles.job_description_item_body}>
                                {isEditing ? (
                                    <>
                                        <FileInput
                                            name="notification"
                                            label={"Notification PDF"}
                                            actionButtonLabel={"Upload Notification"}
                                            accept={"application/pdf"}
                                            maxFileSize={1000000}
                                            allowPreview={true}
                                            uploadPath={`/v1/fileupload/upload/notification/${privateJobInfo._id}_${key}`}
                                            replaceActionButtonLabel={"Replace Notification PDF"}
                                            onFileChange={(file) => setEditedContentsRender(editedContentsRender.map((content, idx) => (idx === key ? file : content)))}
                                        />
                                        <input
                                            type="text"
                                            value={editedContent}
                                            onChange={(e) => setEditedContentsRender(editedContentsRender.map((content, idx) => (idx === key ? e.target.value : content)))}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <a
                                            href={`${process.env.REACT_APP_API_BASE_URL}/v1/privatejobpost/getmediaassests/${tab}?key=${item.heading}#zoom=109.5`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {"Notification PDF Click Here to View"}
                                        </a>
                                        <PDFViewer pdfUrl={`${process.env.REACT_APP_API_BASE_URL}/v1/privatejobpost/getmediaassests/${tab}?key=${item.heading}#zoom=109.5`} pdfId="pdf1" />
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                );
            case "tableWithHeader":
                return (
                    <div key={key} className={styles.job_description_item}>
                        <div className={styles.job_description_item_heading}>
                            {isEditing ? (
                                <input type="text" value={editedHeading} onChange={(e) => handleHeadingChange(key, e.target.value)} />
                            ) : (
                                <h2 onClick={() => toggleDetails(key)}>{item.heading}</h2>
                            )}
                            <svg
                                onClick={() => toggleDetails(key)}
                                style={{ transform: !showDetails[key] && "rotate(180deg)" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.2em"
                                height="1.2em"
                                viewBox="0 0 24 24"
                            >
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 10 5 5m0 0 5-5" />
                            </svg>
                            {isVerifiedRole && (
                                <div className={styles.admin_action_container}>
                                    <button
                                        className={styles.edit}
                                        onClick={() => {
                                            if (isEditing) {
                                                handleSaveRender(key, {
                                                    type: item.type,
                                                    heading: editedHeading,
                                                    data: editedContent,
                                                });
                                            } else {
                                                toggleEditModeRender(key);
                                            }
                                        }}
                                    >
                                        {isEditing ? ICONS.TICK : ICONS.EDIT}
                                    </button>
                                    {isEditing && (
                                        <button className={styles.cancel} onClick={() => handleCancelRender(key)}>
                                            {ICONS.CANCEL}
                                        </button>
                                    )}
                                    <button
                                        className={styles.delete}
                                        onClick={() => {
                                            setAlertShow(true);
                                            setDeleteIndex(key);
                                        }}
                                    >
                                        {ICONS.DELETE}
                                    </button>
                                </div>
                            )}
                        </div>
                        {!showDetails[key] && (
                            <div className={styles.job_description_item_body}>
                                <table>
                                    <thead>
                                        <tr>
                                            {Object.keys(editedContent[0] || {}).map((header, headerIndex) => (
                                                <th key={headerIndex}>
                                                    {isEditing ? <input type="text" value={header} onChange={(e) => handleHeaderChange(key, headerIndex, e.target.value)} /> : header}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {editedContent?.map((row, rowIndex) => {
                                            const allHeaders = Object.keys(editedContent[0]);
                                            const rowValues = allHeaders.map((header) => row[header] || "");

                                            const isEmptyRow = rowValues.every((cell) => !cell);

                                            if (!isEditing && isEmptyRow) {
                                                return null;
                                            }

                                            return (
                                                <tr key={rowIndex}>
                                                    {rowValues.map((cell, cellIndex) => (
                                                        <td key={cellIndex}>
                                                            {isEditing ? (
                                                                <input type="text" value={cell || ""} onChange={(e) => handleTableInputChangeRender(key, rowIndex, cellIndex, e.target.value)} />
                                                            ) : (
                                                                cell || "—"
                                                            )}
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                );

            case "tableWithoutHeader":
                return (
                    <div key={key} className={styles.job_description_item}>
                        <div className={styles.job_description_item_heading}>
                            {isEditing ? (
                                <input type="text" value={editedHeading} onChange={(e) => handleHeadingChange(key, e.target.value)} />
                            ) : (
                                <h2 onClick={() => toggleDetails(key)}>{item.heading}</h2>
                            )}

                            <svg
                                onClick={() => toggleDetails(key)}
                                style={{ transform: !showDetails[key] && "rotate(180deg)" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.2em"
                                height="1.2em"
                                viewBox="0 0 24 24"
                            >
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 10 5 5m0 0 5-5" />
                            </svg>

                            {isVerifiedRole && (
                                <div className={styles.admin_action_container}>
                                    <button
                                        className={styles.edit}
                                        onClick={() => {
                                            if (isEditing) {
                                                handleSaveRender(key, {
                                                    type: item.type,
                                                    heading: editedHeading,
                                                    data: editedContent,
                                                });
                                            } else {
                                                toggleEditModeRender(key);
                                            }
                                        }}
                                    >
                                        {isEditing ? ICONS.TICK : ICONS.EDIT}
                                    </button>
                                    {isEditing && (
                                        <button className={styles.cancel} onClick={() => handleCancelRender(key)}>
                                            {ICONS.CANCEL}
                                        </button>
                                    )}
                                    <button
                                        className={styles.delete}
                                        onClick={() => {
                                            setAlertShow(true);
                                            setDeleteIndex(key);
                                        }}
                                    >
                                        {ICONS.DELETE}
                                    </button>
                                </div>
                            )}
                        </div>
                        {!showDetails[key] && (
                            <div className={styles.job_description_item_body}>
                                <div>
                                    {editedContent.map((row, rowIndex) => (
                                        <div key={rowIndex}>
                                            {isEditing ? (
                                                <>
                                                    <input
                                                        type="text"
                                                        value={row.key}
                                                        onChange={(e) => {
                                                            const newData = [...editedContentsRender];
                                                            newData[key][rowIndex].key = e.target.value;
                                                            setEditedContentsRender(newData);
                                                        }}
                                                    />
                                                    <input
                                                        type="text"
                                                        value={row.value}
                                                        onChange={(e) => {
                                                            const newData = [...editedContentsRender];
                                                            newData[key][rowIndex].value = e.target.value;
                                                            setEditedContentsRender(newData);
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <h3>{row.key}</h3>
                                                    <p>{row.value}</p>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className={styles.container}>
            <Helmet>
                <title>{privateJobInfo.jobTitle}</title>
                <link rel="canonical" href={`https://wwww.medmanch.com/private/${tab}`} />
                <meta property="og:title" content={privateJobInfo.jobTitle} />
                <meta property="og:organization" content={privateJobInfo.field} />
                <meta property="og:description" content={shareMessage} />
                <meta property="og:type" content="website" />
                <meta name="keywords" content={`Medmanch - job, ${privateJobInfo.jobTitle}, ${privateJobInfo.field}, medical jobs, healthcare careers`} />
            </Helmet>
            <div className={styles.job_details}>
                <div className={styles.job_list_item}>
                    <div className={styles.job_heading}>
                        {isEditing ? (
                            <input type="text" name="jobTitle" value={editedJobInfo.jobTitle} onChange={handleChange} className={styles.input_job_title} />
                        ) : (
                            <h1>{editedJobInfo.jobTitle || "Job Title"}</h1>
                        )}
                    </div>
                    <div className={styles.job_body}>
                        <div>
                            <div className={styles.job_body_top}>
                                {isVerifiedRole && (
                                    <div className={styles.admin_action_container}>
                                        {isEditing ? (
                                            <>
                                                <button className={styles.save} onClick={handleSaveClick}>
                                                    {ICONS.TICK}
                                                </button>
                                                <button className={styles.cancel} onClick={handleCancel}>
                                                    {ICONS.CANCEL}
                                                </button>
                                            </>
                                        ) : (
                                            <button className={styles.edit} onClick={handleEditClick}>
                                                {ICONS.EDIT}
                                            </button>
                                        )}
                                        <button className={styles.delete} onClick={() => setAlertShow(true)}>
                                            {ICONS.DELETE}
                                        </button>
                                    </div>
                                )}
                                <div className={styles.job_overview}>
                                    <h3>{isEditing ? <input name="companyName" value={editedJobInfo.companyName} onChange={handleChange} /> : editedJobInfo.companyName}</h3>
                                    <h4 className={styles.job_category}>
                                        {isEditing ? (
                                            <InputField
                                                label={""}
                                                formData={editedJobInfo}
                                                fieldName={"tags"}
                                                setFormData={setEditedJobInfo}
                                                initialSuggestion={DATA.tags.map((item) => {
                                                    return { cat: item.value, key: item.value };
                                                })}
                                                getSearchSuggestion={(text, setSuggestion) => {
                                                    const suggestions = DATA.tags
                                                        .filter((item) => item.value.toLowerCase().includes(text.toLowerCase()))
                                                        .map((item) => ({
                                                            cat: item.value,
                                                            key: item.value,
                                                        }));

                                                    setSuggestion(suggestions || []);
                                                }}
                                            />
                                        ) : (
                                            <div className={styles.tags}>
                                                {Utils.filterTags(editedJobInfo.tags || [], DATA.tags).map((tag) => (
                                                    <p key={tag.value}>{tag.value}</p>
                                                ))}
                                            </div>
                                        )}
                                    </h4>
                                    {isEditing ? (
                                        <textarea name="responsibilities" value={editedJobInfo.responsibilities} onChange={handleChange} />
                                    ) : (
                                        <p className={styles.job_description}>{editedJobInfo.responsibilities}</p>
                                    )}
                                </div>
                                <div className={styles.category_logo}>
                                    <img src={Utils.getIcon(editedJobInfo.field)} alt={editedJobInfo.field} />
                                </div>
                            </div>
                            <div className={styles.job_body_bottom}>
                                {editedJobInfo && (
                                    <div>
                                        <h3>
                                            <img src={require("../../Assets/vacancy.png")} alt="vacancy icon" />
                                            Vacancy:
                                        </h3>
                                        {isEditing ? (
                                            <input type="text" name="numberOfVacancy" value={editedJobInfo.numberOfVacancy} onChange={handleChange} />
                                        ) : (
                                            <p>{editedJobInfo.numberOfVacancy}</p>
                                        )}
                                    </div>
                                )}
                                {editedJobInfo && (
                                    <div>
                                        <h3>
                                            <img src={require("../../Assets/location.png")} alt="Location icon" />
                                            Location:
                                        </h3>
                                        {isEditing ? (
                                            <>
                                                <input type="text" name="jobCity" value={editedJobInfo.jobCity} onChange={handleChange} id="" />
                                                <select type="text" name="jobState" value={editedJobInfo.jobState} onChange={handleChange}>
                                                    <option value="">Select State</option>
                                                    {DATA.States.map((item) => (
                                                        <option key={item} value={item.toLowerCase()}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                                            </>
                                        ) : (
                                            <p>
                                                {" "}
                                                {editedJobInfo.jobCity ? `${editedJobInfo.jobCity}, ` : ""} {editedJobInfo.jobState}
                                            </p>
                                        )}
                                    </div>
                                )}
                                {editedJobInfo.salary && (
                                    <div>
                                        <h3>
                                            <img src={require("../../Assets/salary.png")} alt="Salary icon" />
                                            Salary:
                                        </h3>
                                        {isEditing ? <input type="text" name="salary" value={editedJobInfo.salary} onChange={handleChange} /> : <p>{editedJobInfo.salary} ₹</p>}
                                    </div>
                                )}

                                {!editedJobInfo.salary && isVerifiedRole && (
                                    <div>
                                        <h3>
                                            <img src={require("../../Assets/salary.png")} alt="Salary icon" />
                                            Salary:
                                        </h3>
                                        {isEditing ? <input type="text" name="salary" value={editedJobInfo.salary} onChange={handleChange} /> : "Not given"}
                                    </div>
                                )}

                                {editedJobInfo.field && isVerifiedRole && isEditing && (
                                    <div>
                                        <h3>Field:</h3>
                                        {isEditing ? (
                                            <select type="text" name="field" value={editedJobInfo.field} onChange={handleChange}>
                                                {DATA.Fields.map((item) => (
                                                    <option value={item.value}>{item.label}</option>
                                                ))}
                                            </select>
                                        ) : (
                                            editedJobInfo.field
                                        )}
                                    </div>
                                )}

                                {editedJobInfo.createdAt && (
                                    <div>
                                        <h3>
                                            <img src={require("../../Assets/date.png")} alt="Posted icon" />
                                            Posted:
                                        </h3>
                                        <p>{Utils.formatDate(editedJobInfo.createdAt || "2024-07-13T13:47:14.405Z")}</p>
                                    </div>
                                )}

                                <div className={styles.share_icons}>
                                    <WhatsappShareButton url={jobURL?.split("?")[0]} title={shareMessage} separator="" description={shareMessage}>
                                        <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>

                                    <LinkedinShareButton url={" "} title={shareMessage.replace(/\*/g, "")} separator="" description={shareMessage.replace(/\*/g, "")}>
                                        <LinkedinIcon size={32} round />
                                    </LinkedinShareButton>

                                    <TelegramShareButton url={" "} title={shareMessage.replace(/\*/g, "")} separator="" description={shareMessage.replace(/\*/g, "")}>
                                        <TelegramIcon size={32} round />
                                    </TelegramShareButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.job_description_container}>{privateJobInfo?.sections?.map((item, index) => renderContent(item, index))}</div>
                    <div style={{ marginTop: "-3rem", marginBottom: "1rem" }}>
                        <Disclaimer />
                    </div>

                    {isVerifiedRole && (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                const updatedSections = privateJobInfo.sections || [];
                                if (updatedSections.length == addSection.index) {
                                    updatedSections.push({
                                        type: addSection.type,
                                        heading: "section title",
                                        data: addSection.type === "paragraph" || addSection.type === "link" || addSection.type === "iframe" ? "section body" : ["section body"],
                                    });
                                } else {
                                    updatedSections.splice(addSection.index, 0, {
                                        type: addSection.type,
                                        heading: "section title",
                                        data: addSection.type === "paragraph" || addSection.type === "link" || addSection.type === "iframe" ? "section body" : ["section body"],
                                    });
                                }

                                patchPrivateJobInfo(`/v1/privatejobpost/patchprivatejob/${tab}`, { sections: updatedSections }, () => {}, getData);

                                setAddSection({
                                    index: "",
                                    type: "",
                                });
                            }}
                            className={`${styles.add_job_post} ${styles.add_section}`}
                        >
                            <select
                                required
                                value={addSection.type}
                                onChange={(event) => {
                                    setAddSection({ ...addSection, type: event.target.value });
                                }}
                                name=""
                                id=""
                            >
                                <option value="">Select Section Type</option>
                                {DATA.sectionType.map((item, index) => (
                                    <option key={item.label} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>

                            <select
                                required
                                value={addSection.index}
                                onChange={(event) => {
                                    setAddSection({ ...addSection, index: event.target.value });
                                }}
                            >
                                <option value="">Select Section Place</option>
                                <option value="0">0</option>
                                {privateJobInfo.sections?.map((data, index) => (
                                    <option key={index} value={index + 1}>
                                        {index + 1}
                                    </option>
                                ))}
                            </select>
                            <button>Add Section</button>
                        </form>
                    )}
                </div>

                {isVerifiedRole && (
                    <div className={styles.add_job_post}>
                        {user?.roles?.USER === ROLES_LIST.ADMIN && (
                            <button
                                onClick={() => {
                                    setApproveAlertShow(true);
                                }}
                            >
                                {privateJobInfo.isApprovedByAdmin ? <>{ICONS.UN_PUBLISH} UnPublish Job Post</> : <>{ICONS.PUBLISH} Publish Job Post</>}
                            </button>
                        )}
                        <button onClick={() => setAlertShow(true)}>
                            {ICONS.SECONDARY_DELETE}
                            Delete Job Post
                        </button>
                    </div>
                )}

                <div className={styles.similar_job_container}>
                    <h2>Similar Jobs</h2>

                    <div className={styles.similar_job_list}>
                        {privateJobList?.data
                            ?.filter((data) => data._id != tab.split("--")[1])
                            .map((item, index) => (
                                <JobContainer key={index} keyValue={item._id} item={item} />
                            ))}
                    </div>
                </div>
            </div>

            {alertShow && (
                <AlertBox
                    data={{
                        heading: "Delete",
                        description: `are you sure you want to delete this  ${deleteIndex || deleteIndex == 0 ? "section" : "Job post"}`,
                        action: [
                            {
                                label: "Yes",
                                actionFunction: () => {
                                    const url =
                                        deleteIndex || deleteIndex == 0 ? `/v1/privatejobpost/deleteprivatejob/${tab}?sectionIndex=${deleteIndex}` : `/v1/privatejobpost/deleteprivatejob/${tab}`;
                                    deletePrivateJobInfo(
                                        url,
                                        () => setAlertShow(false),
                                        () => {
                                            setTimeout(() => (deleteIndex || deleteIndex == 0 ? getData() : (window.location.href = `/private-jobs?tab=${tabs}`))), 500;
                                        }
                                    );
                                },
                                className: "yes_button",
                            },
                            {
                                label: "No",
                                actionFunction: () => {
                                    setAlertShow(false);
                                },
                                className: "no_button",
                            },
                        ],
                        setClose: () => {
                            setAlertShow(false);
                        },
                    }}
                />
            )}

            {approveAlertShow && (
                <AlertBox
                    data={{
                        heading: privateJobInfo.isApprovedByAdmin ? "UnPublish" : "Publish",
                        description: `are you sure you want to ${privateJobInfo.isApprovedByAdmin ? "UnPublish" : "Publish"} this Job post`,
                        action: [
                            {
                                label: "Yes",
                                actionFunction: () => {
                                    patchPrivateJobInfo(`/v1/privatejobpost/patchprivatejob/${tab}`, { isApprovedByAdmin: !privateJobInfo.isApprovedByAdmin }, () => {}, getData);
                                    setApproveAlertShow(false);
                                },
                                className: "yes_button",
                            },
                            {
                                label: "No",
                                actionFunction: () => {
                                    setApproveAlertShow(false);
                                },
                                className: "no_button",
                            },
                        ],
                        setClose: () => {
                            setApproveAlertShow(false);
                        },
                    }}
                />
            )}
        </div>
    );
};

export default JobDetails;
