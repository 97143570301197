import React from "react";
import Checkbox from "react-custom-checkbox";

const CustomCheckbox = ({ parentKey, label, checked, onChange, name, customValue }) => {
    return (
        <>
            <Checkbox
                checked={checked}
                // icon={<img src="../publicAssets/utils/check.svg" style={{ width: 24 }} alt="check icon" />}
                borderColor="rgba(193, 193, 193, .8)"
                borderRadius={10}
                labelClassName={"custom-checkbox-label"}
                size={18}
                label={label}
                name={name}
                onChange={(value) => onChange({ target: { name, value: value && (customValue || customValue === false) ? customValue : label ? label : undefined, parentKey } })}
                className={"custom-checkbox"}
            />
        </>
    );
};

export default CustomCheckbox;
