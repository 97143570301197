import React, { useState } from "react";
import styles from "./index.module.css";
import { axiosInstance } from "../../Api/Interceptors";
import { toast } from "react-toastify";

const RegistrationForm = ({ getClose }) => {
    const [formData, setFormData] = useState({
        name: "",
        phoneNumber: "",
        email: "",
        specialties: "",
        location: "",
    });

    const [loading, setLoading] = useState(false);

    const notifySuccess = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message);

    const handleChange = (event) => {
        let value = event.target.value;
        let key = event.target.name;
        setFormData({ ...formData, [key]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        axiosInstance
            .post(`/v1/contact/contacts`, formData)
            .then((response) => {
                notifySuccess(response.data.message);
                setFormData({
                    name: "",
                    phoneNumber: "",
                    email: "",
                    specialties: "",
                });
            })
            .catch((error) => {
                notifyError(error.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
                getClose();
            });
    };

    return (
        <div className={styles.registration_pop_up_container}>
            <div className={styles.container}>
                <h3>Get personalized job alerts for your specialty in your area</h3>
                <span onClick={getClose} className={styles.cut_option}>
                    X
                </span>
                <form onSubmit={handleSubmit} className={styles.form} action="">
                    <input required value={formData.name} type="text" name="name" id="" placeholder="Your Name" onChange={handleChange} />

                    <input required value={formData.phoneNumber} type="text" name="phoneNumber" id="" placeholder="Phone No." onChange={handleChange} />

                    <input required value={formData.email} type="email" name="email" id="" placeholder="Enter your Email Id" onChange={handleChange} />

                    <select required value={formData.specialties} name="specialties" id="" onChange={handleChange}>
                        <option value="">Field</option>
                        <option value="doctor">Doctor</option>
                        <option value="nursing">Nursing</option>
                        <option value="paramedical">Paramedical</option>
                        <option value="technician">Technician</option>
                    </select>
                    <input required value={formData.location} type="text" name="location" id="" placeholder="Enter your location" onChange={handleChange} />

                    <button>
                        {loading ? (
                            <svg style={{ paddingInline: "1.1rem" }} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                <circle cx="12" cy="3.5" r="1.5" fill="currentColor" opacity="0">
                                    <animateTransform attributeName="transform" calcMode="discrete" dur="2.4s" repeatCount="indefinite" type="rotate" values="0 12 12;90 12 12;180 12 12;270 12 12" />
                                    <animate attributeName="opacity" dur="0.6s" keyTimes="0;0.5;1" repeatCount="indefinite" values="1;1;0" />
                                </circle>
                                <circle cx="12" cy="3.5" r="1.5" fill="currentColor" opacity="0">
                                    <animateTransform
                                        attributeName="transform"
                                        begin="0.2s"
                                        calcMode="discrete"
                                        dur="2.4s"
                                        repeatCount="indefinite"
                                        type="rotate"
                                        values="30 12 12;120 12 12;210 12 12;300 12 12"
                                    />
                                    <animate attributeName="opacity" begin="0.2s" dur="0.6s" keyTimes="0;0.5;1" repeatCount="indefinite" values="1;1;0" />
                                </circle>
                                <circle cx="12" cy="3.5" r="1.5" fill="currentColor" opacity="0">
                                    <animateTransform
                                        attributeName="transform"
                                        begin="0.4s"
                                        calcMode="discrete"
                                        dur="2.4s"
                                        repeatCount="indefinite"
                                        type="rotate"
                                        values="60 12 12;150 12 12;240 12 12;330 12 12"
                                    />
                                    <animate attributeName="opacity" begin="0.4s" dur="0.6s" keyTimes="0;0.5;1" repeatCount="indefinite" values="1;1;0" />
                                </circle>
                            </svg>
                        ) : (
                            "Submit"
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default RegistrationForm;
