import React, { useState } from "react";
import styles from "./index.module.css";
import { axiosInstance } from "../../Api/Interceptors";
import { toast } from "react-toastify";

const NewsLetter = () => {
    const [formData, setFormData] = useState({
        name: "",
        phoneNumber: "",
        email: "",
        specialties: "",
        location: "",
    });

    const [loading, setLoading] = useState(false);

    const notifySuccess = (message) => toast.success(message);
    const notifyError = (message) => toast.error(message);

    const handleChange = (event) => {
        let value = event.target.value;
        let key = event.target.name;
        setFormData({ ...formData, [key]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        axiosInstance
            .post(`/v1/contact/contacts`, formData)
            .then((response) => {
                notifySuccess(response.data.message);
                setFormData({
                    name: "",
                    phoneNumber: "",
                    email: "",
                    specialties: "",
                });
            })
            .catch((error) => {
                notifyError(error.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className={styles.news_letter_main_container_wrapper}>
            <div className={styles.new_letter_main_container}>
                <h3>
                    <svg width="71" height="58" viewBox="0 0 71 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.4082 3H60.6738C64.1983 3 67.082 5.88369 67.082 9.4082V47.8574C67.082 51.3819 64.1983 54.2656 60.6738 54.2656H9.4082C5.88369 54.2656 3 51.3819 3 47.8574V9.4082C3 5.88369 5.88369 3 9.4082 3Z"
                            stroke="white"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path d="M67.082 9.4082L38.4818 29.4284C36.4159 30.8745 33.6662 30.8745 31.6002 29.4284L3 9.4082" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Get personalized job alerts for your specialty in your area
                </h3>
                <form onSubmit={handleSubmit} className={styles.form} action="">
                    <input required value={formData.name} type="text" name="name" id="" placeholder="Your Name" onChange={handleChange} />

                    <input required value={formData.phoneNumber} type="text" name="phoneNumber" id="" placeholder="Phone No." onChange={handleChange} />

                    <input required value={formData.email} type="email" name="email" id="" placeholder="Enter your Email Id" onChange={handleChange} />

                    <select required value={formData.specialties} name="specialties" id="" onChange={handleChange}>
                        <option value="">Field</option>
                        <option value="doctor">Doctor</option>
                        <option value="nursing">Nursing</option>
                        <option value="paramedical">Paramedical</option>
                        <option value="technician">Technician</option>
                    </select>
                    <input required value={formData.location} type="text" name="location" id="" placeholder="Enter your location" onChange={handleChange} />

                    <button>
                        {loading ? (
                            <svg style={{ paddingInline: "1.1rem" }} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                <circle cx="12" cy="3.5" r="1.5" fill="currentColor" opacity="0">
                                    <animateTransform attributeName="transform" calcMode="discrete" dur="2.4s" repeatCount="indefinite" type="rotate" values="0 12 12;90 12 12;180 12 12;270 12 12" />
                                    <animate attributeName="opacity" dur="0.6s" keyTimes="0;0.5;1" repeatCount="indefinite" values="1;1;0" />
                                </circle>
                                <circle cx="12" cy="3.5" r="1.5" fill="currentColor" opacity="0">
                                    <animateTransform
                                        attributeName="transform"
                                        begin="0.2s"
                                        calcMode="discrete"
                                        dur="2.4s"
                                        repeatCount="indefinite"
                                        type="rotate"
                                        values="30 12 12;120 12 12;210 12 12;300 12 12"
                                    />
                                    <animate attributeName="opacity" begin="0.2s" dur="0.6s" keyTimes="0;0.5;1" repeatCount="indefinite" values="1;1;0" />
                                </circle>
                                <circle cx="12" cy="3.5" r="1.5" fill="currentColor" opacity="0">
                                    <animateTransform
                                        attributeName="transform"
                                        begin="0.4s"
                                        calcMode="discrete"
                                        dur="2.4s"
                                        repeatCount="indefinite"
                                        type="rotate"
                                        values="60 12 12;150 12 12;240 12 12;330 12 12"
                                    />
                                    <animate attributeName="opacity" begin="0.4s" dur="0.6s" keyTimes="0;0.5;1" repeatCount="indefinite" values="1;1;0" />
                                </circle>
                            </svg>
                        ) : (
                            "Submit"
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default NewsLetter;
