import DATA from "../Data";

class Utils {
    static parseJobDetails(data) {
        try {
            const lines = data?.paragraph?.split("\n").map((line) => line.trim());

            if (!lines) {
                throw new Error("Invalid paragraph format.");
            }

            // Initialize object with parsed data
            const jobDetails = {
                companyName: "",
                jobTitle: "",
                jobState: "",
                jobCity: "",
                responsibilities: "",
                url: "",
                field: data.field || "nursing",
                sections: [],
                numberOfVacancy: "1",
            };

            // Loop through lines and extract details
            lines.forEach((line, index) => {
                try {
                    // Extract job title and company from the first line
                    if (line.startsWith("Exciting Opportunity")) {
                        const [jobTitle, companyLocation] = line?.split(" at ");
                        if (!jobTitle || !companyLocation) throw new Error("Invalid job title or company location format.");

                        const companyName = companyLocation?.split(" in ")[0];
                        jobDetails.jobTitle = jobTitle?.trim();
                        jobDetails.companyName = companyName?.trim();
                        jobDetails.url = jobTitle?.trim()?.split(" ")?.join("-");
                    }

                    // Extract salary from the "1️⃣ Positions Available" section
                    if (line.startsWith("1️⃣ Positions Available:") || line.startsWith("1️⃣ Position Available:") || line.startsWith("1️⃣Positions Available:")) {
                        const positionLine = lines[index + 1];
                        if (!positionLine) throw new Error("Missing position line after Positions Available.");

                        const salaryMatch = positionLine.match(/Salary:\s*₹([\d,]+)\s*-\s*₹([\d,]+)/);
                        if (salaryMatch) {
                            jobDetails.salary = `₹${salaryMatch[1]} - ₹${salaryMatch[2]}`;
                        }
                    }

                    // Extract "1️⃣ Positions Available"
                    if (line.startsWith("1️⃣ Positions Available:") || line.startsWith("1️⃣ Position Available:") || line.startsWith("1️⃣Positions Available:")) {
                        const positionLine = [];
                        let i = index + 1;
                        while (i < lines.length && !lines[i].startsWith("2️⃣ Responsibilities:")) {
                            if (lines[i]) positionLine.push(lines[i]);
                            i++;
                        }
                        const jobTitle = positionLine.join(" ");
                        if (jobTitle) {
                            jobDetails.jobTitle = jobTitle?.split("–")[0]?.trim();
                        }
                        jobDetails.sections.push({
                            type: "list",
                            heading: "Positions Available",
                            data: positionLine,
                        });
                    }

                    // Extract responsibilities
                    if (line.startsWith("2️⃣ Responsibilities:")) {
                        const responsibilitiesLines = [];
                        let i = index + 1;
                        while (i < lines.length && !lines[i].startsWith("3️⃣ Qualifications:")) {
                            if (lines[i]) responsibilitiesLines.push(lines[i]);
                            i++;
                        }
                        jobDetails.responsibilities = responsibilitiesLines.join("\n");
                        jobDetails.sections.push({
                            type: "list",
                            heading: "Responsibilities",
                            data: responsibilitiesLines,
                        });
                    }

                    // Extract qualifications
                    if (line.startsWith("3️⃣ Qualifications:")) {
                        const qualificationsLines = [];
                        let i = index + 1;
                        while (i < lines.length && !lines[i].startsWith("4️⃣ Location:")) {
                            if (lines[i]) qualificationsLines.push(lines[i]);
                            i++;
                        }
                        jobDetails.sections.push({
                            type: "list",
                            heading: "Qualification",
                            data: qualificationsLines,
                        });
                    }

                    // Extract job city and state from the location
                    if (line.startsWith("4️⃣ Location:")) {
                        const locationLine = lines[index + 1];
                        if (!locationLine) throw new Error("Missing location line.");

                        const [jobCity, jobState] = locationLine.split(", ");
                        jobDetails.jobCity = jobCity?.trim()?.toLowerCase();
                        jobDetails.jobState = jobState?.trim()?.toLowerCase();
                    }

                    // Extract application link
                    if (line.startsWith("6️⃣ Apply Here:") || line.startsWith("6️⃣Apply Here:") || line.startsWith("7️⃣Apply Here:")) {
                        const applyLink = lines[index + 1];
                        if (!applyLink) throw new Error("Missing application link.");

                        jobDetails.url = applyLink;
                        jobDetails.sections.push({
                            type: "list",
                            heading: "How To Apply",
                            data: [applyLink],
                        });
                    }

                    // Extract company details
                    if (line.startsWith("5️⃣ Company:")) {
                        const aboutCompany = lines[index + 1];
                        if (!aboutCompany) throw new Error("Missing company details.");

                        const companyName = aboutCompany?.split(":")[0];
                        if (companyName) {
                            jobDetails.companyName = companyName;
                            jobDetails.jobTitle = jobDetails.jobTitle + ", " + companyName;
                            jobDetails.url = jobDetails.jobTitle?.trim()?.split(" ")?.join("-");
                        }

                        jobDetails.sections.push({
                            type: "paragraph",
                            heading: "About the Company",
                            data: aboutCompany,
                        });
                    }

                    // Extract contact information
                    if (line.startsWith("6️⃣ Contact Information:")) {
                        const contactInfo = lines[index + 1];
                        if (!contactInfo) throw new Error("Missing contact information.");

                        jobDetails.sections.push({
                            type: "list",
                            heading: "Contact Information",
                            data: [contactInfo],
                        });
                    }
                } catch (lineError) {
                    console.error(`Error processing line: ${line}. Error: ${lineError.message}`);
                }
            });

            return jobDetails;
        } catch (error) {
            console.error("Failed to parse job details:", error.message);
            return null;
        }
    }

    static formatDate(dateString) {
        const date = new Date(dateString);
        const options = { day: "2-digit", month: "short", year: "numeric" };
        return new Intl.DateTimeFormat("en-GB", options).format(date);
    }
    static truncateText(paragraph) {
        const words = paragraph.split("");

        if (words.length > 520) {
            const truncated = words.slice(0, 520).join("");
            return truncated;
        }

        return paragraph;
    }
    static getIcon(text) {
        switch (text) {
            case "nursing":
                return require("../Assets/nurse_icon.png");
            case "doctor":
                return require("../Assets/doctor_icon.png");
            case "pharmacy":
                return require("../Assets/medical 1.png");
            case "technician":
                return require("../Assets/paramedic_icon.png");
            default:
                return require("../Assets/doctor_icon.png");
        }
    }
    static convertToSlug(text) {
        return text
            .toLowerCase() // Convert the string to lowercase
            .replace(/[\s\W-]+/g, "-") // Replace spaces and non-word characters with hyphens
            .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
    }
    static filterTags(tags, data) {
        const lowerTags = tags.map((tag) => tag.toLowerCase());

        return data.filter((item) => lowerTags.includes(item.value.toLowerCase()));
    }
    static getStatesOptions = () => [
        { value: "", label: "Select State" },
        ...DATA.States.map((state) => ({
            value: state.toLowerCase(),
            label: state,
        })),
    ];
}
export default Utils;
