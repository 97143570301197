import React from "react";
import styles from "./index.module.css";

const AlertBox = ({ data }) => {
    return (
        <div onClick={data.setClose} className={styles.alert_main_container}>
            <div onClick={(event) => event.stopPropagation()} className={styles.alert_main_container_wrapper}>
                <div className={styles.alert_heading}>
                    <h2>{data.heading}</h2>
                </div>
                <div className={styles.alert_details}>
                    <p>{data.description}</p>
                </div>
                <div className={styles.alert_action_button}>
                    {data.action &&
                        data.action?.map((item) => {
                            return (
                                <button className={styles?.[item.className]} key={item.label} onClick={item.actionFunction}>
                                    {item.loading ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                            <circle cx="18" cy="12" r="0" fill="currentColor">
                                                <animate
                                                    attributeName="r"
                                                    begin=".67"
                                                    calcMode="spline"
                                                    dur="1.5s"
                                                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                                    repeatCount="indefinite"
                                                    values="0;2;0;0"
                                                />
                                            </circle>
                                            <circle cx="12" cy="12" r="0" fill="currentColor">
                                                <animate
                                                    attributeName="r"
                                                    begin=".33"
                                                    calcMode="spline"
                                                    dur="1.5s"
                                                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                                    repeatCount="indefinite"
                                                    values="0;2;0;0"
                                                />
                                            </circle>
                                            <circle cx="6" cy="12" r="0" fill="currentColor">
                                                <animate
                                                    attributeName="r"
                                                    begin="0"
                                                    calcMode="spline"
                                                    dur="1.5s"
                                                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                                    repeatCount="indefinite"
                                                    values="0;2;0;0"
                                                />
                                            </circle>
                                        </svg>
                                    ) : (
                                        item.label || "Button"
                                    )}
                                </button>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default AlertBox;
