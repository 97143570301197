import React, { useEffect } from "react";

const PDFViewer = ({ pdfUrl, pdfId }) => {
    const iframeId = `pdfIframe_${pdfId}`;

    useEffect(() => {
        const handlePopState = () => {
            const iframe = document.getElementById(iframeId);
            if (iframe) {
                iframe.src = pdfUrl;
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [pdfUrl, iframeId]);

    return <iframe id={iframeId} src={pdfUrl} style={{ width: "100%", height: "100vh" }} title="PDF Viewer" />;
};

export default PDFViewer;
