export const tags = [
    { label: "ANM", value: "ANM", category: "nursing" },
    { label: "B.sc Nursing", value: "B.sc Nursing", category: "nursing" },
    { label: "GNM", value: "GNM", category: "nursing" },
    { label: "B.Pharm", value: "B.Pharm", category: "pharmacy" },
    { label: "D. Pharm", value: "D. Pharm", category: "pharmacy" },
    { label: "M. Pharm", value: "M.Pharm", category: "pharmacy" },
    { label: "B.V.Sc", value: "B.V.Sc", category: "doctor" },
    { label: "BAMS", value: "BAMS", category: "doctor" },
    { label: "BDS", value: "BDS", category: "doctor" },
    { label: "BHMS", value: "BHMS", category: "doctor" },
    { label: "MD", value: "MD", category: "doctor" },
    { label: "MBBS", value: "MBBS", category: "doctor" },
    { label: "MDS", value: "MDS", category: "doctor" },
    { label: "MS", value: "MS", category: "doctor" },
    { label: "Biotechnology", value: "Biotechnology", category: "paramedic" },
    { label: "BPT", value: "BPT", category: "doctor" },
    { label: "BUMS", value: "BUMS", category: "doctor" },
    { label: "DNB", value: "DNB", category: "doctor" },
    { label: "M.V.Sc", value: "M.V.Sc", category: "doctor" },
    { label: "M. Sc Nursing", value: "M.sc Nursing", category: "nursing" },
    { label: "M. Phil", value: "M.Phil", category: "doctor" },
    { label: "Phd", value: "Phd", category: "doctor" },
    // { label: "Pharmacy", value: "Pharmacy", category: "pharmacy" },
    // { label: "Technician", value: "Technician", category: "technician" },
    { label: "Nutritionist", value: "Nutritionist", category: "technician" },
    { label: "Audiologist", value: "Audiologist", category: "technician" },
    { label: "Optometrist", value: "Optometrist", category: "technician" },
    { label: "12th Pass", value: "12th Pass", category: "technician" },
    { label: "MPH", value: "MPH", category: "technician" },
    { label: "MHA", value: "MHA", category: "technician" },
    { label: "Diploma", value: "Diploma", category: "technician" },
    { label: "DMLT", value: "DMLT", category: "technician" },
];

// Function to filter tags based on the category
export const getTagsByCategory = (category) => {
    return tags.filter((tag) => tag.category.toLowerCase() === category.toLowerCase());
};
