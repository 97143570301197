import React, { useState } from "react";
import styles from "./index.module.css";
import ICON from "../../../Utils/icons";

const RatingInput = ({ onChange, name }) => {
    const [data, setData] = useState(0);

    const handleChange = (selectedRating) => {
        const newRating = data === selectedRating ? 0 : selectedRating;
        setData(newRating);
        onChange && onChange({ target: { name, value: newRating } });
    };

    const ratingIcon = {
        1: ICON.BAD,
        2: ICON.POOR,
        3: ICON.AVERAGE,
        4: ICON.GOOD,
        5: ICON.EXCELLENT,
    };

    const ratingName = {
        1: "Bad",
        2: "Poor",
        3: "Average",
        4: "Good",
        5: "Excellent",
    };

    return (
        <div className={styles.container}>
            {[1, 2, 3, 4, 5].map((item) => (
                <div key={item} className={styles.ratingItem}>
                    <span onClick={() => handleChange(item)}>{item <= data ? ICON.FillStar : ICON.EmptyStar}</span>
                </div>
            ))}
            <div className={styles.ratingInfo}>
                {data > 0 && (
                    <>
                        <span>{ratingName[data]}</span> <span>{ratingIcon[data]}</span>
                    </>
                )}
            </div>
        </div>
    );
};

export default RatingInput;
