import React from "react";
import Select, { components } from "react-select";

const DropdownIndicator = (props) => {
    if (props.selectProps.isDisabled) {
        return null;
    }

    return <components.DropdownIndicator {...props} />;
};

const MultiValueRemove = (props) => {
    if (props.selectProps.isDisabled) {
        return null;
    }

    return (
        <components.MultiValueRemove
            {...props}
            innerProps={{
                ...props.innerProps,
                style: {
                    ...props.innerProps.style,
                    cursor: "pointer",
                    color: "rgb(115, 103, 240)",
                    transition: "color 0.2s",
                },
                onMouseEnter: (e) => (e.currentTarget.style.backgroundColor = "rgb(233, 231, 253)"),
                onMouseLeave: (e) => (e.currentTarget.style.backgroundColor = "rgb(233, 231, 253)"),
            }}
        />
    );
};

const CustomSingleValue = (props) => <components.SingleValue {...props}>{props.isDisabled ? <span>{props.data.label}</span> : props.children}</components.SingleValue>;

const CustomMultiValueLabel = (props) => <components.MultiValueLabel {...props}>{props.data.disabled ? <span>{props.data.label}</span> : props.children}</components.MultiValueLabel>;

const CustomSelect = ({ name, formValues, placeholder, handleSelectChange, options, multiple, required, disabled, style, classNames, borderColor = "#108A00" }) => (
    <Select
        id={name}
        name={name}
        value={multiple ? options.filter((option) => formValues[name]?.includes(option.value)) : options.find((option) => option.value === formValues[name])}
        onChange={(selectedOption) => handleSelectChange({ target: { name, value: multiple ? selectedOption.map((opt) => opt.value) : selectedOption?.value } })}
        options={options}
        isMulti={multiple}
        required={required || false}
        isDisabled={disabled}
        isSearchable={true}
        placeholder={placeholder}
        components={{
            DropdownIndicator,
            MultiValueRemove,
            SingleValue: CustomSingleValue,
            MultiValueLabel: CustomMultiValueLabel,
        }}
        styles={{
            control: (provided, state) => ({
                ...provided,
                backgroundColor: "white",
                textAlign: "left",
                color: "white",
                fontSize: "0.9rem",
                borderColor: state.isFocused ? borderColor : "lightgray",
                boxShadow: state.isFocused ? `0 0 0 1px ${borderColor}` : "none",
                "&:hover": {
                    borderColor: borderColor,
                },
                paddingBlock: "0.1rem",
                paddingInline: "0.15rem",
                borderRadius: "0.5rem",
                fontWeight: "400",
            }),
            menu: (provided) => ({
                ...provided,
                textAlign: "left",
                fontSize: "0.9rem",
                color: "#999",
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? "rgb(115, 103, 240)" : "white",
                color: state.isFocused ? "white" : "#333",
                cursor: "pointer",
            }),
            multiValue: (provided) => ({
                ...provided,
                backgroundColor: "rgb(233, 231, 253)",
                color: "white",
                borderRadius: "5px",
                paddingInline: "0.5rem",
            }),
            multiValueLabel: (provided) => ({
                ...provided,
                color: "rgb(115, 103, 240)",
            }),
            ...style,
        }}
        className={["react-select-container", ...classNames].join(" ")}
    />
);

export default CustomSelect;
