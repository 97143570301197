import React, { useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import styles from "./customSelect.module.css";
import { tags } from "../../ExploreTopGovernmentJob/data";
import Utils from "../../../Pages/GovernmentJobPosting/Utils";

// Define the InputField component
const InputField = ({
    label,
    fieldName,
    setFormData,
    formData,
    getSearchSuggestion,
    initialSuggestion,
    multiSelect = true, // Prop to toggle between single and multi-select
    withSuggestions = true,
    placeholder, // Prop to toggle suggestions on/off
}) => {
    // State for suggestions
    const [suggestion, setSuggestion] = useState(initialSuggestion);

    // Function to modify field data for display
    function modifyFieldData(data) {
        // console.log(data);
        return Utils.filterTags(data || [], tags)?.map((item) => ({
            cat: item.value,
            key: item.value,
        }));
    }

    // Handler for data selection
    const handleSelect = (data) => {
        if (!multiSelect) {
            // For single select, set only the selected item
            const selectedItem = data.length ? [data[data.length - 1]] : [];
            const modifiedData = selectedItem.map((item) => ({ name: item.cat }));
            setFormData({ ...formData, [fieldName]: modifiedData });
        } else {
            const modifiedData = data.map((item) => item.cat);
            setFormData({ ...formData, [fieldName]: modifiedData });
        }
    };

    // Handler for data removal
    const handleRemove = (data) => {
        const modifiedData = data.map((item) => item.cat);
        setFormData({ ...formData, [fieldName]: modifiedData });
    };

    // Handler for searching suggestions
    const handleSearch = (text) => {
        if (withSuggestions) {
            getSearchSuggestion(text, setSuggestion);
        }
    };

    return (
        <div className={styles.field_main_container}>
            <p>{label}</p>
            <Multiselect
                // singleSelect={} // Enable single select mode when multiSelect is false
                displayValue="key"
                className="project_member_container"
                selectionLimit={10}
                onRemove={handleRemove}
                onSearch={handleSearch}
                onSelect={handleSelect}
                options={suggestion}
                selectedValues={modifyFieldData(formData[fieldName] || [])}
                style={{
                    chips: {
                        background: "#f51f9c",
                    },
                    multiselectContainer: {
                        background: "white",
                        // borderRadius:"68px"
                    },
                    searchBox: {
                        // padding: "0.7rem",
                        // borderRadius:"60px"
                    },
                }}
                placeholder={placeholder}
                closeIcon="circle2"
            />
        </div>
    );
};

export default InputField;
