class Utils {
    static getIcon(text) {
        switch (text) {
            case "Nursing":
                return "../publicAssets/governmentJobHome/nurse_icon.png";
            case "Doctor":
                return "../publicAssets/governmentJobHome/doctor_icon.png";
            case "Paramedic":
                return "../publicAssets/governmentJobHome/paramedic_icon.png";
            default:
                return "../publicAssets/governmentJobHome/doctor_icon.png";
        }
    }

    static isValidLink(text) {
        const urlPattern = new RegExp(
            "^(https?:\\/\\/|www\\.)" + // Protocol (http, https) or "www." mandatory
                "(([a-zA-Z0-9$-_@.&+!*\\(\\),]|%[0-9a-fA-F]{2})+)" + // Domain name
                "(\\:[0-9]{1,5})?" + // Port (optional)
                "(\\/([a-zA-Z0-9$-_@.&+!*\\(\\),]|%[0-9a-fA-F]{2})*)*" + // Path (optional)
                "(\\?([a-zA-Z0-9$-_@.&+!*\\(\\),;]|%[0-9a-fA-F]{2})*)?" + // Query string (optional)
                "(#([a-zA-Z0-9$-_@.&+!*\\(\\),;]|%[0-9a-fA-F]{2})*)?$"
        ); // Fragment identifier (optional)

        return urlPattern.test(text);
    }
    static formatDate(dateString) {
        const date = new Date(dateString);
        const options = { day: "2-digit", month: "short", year: "numeric" };
        return new Intl.DateTimeFormat("en-GB", options).format(date);
    }
    static convertToSlug(text) {
        return text
            .toLowerCase() // Convert the string to lowercase
            .replace(/[\s\W-]+/g, "-") // Replace spaces and non-word characters with hyphens
            .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
    }
    static truncateText(paragraph) {
        const words = paragraph.split("");

        if (words.length > 520) {
            const truncated = words.slice(0, 520).join("");
            return truncated;
        }

        return paragraph;
    }
    static getTitle = (title) => {
        if (title) {
            const data = title.split("Apply for")[1] || title.split("Apply Online for")[1] || title.split("Recruitment")[0] || title.split("Vacancy")[0];
            if (data) {
                return data.trim().replace(/^\d+\s*/, "");
            } else {
                return title;
            }
        }
    };
    static filterTags(tags, data) {
        const lowerTags = tags.map((tag) => tag.toLowerCase());

        return data.filter((item) => lowerTags.includes(item.value.toLowerCase()));
    }
    static governmentJobsCategoryMenu() {
        return [
            {
                name: "All",
                url: "/government-jobs",
            },
            {
                name: "Doctor",
                url: "/doctor-jobs",
            },
            {
                name: "Nursing",
                url: "/nursing-jobs",
            },
            {
                name: "Pharmacy",
                url: "/pharmacy-jobs",
            },
            {
                name: "Technician",
                url: "/technician-jobs",
            },
        ];
    }
}
export default Utils;
